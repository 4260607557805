import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { getOption } from "../../utils/parse-options";

interface Props {
  id?: string;
  newReleaseMessageId: string;
  onChange: (data: any) => void;
  type: string;
  value: any;
}

const fields = [
  "Resource Group Type",
  "Resource Group Release Reference",
  "Display Sequence",
];

export const ResourceGroupTab = (props: Props) => {
  const history = useHistory();
  const [data, , remove, , , setDefault] = useArray<any>();

  const add = () => {
    if (!props.id) return;

    history.push(
      `/ern/${props.newReleaseMessageId}/${props.type}/${props.id}/resource-group/`
    );
  };

  const update = (data: any, index: number) => {
    if (!props.id) return;

    history.push(
      `/ern/${props.newReleaseMessageId}/${props.type}/${props.id}/resource-group/${index}`
    );
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="resource_group" className="page__tab-content">
      {!props.id ? (
        <h6>Please, save changes before adding Resource Group</h6>
      ) : (
        <TableComponent
          add={add}
          data={data || []}
          emptyElement={{}}
          fields={fields}
          getCells={(el: any) => (
            <>
              <td>{getOption(el.resourceGroupType || "")}</td>
              <td>{el.resourceGroupReleaseReference || ""}</td>
              <td>{`${el.displaySequence || false}`}</td>
            </>
          )}
          remove={remove}
          update={update}
        />
      )}
    </Tab.Pane>
  );
};
