import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import withAuth from "../hocs/withAuth";

import "./page.scss";

export const Password = withAuth((props: any) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("reset_password_token");

  useEffect(() => {
    if (token) {
      props.toggleAuthWithView("resetPassword", token);
    } else {
      props.showError(["Invalid password reset token"]);
    }
  }, []);

  return null;
});
