import { TISTerritory } from "../models/Territory";

export function useTISCodes() {
  const data = [
    {
      tis: 4,
      name: "Afghanistan",
      code: "AF",
    },
    {
      tis: 8,
      name: "Albania",
      code: "AL",
    },
    {
      tis: 12,
      name: "Algeria",
      code: "DZ",
    },
    {
      tis: 20,
      name: "Andorra",
      code: "AD",
    },
    {
      tis: 24,
      name: "Angola",
      code: "AO",
    },
    {
      tis: 28,
      name: "Antigua And Barbuda",
      code: "AG",
    },
    {
      tis: 31,
      name: "Azerbaijan",
      code: "AZ",
    },
    {
      tis: 32,
      name: "Argentina",
      code: "AR",
    },
    {
      tis: 36,
      name: "Australia",
      code: "AU",
    },
    {
      tis: 40,
      name: "Austria",
      code: "AT",
    },
    {
      tis: 44,
      name: "Bahamas",
      code: "BS",
    },
    {
      tis: 48,
      name: "Bahrain",
      code: "BH",
    },
    {
      tis: 50,
      name: "Bangladesh",
      code: "BD",
    },
    {
      tis: 51,
      name: "Armenia",
      code: "AM",
    },
    {
      tis: 52,
      name: "Barbados",
      code: "BB",
    },
    {
      tis: 56,
      name: "Belgium",
      code: "BE",
    },
    {
      tis: 64,
      name: "Bhutan",
      code: "BT",
    },
    {
      tis: 68,
      name: "Bolivia",
      code: "BO",
    },
    {
      tis: 70,
      name: "Bosnia And Herzegovina",
      code: "BA",
    },
    {
      tis: 72,
      name: "Botswana",
      code: "BW",
    },
    {
      tis: 76,
      name: "Brazil",
      code: "BR",
    },
    {
      tis: 84,
      name: "Belize",
      code: "BZ",
    },
    {
      tis: 90,
      name: "Solomon Islands",
      code: "SB",
    },
    {
      tis: 96,
      name: "Brunei Darussalam",
      code: "BN",
    },
    {
      tis: 100,
      name: "Bulgaria",
      code: "BG",
    },
    {
      tis: 104,
      name: "Myanmar",
      code: "MM",
    },
    {
      tis: 108,
      name: "Burundi",
      code: "BI",
    },
    {
      tis: 112,
      name: "Belarus",
      code: "BY",
    },
    {
      tis: 116,
      name: "Cambodia",
      code: "KH",
    },
    {
      tis: 120,
      name: "Cameroon",
      code: "CM",
    },
    {
      tis: 124,
      name: "Canada",
      code: "CA",
    },
    {
      tis: 132,
      name: "Cape Verde",
      code: "CV",
    },
    {
      tis: 140,
      name: "Central African Republic",
      code: "CF",
    },
    {
      tis: 144,
      name: "Sri Lanka",
      code: "LK",
    },
    {
      tis: 148,
      name: "Chad",
      code: "TD",
    },
    {
      tis: 152,
      name: "Chile",
      code: "CL",
    },
    {
      tis: 156,
      name: "China",
      code: "CN",
    },
    {
      tis: 158,
      name: "Province of China Taiwan",
      code: "Province of China Taiwan",
    },
    {
      tis: 170,
      name: "Colombia",
      code: "CO",
    },
    {
      tis: 174,
      name: "Comoros",
      code: "KM",
    },
    {
      tis: 178,
      name: "Congo",
      code: "CG",
    },
    {
      tis: 180,
      name: "The Democratic Republic of The Congo",
      code: "The Democratic Republic of The Congo",
    },
    {
      tis: 188,
      name: "Costa Rica",
      code: "CR",
    },
    {
      tis: 191,
      name: "Croatia",
      code: "HR",
    },
    {
      tis: 192,
      name: "Cuba",
      code: "CU",
    },
    {
      tis: 196,
      name: "Cyprus",
      code: "CY",
    },
    {
      tis: 200,
      name: "Czechoslovakia",
      code: "Czechoslovakia",
    },
    {
      tis: 203,
      name: "Czechia",
      code: "CZ",
    },
    {
      tis: 204,
      name: "Benin",
      code: "BJ",
    },
    {
      tis: 208,
      name: "Denmark",
      code: "DK",
    },
    {
      tis: 212,
      name: "Dominica",
      code: "DM",
    },
    {
      tis: 214,
      name: "Dominican Republic",
      code: "DO",
    },
    {
      tis: 218,
      name: "Ecuador",
      code: "EC",
    },
    {
      tis: 222,
      name: "El Salvador",
      code: "SV",
    },
    {
      tis: 226,
      name: "Equatorial Guinea",
      code: "GQ",
    },
    {
      tis: 230,
      name: "Ethiopia",
      code: "ET",
    },
    {
      tis: 231,
      name: "Ethiopia",
      code: "ET",
    },
    {
      tis: 232,
      name: "Eritrea",
      code: "ER",
    },
    {
      tis: 233,
      name: "Estonia",
      code: "EE",
    },
    {
      tis: 242,
      name: "Fiji",
      code: "FJ",
    },
    {
      tis: 246,
      name: "Finland",
      code: "FI",
    },
    {
      tis: 250,
      name: "France",
      code: "FR",
    },
    {
      tis: 258,
      name: "French Polynesia",
      code: "PF",
    },
    {
      tis: 262,
      name: "Djibouti",
      code: "DJ",
    },
    {
      tis: 266,
      name: "Gabon",
      code: "GA",
    },
    {
      tis: 268,
      name: "Georgia",
      code: "GE",
    },
    {
      tis: 270,
      name: "Gambia",
      code: "GM",
    },
    {
      tis: 276,
      name: "Germany",
      code: "DE",
    },
    {
      tis: 278,
      name: "German Democratic Republic",
      code: "German Democratic Republic",
    },
    {
      tis: 280,
      name: "Germany",
      code: "DE",
    },
    {
      tis: 288,
      name: "Ghana",
      code: "GH",
    },
    {
      tis: 296,
      name: "Kiribati",
      code: "KI",
    },
    {
      tis: 300,
      name: "Greece",
      code: "GR",
    },
    {
      tis: 308,
      name: "Grenada",
      code: "GD",
    },
    {
      tis: 320,
      name: "Guatemala",
      code: "GT",
    },
    {
      tis: 324,
      name: "Guinea",
      code: "GN",
    },
    {
      tis: 328,
      name: "Guyana",
      code: "GY",
    },
    {
      tis: 332,
      name: "Haiti",
      code: "HT",
    },
    {
      tis: 336,
      name: "Holy See (Vatican City State)",
      code: "VA",
    },
    {
      tis: 340,
      name: "Honduras",
      code: "HN",
    },
    {
      tis: 344,
      name: "Hong Kong",
      code: "HK",
    },
    {
      tis: 348,
      name: "Hungary",
      code: "HU",
    },
    {
      tis: 352,
      name: "Iceland",
      code: "IS",
    },
    {
      tis: 356,
      name: "India",
      code: "IN",
    },
    {
      tis: 360,
      name: "Indonesia",
      code: "ID",
    },
    {
      tis: 364,
      name: "Islamic Republic of Iran",
      code: "IR",
    },
    {
      tis: 368,
      name: "Iraq",
      code: "IQ",
    },
    {
      tis: 372,
      name: "Ireland",
      code: "IE",
    },
    {
      tis: 376,
      name: "Israel",
      code: "IL",
    },
    {
      tis: 380,
      name: "Italy",
      code: "IT",
    },
    {
      tis: 384,
      name: "Cote D'Ivoire",
      code: "Cote D'Ivoire",
    },
    {
      tis: 388,
      name: "Jamaica",
      code: "JM",
    },
    {
      tis: 392,
      name: "Japan",
      code: "JP",
    },
    {
      tis: 398,
      name: "Kazakhstan",
      code: "KZ",
    },
    {
      tis: 400,
      name: "Jordan",
      code: "JO",
    },
    {
      tis: 404,
      name: "Kenya",
      code: "KE",
    },
    {
      tis: 408,
      name: "Korea, Democratic People's Republic of",
      code: "KP",
    },
    {
      tis: 410,
      name: "Korea, Republic of",
      code: "KR",
    },
    {
      tis: 414,
      name: "Kuwait",
      code: "KW",
    },
    {
      tis: 417,
      name: "Kyrgyzstan",
      code: "KG",
    },
    {
      tis: 418,
      name: "Lao People's Democratic Republic",
      code: "LA",
    },
    {
      tis: 422,
      name: "Lebanon",
      code: "LB",
    },
    {
      tis: 426,
      name: "Lesotho",
      code: "LS",
    },
    {
      tis: 428,
      name: "Latvia",
      code: "LV",
    },
    {
      tis: 430,
      name: "Liberia",
      code: "LR",
    },
    {
      tis: 434,
      name: "Libya",
      code: "LY",
    },
    {
      tis: 438,
      name: "Liechtenstein",
      code: "LI",
    },
    {
      tis: 440,
      name: "Lithuania",
      code: "LT",
    },
    {
      tis: 442,
      name: "Luxembourg",
      code: "LU",
    },
    {
      tis: 450,
      name: "Madagascar",
      code: "MG",
    },
    {
      tis: 454,
      name: "Malawi",
      code: "MW",
    },
    {
      tis: 458,
      name: "Malaysia",
      code: "MY",
    },
    {
      tis: 462,
      name: "Maldives",
      code: "MV",
    },
    {
      tis: 466,
      name: "Mali",
      code: "ML",
    },
    {
      tis: 470,
      name: "Malta",
      code: "MT",
    },
    {
      tis: 478,
      name: "Mauritania",
      code: "MR",
    },
    {
      tis: 480,
      name: "Mauritius",
      code: "MU",
    },
    {
      tis: 484,
      name: "Mexico",
      code: "MX",
    },
    {
      tis: 492,
      name: "Monaco",
      code: "MC",
    },
    {
      tis: 496,
      name: "Mongolia",
      code: "MN",
    },
    {
      tis: 498,
      name: "Republic of Moldova",
      code: "Republic of Moldova,",
    },
    {
      tis: 499,
      name: "Montenegro",
      code: "ME",
    },
    {
      tis: 504,
      name: "Morocco",
      code: "MA",
    },
    {
      tis: 508,
      name: "Mozambique",
      code: "MZ",
    },
    {
      tis: 512,
      name: "Oman",
      code: "OM",
    },
    {
      tis: 516,
      name: "Namibia",
      code: "NA",
    },
    {
      tis: 520,
      name: "Nauru",
      code: "NR",
    },
    {
      tis: 524,
      name: "Nepal",
      code: "NP",
    },
    {
      tis: 528,
      name: "Netherlands",
      code: "NL",
    },
    {
      tis: 540,
      name: "New Caledonia",
      code: "NC",
    },
    {
      tis: 548,
      name: "Vanuatu",
      code: "VU",
    },
    {
      tis: 554,
      name: "New Zealand",
      code: "NZ",
    },
    {
      tis: 558,
      name: "Nicaragua",
      code: "NI",
    },
    {
      tis: 562,
      name: "Niger",
      code: "NE",
    },
    {
      tis: 566,
      name: "Nigeria",
      code: "NG",
    },
    {
      tis: 578,
      name: "Norway",
      code: "NO",
    },
    {
      tis: 583,
      name: "Federated States of Micronesia",
      code: "FM",
    },
    {
      tis: 584,
      name: "Marshall Islands",
      code: "MH",
    },
    {
      tis: 585,
      name: "Palau",
      code: "PW",
    },
    {
      tis: 586,
      name: "Pakistan",
      code: "PK",
    },
    {
      tis: 591,
      name: "Panama",
      code: "PA",
    },
    {
      tis: 598,
      name: "Papua New Guinea",
      code: "PG",
    },
    {
      tis: 600,
      name: "Paraguay",
      code: "PY",
    },
    {
      tis: 604,
      name: "Peru",
      code: "PE",
    },
    {
      tis: 608,
      name: "Philippines",
      code: "PH",
    },
    {
      tis: 616,
      name: "Poland",
      code: "PL",
    },
    {
      tis: 620,
      name: "Portugal",
      code: "PT",
    },
    {
      tis: 624,
      name: "Guinea-Bissau",
      code: "GW",
    },
    {
      tis: 626,
      name: "Timor-Leste",
      code: "TL",
    },
    {
      tis: 630,
      name: "Puerto Rico",
      code: "PR",
    },
    {
      tis: 634,
      name: "Qatar",
      code: "QA",
    },
    {
      tis: 642,
      name: "Romania",
      code: "RO",
    },
    {
      tis: 643,
      name: "Russian Federation",
      code: "RU",
    },
    {
      tis: 646,
      name: "Rwanda",
      code: "RW",
    },
    {
      tis: 659,
      name: "Saint Kitts And Nevis",
      code: "KN",
    },
    {
      tis: 662,
      name: "Saint Lucia",
      code: "LC",
    },
    {
      tis: 670,
      name: "Saint Vincent And The Grenadines",
      code: "VC",
    },
    {
      tis: 674,
      name: "San Marino",
      code: "SM",
    },
    {
      tis: 678,
      name: "Sao Tome And Principe",
      code: "ST",
    },
    {
      tis: 682,
      name: "Saudi Arabia",
      code: "SA",
    },
    {
      tis: 686,
      name: "Senegal",
      code: "SN",
    },
    {
      tis: 688,
      name: "Serbia",
      code: "RS",
    },
    {
      tis: 690,
      name: "Seychelles",
      code: "SC",
    },
    {
      tis: 694,
      name: "Sierra Leone",
      code: "SL",
    },
    {
      tis: 702,
      name: "Singapore",
      code: "SG",
    },
    {
      tis: 703,
      name: "Slovakia",
      code: "SK",
    },
    {
      tis: 704,
      name: "Viet Nam",
      code: "VN",
    },
    {
      tis: 705,
      name: "Slovenia",
      code: "SI",
    },
    {
      tis: 706,
      name: "Somalia",
      code: "SO",
    },
    {
      tis: 710,
      name: "South Africa",
      code: "ZA",
    },
    {
      tis: 716,
      name: "Zimbabwe",
      code: "ZW",
    },
    {
      tis: 720,
      name: "Democratic Yemen",
      code: "Democratic Yemen",
    },
    {
      tis: 724,
      name: "Spain",
      code: "ES",
    },
    {
      tis: 728,
      name: "South Sudan",
      code: "SS",
    },
    {
      tis: 729,
      name: "Sudan",
      code: "SD",
    },
    {
      tis: 732,
      name: "Western Sahara",
      code: "EH",
    },
    {
      tis: 736,
      name: "Sudan",
      code: "SD",
    },
    {
      tis: 740,
      name: "Suriname",
      code: "SR",
    },
    {
      tis: 748,
      name: "Swaziland",
      code: "SZ",
    },
    {
      tis: 752,
      name: "Sweden",
      code: "SE",
    },
    {
      tis: 756,
      name: "Switzerland",
      code: "CH",
    },
    {
      tis: 760,
      name: "Syrian Arab Republic",
      code: "SY",
    },
    {
      tis: 762,
      name: "Tajikistan",
      code: "TJ",
    },
    {
      tis: 764,
      name: "Thailand",
      code: "TH",
    },
    {
      tis: 768,
      name: "Togo",
      code: "TG",
    },
    {
      tis: 776,
      name: "Tonga",
      code: "TO",
    },
    {
      tis: 780,
      name: "Trinidad And Tobago",
      code: "TT",
    },
    {
      tis: 784,
      name: "United Arab Emirates",
      code: "AE",
    },
    {
      tis: 788,
      name: "Tunisia",
      code: "TN",
    },
    {
      tis: 792,
      name: "Turkey",
      code: "TR",
    },
    {
      tis: 795,
      name: "Turkmenistan",
      code: "TM",
    },
    {
      tis: 798,
      name: "Tuvalu",
      code: "TV",
    },
    {
      tis: 800,
      name: "Uganda",
      code: "UG",
    },
    {
      tis: 804,
      name: "Ukraine",
      code: "UA",
    },
    {
      tis: 807,
      name: "Macedonia",
      code: "MK",
    },
    {
      tis: 810,
      name: "USSR",
      code: "USSR",
    },
    {
      tis: 818,
      name: "Egypt",
      code: "EG",
    },
    {
      tis: 826,
      name: "United Kingdom",
      code: "GB",
    },
    {
      tis: 834,
      name: "United Republic of Tanzania",
      code: "TZ",
    },
    {
      tis: 840,
      name: "United States",
      code: "US",
    },
    {
      tis: 854,
      name: "Burkina Faso",
      code: "BF",
    },
    {
      tis: 858,
      name: "Uruguay",
      code: "UY",
    },
    {
      tis: 860,
      name: "Uzbekistan",
      code: "UZ",
    },
    {
      tis: 862,
      name: "Venezuela",
      code: "VE",
    },
    {
      tis: 882,
      name: "Samoa",
      code: "WS",
    },
    {
      tis: 886,
      name: "Yemen",
      code: "YE",
    },
    {
      tis: 887,
      name: "Yemen",
      code: "YE",
    },
    {
      tis: 890,
      name: "Yugoslavia",
      code: "Yugoslavia",
    },
    {
      tis: 891,
      name: "Serbia And Montenegro",
      code: "Serbia And Montenegro",
    },
    {
      tis: 894,
      name: "Zambia",
      code: "ZM",
    },
    {
      tis: 2100,
      name: "Africa",
      code: "Africa",
    },
    {
      tis: 2101,
      name: "America",
      code: "US",
    },
    {
      tis: 2102,
      name: "American Continent",
      code: "American Continent",
    },
    {
      tis: 2103,
      name: "Antilles",
      code: "Antilles",
    },
    {
      tis: 2104,
      name: "Apec Countries",
      code: "Apec Countries",
    },
    {
      tis: 2105,
      name: "Asean Countries",
      code: "Asean Countries",
    },
    {
      tis: 2106,
      name: "Asia",
      code: "Asia",
    },
    {
      tis: 2107,
      name: "Australasia",
      code: "Australasia",
    },
    {
      tis: 2108,
      name: "Balkans",
      code: "Balkans",
    },
    {
      tis: 2109,
      name: "Baltic States",
      code: "Baltic States",
    },
    {
      tis: 2110,
      name: "Benelux",
      code: "Benelux",
    },
    {
      tis: 2111,
      name: "British Isles",
      code: "British Isles",
    },
    {
      tis: 2112,
      name: "British West Indies",
      code: "British West Indies",
    },
    {
      tis: 2113,
      name: "Central America",
      code: "Central America",
    },
    {
      tis: 2114,
      name: "Commonwealth",
      code: "Commonwealth",
    },
    {
      tis: 2115,
      name: "Commonwealth African Territories",
      code: "Commonwealth African Territories",
    },
    {
      tis: 2116,
      name: "Commonwealth Asian Territories",
      code: "Commonwealth Asian Territories",
    },
    {
      tis: 2117,
      name: "Commonwealth Australasian Territories",
      code: "Commonwealth Australasian Territories",
    },
    {
      tis: 2118,
      name: "Commonwealth of Independent States",
      code: "Commonwealth of Independent States",
    },
    {
      tis: 2119,
      name: "Eastern Europe",
      code: "Eastern Europe",
    },
    {
      tis: 2120,
      name: "Europe",
      code: "Europe",
    },
    {
      tis: 2121,
      name: "European Economic Area",
      code: "European Economic Area",
    },
    {
      tis: 2122,
      name: "European Continent",
      code: "European Continent",
    },
    {
      tis: 2123,
      name: "European Union",
      code: "European Union",
    },
    {
      tis: 2124,
      name: "GSA Countries",
      code: "GSA Countries",
    },
    {
      tis: 2125,
      name: "Middle East",
      code: "Middle East",
    },
    {
      tis: 2126,
      name: "Nafta Countries",
      code: "Nafta Countries",
    },
    {
      tis: 2127,
      name: "Nordic Countries",
      code: "Nordic Countries",
    },
    {
      tis: 2128,
      name: "North Africa",
      code: "North Africa",
    },
    {
      tis: 2129,
      name: "North America",
      code: "North America",
    },
    {
      tis: 2130,
      name: "Oceania",
      code: "Oceania",
    },
    {
      tis: 2131,
      name: "Scandinavia",
      code: "Scandinavia",
    },
    {
      tis: 2132,
      name: "South America",
      code: "South America",
    },
    {
      tis: 2133,
      name: "South East Asia",
      code: "South East Asia",
    },
    {
      tis: 2134,
      name: "West Indies",
      code: "West Indies",
    },
    {
      tis: 2136,
      name: "World",
      code: "World",
    },
  ];
  return {
    countries: data,
    byCode: (tis: number): TISTerritory => {
      const found = data.find((country) => country.tis === tis);
      if (!found) throw Error(`invalid territory code ${tis}`);
      return found;
    },
  };
}
