import React from "react";

import "./layout.scss";

export const Footer = () => {
  return (
    <footer className="ddex-footer border-top">
      <p className="footer-text">
        Made by
        <br />
        <a href="http://screenstaring.com" className="footer-text">
          ScreenStaring
        </a>
      </p>
    </footer>
  );
};
