import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const PriceInformation = React.lazy(() =>
  import("../PriceInformation").then((module) => ({
    default: module.PriceInformation,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Bulk Order Wholesale Price Per Unit",
  "Price Code",
  "Price Type",
  "Suggested Retail Price",
  "Wholesale Price Per Unit",
  "Namespace",
  "User Defined Value",
];

export const PriceInformationsTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Tab.Pane eventKey="price_informations" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => (
          <React.Fragment key={index}>
            <td>{el.bulkOrderWholesalePricePerUnit?.value || ""}</td>
            <td>{el.priceCode?.value || ""}</td>
            <td>{el.priceType || ""}</td>
            <td>{el.suggestedRetailPrice?.value || ""}</td>
            <td>{el.wholesalePricePerUnit?.value || ""}</td>
            <td>{el.namespace || ""}</td>
            <td>{el.userDefinedValue || ""}</td>
          </React.Fragment>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <PriceInformation onChange={onChange} value={value} />
          </React.Suspense>
        )}
        modalTitle="Price Information"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
