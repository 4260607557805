import React, { useState, useEffect, useRef } from "react";
import { Tab, Form } from "react-bootstrap";
import { Input } from "../Input";
import { SelectData } from "../SelectData";
import { getOption } from "../../utils/parse-options";
import data from "../../data/cue-sheet-type.json";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="cue_sheet_reference"
          label="Cue Sheet Reference"
          invalidFeedback="Please provide a valid value."
          prependText="Q"
          pattern="[\d\-_a-zA-Z]+$"
          value={generalData.cueSheetReference || ""}
          onChange={(cueSheetReference) =>
            onUpdate({ ...generalData, cueSheetReference })
          }
        />
        <SelectData
          id="cue_sheet_type"
          data={data}
          label="Cue Sheet Type"
          value={{
            label: getOption(generalData.cueSheetType || ""),
            value: generalData.cueSheetType || "",
          }}
          onChoose={(change: any) =>
            onUpdate({ ...generalData, cueSheetType: change.value })
          }
        />
      </Form>
    </Tab.Pane>
  );
};
