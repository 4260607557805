import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { Resources } from "../../contexts/resource";
import { getOption } from "../../utils/parse-options";

const Contributor = React.lazy(() =>
  import("../Contributor").then((module) => ({
    default: module.Contributor,
  }))
);

const Input = React.lazy(() =>
  import("../Input").then((module) => ({
    default: module.Input,
  }))
);

interface Props {
  isSortable?: boolean;
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Character Party Reference",
  "Contributor Party Reference",
  "Instrument Type",
  "Contribution",
  "Display Credits",
];

export const CharacterTab = (props: Props) => {
  const ctxt: any = React.useContext(Resources);
  const ctrKey = ctxt.contributor?.key;

  const [characters, setCharacters] = useState<any[]>([]);
  const [groups, setGroups] = useState<any>({});
  const [data, add, remove, update, , setDefault] = useArray<any>();

  const getContributionField = (el: any) => {
    const data = [];

    if (ctrKey) {
      if (el[ctrKey]?.hasMadeFeaturedContribution) data.push("Featured");
      if (el[ctrKey]?.hasMadeContractedContribution) data.push("Contracted");
    } else {
      if (el.hasMadeFeaturedContribution) data.push("Featured");
      if (el.hasMadeContractedContribution) data.push("Contracted");
    }

    return data.join(", ");
  };

  const getRowsPan = (key: string, index: number) => {
    if (groups[key] === 1) return 1;

    const groupKeys = Object.keys(groups);
    const currentIndex = groupKeys.indexOf(key);
    let prev = 0;

    groupKeys.forEach((groupKey: string, index) => {
      if (groupKey !== key && index < currentIndex) {
        prev += groups[groupKey];
      }
    });

    if (index - prev === 0) return groups[key];

    return 0;
  };

  const updateCharacter = (data: any, index: number) => {
    update(data, characters[index].key);
  };

  const removeCharacter = (index: number) => {
    remove(characters[index].key);
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    if (data.length) {
      const characters = data.map((el: any, key: number) => {
        if (!el.characterPartyReference) {
          return {
            ...el,
            key,
            characterPartyReference: "empty",
          };
        }

        return {
          ...el,
          key,
        };
      });

      const groups: { [key: string]: number } = {};

      characters.forEach((el: any) => {
        const elValue: string = el.characterPartyReference;

        if (typeof groups[elValue] === "undefined") {
          groups[elValue] = 1;
        } else {
          groups[elValue] += 1;
        }
      });

      setCharacters(characters);
      setGroups(groups);
    } else {
      setCharacters([]);
      setGroups({});
    }
  }, [data]);

  return (
    <Tab.Pane eventKey="characters" className="page__tab-content">
      <TableComponent
        add={add}
        data={characters}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => {
          const rowSpan = getRowsPan(el.characterPartyReference, index);

          return (
            <>
              {rowSpan ? (
                <td rowSpan={rowSpan}>{el.characterPartyReference}</td>
              ) : null}
              <td>
                {ctrKey
                  ? el[ctrKey]?.contributorPartyReference
                  : el.contributorPartyReference}
              </td>
              <td>
                {(el[ctrKey]?.instrumentTypes || el.instrumentTypes || [])
                  .map((el: any) => getOption(el.value))
                  .join(", ")}
              </td>
              <td>{getContributionField(el)}</td>
              <td>
                {(el[ctrKey]?.displayCredits || el.displayCredits || [])
                  .length || "None"}
              </td>
            </>
          );
        }}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <>
            <React.Suspense fallback={null}>
              <Input
                id="character-reference"
                label="Character Party Reference"
                invalidFeedback="Please provide a valid value."
                pattern="^[\d\-_a-zA-Z]+$"
                prependText="P"
                value={value?.characterPartyReference || ""}
                onChange={(characterPartyReference) =>
                  onChange({ characterPartyReference })
                }
              />
            </React.Suspense>
            <React.Suspense fallback={null}>
              <Contributor
                id="char-modal"
                onChange={(data) => {
                  if (ctrKey) {
                    onChange({
                      [ctrKey]: {
                        ...(value?.[ctrKey] || {}),
                        ...data,
                      },
                    });
                  } else {
                    onChange(data);
                  }
                }}
                value={ctrKey ? value[ctrKey] || {} : value}
              />
            </React.Suspense>
          </>
        )}
        modalTitle="Character"
        onSortTable={setDefault}
        remove={removeCharacter}
        update={updateCharacter}
        isSortable={props.isSortable}
      />
    </Tab.Pane>
  );
};
