import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const Input = React.lazy(() =>
  import("../Input").then((module) => ({
    default: module.Input,
  }))
);

const LanguageScriptField = React.lazy(() =>
  import("../LanguageField").then((module) => ({
    default: module.LanguageScriptField,
  }))
);

interface Props {
  onChange: (data: any, noData: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

const ResetComponent = (props: any) => (
  <Form.Group>
    <Form.Check
      checked={props.checked || false}
      id="reset"
      type="checkbox"
      label="No cue sheet"
      onChange={props.onChange}
    />
  </Form.Group>
);

export const VideoCueSheetReferencesTab = (props: Props) => {
  const [noCueSheet, setNoCueSheet] = useState(false);
  const [data, add, remove, update, , setDefault] = useArray<any>();
  const [noData, setNoData] = useState<any>({});

  const formRef: any = useRef(null);

  useEffect(() => {
    if (props.validated) {
      if (formRef.current) {
        props.setValidStatus(formRef.current.checkValidity());
      } else {
        props.setValidStatus(true);
      }
    }
  }, [props.validated, data, noData]);

  useEffect(() => {
    if (!data.length && props.value?.videoCueSheetReferences?.length) {
      setDefault(props.value?.videoCueSheetReferences);
      setNoCueSheet(false);
    } else if (
      !data.length &&
      (props.value.reasonForCueSheetAbsence.value ||
        props.value.reasonForCueSheetAbsence.languageAndScriptCode) &&
      !isEqual(noData, {
        reasonForCueSheetAbsence: props.value.reasonForCueSheetAbsence,
      })
    ) {
      setNoData({
        reasonForCueSheetAbsence: props.value.reasonForCueSheetAbsence,
      });
      setNoCueSheet(true);
    }
  }, [props.value]);

  useEffect(() => {
    props.onChange(data, noData);
  }, [data, noData]);

  return (
    <Tab.Pane
      eventKey="video-cue-sheet-reference"
      className="page__tab-content"
    >
      {noCueSheet ? (
        <>
          <ResetComponent
            checked={true}
            onChange={() => {
              setNoCueSheet(false);
              setNoData({ reasonForCueSheetAbsence: {} });
              setDefault([]);
            }}
          />
          <Form validated={props.validated} ref={formRef}>
            <React.Suspense fallback={null}>
              <Input
                id="reason"
                label="Reason"
                value={noData?.reasonForCueSheetAbsence?.value || ""}
                onChange={(value) =>
                  setNoData({
                    reasonForCueSheetAbsence: {
                      ...(noData.reasonForCueSheetAbsence || {}),
                      value,
                    },
                  })
                }
              />
            </React.Suspense>
            <React.Suspense fallback={null}>
              <LanguageScriptField
                idDiff="lang-and-script"
                label="Language and Script Code"
                value={
                  noData?.reasonForCueSheetAbsence?.languageAndScriptCode || ""
                }
                onChange={(languageAndScriptCode: any) =>
                  setNoData({
                    reasonForCueSheetAbsence: {
                      ...(noData.reasonForCueSheetAbsence || {}),
                      languageAndScriptCode,
                    },
                  })
                }
              />
            </React.Suspense>
          </Form>
        </>
      ) : (
        <TableComponent
          add={add}
          data={data || []}
          emptyElement={{}}
          fields={["Cue Sheet Reference"]}
          getCells={(el: any) => (
            <>
              <td>{el?.reference || ""}</td>
            </>
          )}
          getModalContent={(value: any, onChange: (data: any) => void) => (
            <React.Suspense fallback={null}>
              <Input
                id="cue-sheet-reference"
                label="Video Cue Sheet Reference"
                invalidFeedback="Please provide a valid value."
                prependText="Q"
                pattern="^[\d\-_a-zA-Z]+$"
                value={value?.reference || ""}
                onChange={(reference) => onChange({ reference })}
              />
            </React.Suspense>
          )}
          modalTitle="Cue Sheet Reference"
          remove={remove}
          update={update}
          ResetComponent={() => (
            <ResetComponent
              checked={noCueSheet}
              onChange={() => {
                setNoCueSheet(true);
                setNoData({ reasonForCueSheetAbsence: {} });
                setDefault([]);
              }}
            />
          )}
        />
      )}
    </Tab.Pane>
  );
};
