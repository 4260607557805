import React, { useState, useEffect, useRef } from "react";
import { Tab, Form } from "react-bootstrap";
import { LanguageScriptField } from "../LanguageField";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <LanguageScriptField
          idDiff="general-language-field"
          value={generalData.languageAndScriptCode || ""}
          onChange={(languageAndScriptCode) =>
            onUpdate({ ...generalData, languageAndScriptCode })
          }
        />
      </Form>
    </Tab.Pane>
  );
};
