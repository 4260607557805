import { transformToCamelCase, transformToSnakeCase } from "./base-converters";

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    release_visibility: converted || {},
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return converted.releaseVisibility || {};
};

export default {
  getDataForUpdate,
  getDataForSet,
};
