import {
  transformToCamelCase,
  transformToSnakeCase,
  convertDisplayArtistsForTable,
  convertDisplayArtistsForApi,
  convertBaseIdForTable,
  convertBaseIdForApi,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getGeneralDataForUpdate = (data: any) => ({
  ...(data || {}),
  ...(data?.type ? { type: data.type } : {}),
});

const getGeneralDataForSet = (data: any) => ({
  ...(data || {}),
  ...(data?.type ? { type: data.type } : { type: {} }),
});

const getDatesForUpdate = (data: any) => ({
  creation_date: data.creation_date?.value ? data.creation_date : undefined,
  mastered_date: data.mastered_date?.value ? data.mastered_date : undefined,
  remastered_date: data.remastered_date?.value
    ? data.remastered_date
    : undefined,
  first_publication_dates: (data.first_publication_dates || []).length
    ? data.first_publication_dates
    : undefined,
});

const getDatesForSet = (data: any) => ({
  creationDate: data.creationDate || {},
  masteredDate: data.masteredDate || {},
  remasteredDate: data.remasteredDate || {},
  firstPublicationDates: data.firstPublicationDates || [],
});

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    text: {
      ...getGeneralDataForUpdate(converted.general_data),
      ...getDatesForUpdate(converted.dates || {}),
      additional_titles: convertAddTitlesForApi(converted.add_titles || []),
      c_lines: converted.c_lines || [],
      contributors: convertBaseIdForApi(converted.contributors || []),
      courtesy_lines: converted.courtesy_lines || [],
      display_artists: convertDisplayArtistsForApi(
        converted.display_artists || []
      ),
      display_artist_names: converted.display_artist_names || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      parental_warning_types: converted.parental_warning_types,
      related_releases: converted.related_releases || [],
      related_resources: converted.related_resources || [],
      resource_ids: converted.resource_ids || [],
      resource_rights_controllers: converted.resource_rights_controllers || [],
      resource_contained_resource_reference_list:
        converted.resource_contained_resource_reference_list || [],
      technical_details: converted.technical_details || [],
      version_types: converted.version_types || [],
      work_ids: converted.work_ids || [],
      work_rights_controllers: converted.work_rights_controllers || [],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: getGeneralDataForSet(converted.text),
    addTitles: convertAddTitlesForTable(converted.text.additionalTitles || []),
    cLines: converted.text.cLines || [],
    contributors: convertBaseIdForTable(converted.text.contributors || []),
    courtesyLines: converted.text.courtesyLines || [],
    dates: getDatesForSet(converted.text),
    displayArtists: convertDisplayArtistsForTable(
      converted.text.displayArtists || []
    ),
    displayArtistNames: converted.text.displayArtistNames,
    displayTitles: convertAddTitlesForTable(converted.text.displayTitles || []),
    displayTitleTexts: converted.text.displayTitleTexts,
    parentalWarningTypes: converted.text.parentalWarningTypes,
    relatedReleases: converted.text.relatedReleases,
    relatedResources: converted.text.relatedResources || [],
    resourceIds: converted.text.resourceIds || [],
    resourceContainedResourceReferenceList:
      converted.text.resourceContainedResourceReferenceList || [],
    resourceRightsControllers: converted.text.resourceRightsControllers || [],
    technicalDetails: converted.text.technicalDetails || [],
    versionTypes: converted.text.versionTypes || [],
    workIds: converted.text.workIds || [],
    workRightsControllers: converted.text.workRightsControllers || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
