import React, { useEffect, useState, useRef } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import { GeneralTab } from "../../components/tabs-software/GeneralTab";
import { AdditionalTitleTab } from "../../components/tables/AdditionalTitleTab";
import { CLineTab } from "../../components/tables/CLineTab";
import { ContributorTab } from "../../components/tables/ContributorTab";
import { CourtesyLineTab } from "../../components/tables/CourtesyLineTab";
import { DatesTab } from "../../components/tabs-software/DatesTab";
import { DisplayArtistNameTab } from "../../components/tables/DisplayArtistNameTab";
import { DisplayArtistTab } from "../../components/tables/DisplayArtistTab";
import { DisplayTitleTab } from "../../components/tables/DisplayTitleTab";
import { DisplayTitleTextTab } from "../../components/tables/DisplayTitleTextTab";
import { ParentalWarningTypeWithTerritoryTab } from "../../components/ParentalWarningTypeWithTerritoryTab";
import { PLineTab } from "../../components/tables/PLineTab";
import { RelatedReleaseTab } from "../../components/tables/RelatedReleaseTab";
import { RelatedResourceTab } from "../../components/tables/RelatedResourceTab";
import { ResourceIdTab } from "../../components/tabs-software/ResourceIdTab";
import { ResourceContainedResourceReferenceTab } from "../../components/tables/ResourceContainedResourceReferenceTab";
import { ResourceRightsControllerTab } from "../../components/tables/ResourceRightsControllerTab";
import { TechnicalDetailsTab } from "../../components/tabs-software/TechnicalDetailsTab";
import { VersionTypeTab } from "../../components/tables/VersionTypeTab";
import { WorkIdsTab } from "../../components/tables/WorkIdsTab";
import { WorkRightsControllerTab } from "../../components/tables/WorkRightsControllerTab";
import { Resources as Context, Value_4_2 } from "../../contexts/resource";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/software";
import pageConverter from "../../api-converters/software";

import "../page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "additionalTitle", title: "Additional Title" },
  { id: "cline", title: "CLine" },
  { id: "contributors", title: "Contributor" },
  { id: "courtesyLines", title: "Courtesy Line" },
  { id: "dates", title: "Dates" },
  { id: "artist", title: "Display Artist" },
  { id: "artistName", title: "Display Artist Name" },
  { id: "displayTitle", title: "Display Title" },
  { id: "displayTitleText", title: "Display Title Text" },
  { id: "parentalWarnings", title: "Parental Warnings" },
  { id: "pline", title: "PLine" },
  { id: "relatedRelease", title: "Related Release" },
  { id: "relatedResource", title: "Related Resource" },
  { id: "resourceContained", title: "Resource Contained Resource Reference" },
  { id: "resourceid", title: "Resource IDs" },
  { id: "resourseRightsController", title: "Resourse Rights Controller" },
  { id: "technicalDetails", title: "Technical Details" },
  { id: "versionType", title: "Version Type" },
  { id: "workids", title: "Work IDs" },
  { id: "workRightsController", title: "Work Rights Controller" },
];

export const Software = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        id?: string;
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
    setSuccessAlertData: (method: string) => void;
  }) => {
    const id = props.match?.params?.id;
    const history = useHistory();
    const timeout: any = useRef(null);
    const validStatuses: any = useRef({
      general: undefined,
      dates: undefined,
    });

    const maxTabWidth = 155;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState<any>({});

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const updateData = (data: any) => {
      setUpdatedData(data);
      setValidated(false);
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getDataForSet(data);

      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchData(
            id,
            props.match.params.newReleaseMessageId,
            data
          ),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      } else {
        props.api({
          ...pageApi.postData(props.match.params.newReleaseMessageId, data),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);

            history.push(
              `/ern/${props.match.params.newReleaseMessageId}/resources/software/${data.id}`,
              { showSuccessAlert: true }
            );
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      }
    };

    const submit = () => {
      setValidated(true);
      setIsValid({});

      timeout.current = setTimeout(() => {
        const allKeys = Object.keys(validStatuses.current);
        const validKeys = allKeys.filter(
          (el: any) => validStatuses.current[el]
        ).length;

        if (validKeys === allKeys.length) {
          setValidated(false);
          setDataSaving(true);
          saveData(updatedData);
        } else {
          props.setAlertData(
            ["Please correct the errors in the highlighted tabs and try again"],
            0
          );
          setDataSaving(false);
        }

        setIsValid(validStatuses.current);
      }, 1000);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(id, props.match?.params?.newReleaseMessageId),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }

      if (props.history?.location?.state?.showSuccessAlert) {
        props.setSuccessAlertData("post");
      }

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, []);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="page">
          <h2 className="page__title">{id ? "Edit" : "Add"} Software</h2>
          <Context.Provider value={Value_4_2}>
            <Tab.Container defaultActiveKey="general">
              <Col
                sm={12}
                className="page__tab-links-container"
                ref={tabsRef}
                style={{
                  width: `${tabsWidth}px`,
                  overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
                }}
              >
                <Nav
                  fill
                  variant="tabs"
                  style={{
                    width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
                  }}
                >
                  {tabs.map((el: any) => (
                    <Nav.Item key={el.id}>
                      <Nav.Link
                        eventKey={el.id}
                        className={
                          isValid[el.id] === false && validated
                            ? "error-tab"
                            : ""
                        }
                      >
                        {el.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <GeneralTab
                    onChange={(generalData: any) => {
                      updateData({
                        ...updatedData,
                        generalData: {
                          ...(updatedData.generalData || {}),
                          ...generalData,
                        },
                      });
                    }}
                    value={baseData.generalData}
                    setValidStatus={(general: boolean) => {
                      validStatuses.current.general = general;
                    }}
                    validated={validated}
                  />
                  <AdditionalTitleTab
                    onChange={(addTitles: any) => {
                      updateData({
                        ...updatedData,
                        addTitles,
                      });
                    }}
                    value={baseData.addTitles}
                  />
                  <CLineTab
                    onChange={(cLines: any) => {
                      updateData({
                        ...updatedData,
                        cLines,
                      });
                    }}
                    value={baseData.cLines}
                  />
                  <ContributorTab
                    isSortable={true}
                    onChange={(contributors: any) => {
                      updateData({
                        ...updatedData,
                        contributors,
                      });
                    }}
                    value={baseData.contributors}
                  />
                  <CourtesyLineTab
                    onChange={(courtesyLines: any) => {
                      updateData({
                        ...updatedData,
                        courtesyLines,
                      });
                    }}
                    value={baseData.courtesyLines}
                  />
                  <DatesTab
                    onChange={(dates: any) => {
                      updateData({
                        ...updatedData,
                        dates,
                      });
                    }}
                    setValidStatus={(dates: boolean) => {
                      validStatuses.current.dates = dates;
                    }}
                    validated={validated}
                    value={baseData.dates}
                  />
                  <DisplayArtistTab
                    onChange={(displayArtists: any) => {
                      updateData({
                        ...updatedData,
                        displayArtists,
                      });
                    }}
                    value={baseData.displayArtists}
                  />
                  <DisplayArtistNameTab
                    onChange={(displayArtistNames: any) => {
                      updateData({
                        ...updatedData,
                        displayArtistNames,
                      });
                    }}
                    value={baseData.displayArtistNames}
                  />
                  <DisplayTitleTab
                    onChange={(displayTitles: any) => {
                      updateData({
                        ...updatedData,
                        displayTitles,
                      });
                    }}
                    value={baseData.displayTitles}
                  />
                  <DisplayTitleTextTab
                    onChange={(displayTitleTexts: any) => {
                      updateData({
                        ...updatedData,
                        displayTitleTexts,
                      });
                    }}
                    value={baseData.displayTitleTexts}
                  />
                  <ParentalWarningTypeWithTerritoryTab
                    onChange={(parentalWarningTypes: any) => {
                      updateData({
                        ...updatedData,
                        parentalWarningTypes,
                      });
                    }}
                    value={baseData.parentalWarningTypes}
                  />
                  <PLineTab
                    onChange={(pLines: any) => {
                      updateData({
                        ...updatedData,
                        pLines,
                      });
                    }}
                    value={updatedData.plines}
                    withApplicableCode
                    withDefault
                  />
                  <RelatedReleaseTab
                    id={id}
                    newReleaseMessageId={
                      props.match?.params?.newReleaseMessageId
                    }
                    onChange={(relatedReleases: any) =>
                      updateData({
                        ...updatedData,
                        relatedReleases,
                      })
                    }
                    type="software"
                    value={baseData.relatedReleases}
                  />
                  <RelatedResourceTab
                    resourceKey="resourceId"
                    onChange={(relatedResources: any) => {
                      updateData({
                        ...updatedData,
                        relatedResources,
                      });
                    }}
                    value={baseData.relatedResources}
                  />
                  <ResourceContainedResourceReferenceTab
                    onChange={(value: any) => {
                      updateData({
                        ...updatedData,
                        resourceContainedResourceReferenceList: {
                          resourceContainedResourceReferences: value,
                        },
                      });
                    }}
                    value={
                      updatedData.resourceContainedResourceReferenceList
                        ?.resourceContainedResourceReferences
                    }
                  />
                  <ResourceIdTab
                    onChange={(resourceIds: any) => {
                      updateData({
                        ...updatedData,
                        resourceIds,
                      });
                    }}
                    value={baseData.resourceIds}
                  />
                  <ResourceRightsControllerTab
                    onChange={(resourceRightsControllers: any) => {
                      updateData({
                        ...updatedData,
                        resourceRightsControllers,
                      });
                    }}
                    value={baseData.resourceRightsControllers}
                  />
                  <TechnicalDetailsTab
                    onChange={(technicalDetails: any) => {
                      updateData({
                        ...updatedData,
                        technicalDetails,
                      });
                    }}
                    value={baseData.technicalDetails}
                  />
                  <VersionTypeTab
                    onChange={(versionTypes: any) => {
                      updateData({
                        ...updatedData,
                        versionTypes,
                      });
                    }}
                    value={baseData.versionTypes}
                  />
                  <WorkIdsTab
                    onChange={(workIds: any) => {
                      updateData({
                        ...updatedData,
                        workIds,
                      });
                    }}
                    value={baseData.workIds}
                  />
                  <WorkRightsControllerTab
                    onChange={(workRightsControllers: any) => {
                      updateData({
                        ...updatedData,
                        workRightsControllers,
                      });
                    }}
                    value={baseData.workRightsControllers}
                  />
                </Tab.Content>
              </Col>
            </Tab.Container>
            <div className="page__btn-container">
              <SpinnerBtn
                disabled={getIsEqual() || dataSaving}
                isLoading={dataSaving}
                onClick={submit}
                text={id ? "Update" : "Save"}
              />
            </div>
          </Context.Provider>
        </div>
      </DndProvider>
    );
  }
);
