import React, { useEffect, useState } from "react";
import { Container, Button as LinkBtn } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { TypeModal } from "./TypeModal";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/release-lists";
import { Spacer } from "../../components/Spacer";
import { Dot } from "../../components/Dot";
import { getFormattedDate } from "../../utils/get-formatted-date";

export const ReleaseList = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    match: {
      params: {
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: any) => void;
  }) => {
    const id = props.match.params.newReleaseMessageId;
    const history = useHistory();

    const [releaseLists, setReleaseLists] = useState<any>([]);
    const [isOpenTypeModal, setIsOpenTypeModal] = useState(false);

    const toggleTypeModal = () => setIsOpenTypeModal(!isOpenTypeModal);

    const setType = (type: string) => {
      toggleTypeModal();

      if (type === "trackRelease") {
        history.push(
          `/ern/${props.match?.params?.newReleaseMessageId}/release-list/track-release`
        );
      } else {
        history.push(
          `/ern/${props.match?.params?.newReleaseMessageId}/release-list/releases`
        );
      }
    };

    const getMainKey = (data: any) => {
      if (data.release) {
        return {
          key: "release",
          title: "Release",
          url: "releases",
        };
      }

      if (data.track_release) {
        return {
          key: "track_release",
          title: "Track Release",
          url: "track-release",
        };
      }

      return { key: "-", title: "-" };
    };

    const getData = () => {
      props.api({
        ...pageApi.getData(id),
        skipAlert: true,
        successCb: ({ data }: any) => {
          if (data) {
            setReleaseLists(data);
          }
        },
      });
    };

    const deleteData = (deleteId: string, type: string) => {
      let key = "";

      switch (type) {
        case "release":
          key = "releases";
          break;
        case "track_release":
          key = "track_releases";
          break;
        default:
          break;
      }

      props.api({
        ...pageApi.deleteData(id, deleteId, key),
        successCb: getData,
      });
    };

    useEffect(() => {
      if (id) {
        getData();
      } else {
        props.setAlertData(["Please, create message_header_id"]);
      }
    }, []);

    return (
      <>
        <TypeModal
          isOpen={isOpenTypeModal}
          setType={setType}
          toggle={toggleTypeModal}
        />
        <Container>
          {!releaseLists.length ? (
            <>
              <h2>Release List</h2>
              <Spacer />
            </>
          ) : null}
          <div className="page__btn-container row">
            {!releaseLists.length ? <p>No releases</p> : <h2>Release List</h2>}
            <button className="btn btn-primary" onClick={toggleTypeModal}>
              Add&nbsp;Release
            </button>
          </div>
          {releaseLists.length ? (
            <>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>Release Type</th>
                    <th>Release Reference</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {releaseLists.map((el: any, i: number) => {
                    const data = getMainKey(el);

                    return (
                      <tr key={`sr-${i}`}>
                        <td>{data.title}</td>
                        <td>{el[data.key]?.release_reference || "-"}</td>
                        <td>{getFormattedDate(el.created_at)}</td>
                        <td>{getFormattedDate(el.updated_at)}</td>
                        <td>
                          <LinkBtn
                            variant="link"
                            onClick={() => {
                              history.push(
                                `/ern/${id}/release-list/${data.url}/${el.id}/`
                              );
                            }}
                          >
                            Edit
                          </LinkBtn>
                          <Dot />
                          <LinkBtn
                            variant="link"
                            onClick={() => {
                              deleteData(el.id, data.key);
                            }}
                          >
                            Delete
                          </LinkBtn>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : null}
        </Container>
      </>
    );
  }
);
