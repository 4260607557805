import React, { useEffect, useState } from "react";
import { Container, Button as LinkBtn } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Button";
import { Dot } from "../../components/Dot";
import { Modal } from "../../components/modals/Modal";
import { Select, Option } from "../../components/Select";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/resources";
import useToggle from "../../hooks/useToggle";
import { Spacer } from "../../components/Spacer";
import { getFormattedDate } from "../../utils/get-formatted-date";
import "../page.scss";

const options = [
  { value: "image", label: "Image" },
  { value: "sheet-music", label: "Sheet Music" },
  { value: "software", label: "Software" },
  { value: "sound-recording", label: "Sound Recording" },
  { value: "text", label: "Text" },
  { value: "video", label: "Video" },
];

export const Resources = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    match: {
      params: {
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: any) => void;
  }) => {
    const id = props.match.params.newReleaseMessageId;
    const history = useHistory();
    const [soundRecordings, setSoundRecordings] = useState<any>([]);
    const [show, toggle] = useToggle();
    const [type, setType] = useState<Option | null>(null);

    const addResourceRedirect = () => {
      if (type) {
        history.push(`/ern/${id}/resources/${type.value}`);
        toggle();
      }
    };

    const getData = () => {
      props.api({
        ...pageApi.getData(id),
        skipAlert: true,
        successCb: ({ data }: any) => {
          if (data) {
            setSoundRecordings(data);
          }
        },
      });
    };

    const deleteData = (deleteId: string, type: string) => {
      let key = "";

      switch (type) {
        case "sound_recording":
          key = "sound_recordings";
          break;
        case "image":
          key = "images";
          break;
        case "video":
          key = "videos";
          break;
        case "sheet_music":
          key = "sheet_music";
          break;
        case "software":
          key = "software";
          break;
        case "text":
          key = "text";
          break;
        default:
          break;
      }

      props.api({
        ...pageApi.deleteData(id, deleteId, key),
        successCb: getData,
      });
    };

    const clearInput = () => {
      setType(null);
    };

    const getMainKey = (data: any) => {
      if (data.image) {
        return {
          key: "image",
          title: "Image",
          url: "image",
        };
      }
      if (data.sound_recording) {
        return {
          key: "sound_recording",
          title: "Sound Recording",
          url: "sound-recording",
        };
      }
      if (data.video) {
        return {
          key: "video",
          title: "Video",
          url: "video",
        };
      }

      if (data.sheet_music) {
        return {
          key: "sheet_music",
          title: "Sheet Music",
          url: "sheet-music",
        };
      }

      if (data.software) {
        return {
          key: "software",
          title: "Software",
          url: "software",
        };
      }

      if (data.text) {
        return {
          key: "text",
          title: "Text",
          url: "text",
        };
      }

      return { key: "-", title: "-" };
    };

    useEffect(() => {
      if (id) {
        getData();
      } else {
        props.setAlertData(["Please, create message_header_id"]);
      }
    }, []);

    useEffect(() => {
      clearInput();
    }, [show]);

    return (
      <>
        {show && (
          <Modal onClose={toggle} title="Add Resource">
            <div className="form-group">
              <Select
                id="index-type"
                onChoose={setType}
                label="Type"
                options={options}
              />
            </div>
            <div className="modal-footer">
              <Button variant="btn-secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button disabled={type === null} onClick={addResourceRedirect}>
                Add
              </Button>
            </div>
          </Modal>
        )}
        <Container>
          {!soundRecordings.length ? (
            <>
              <h2>Resource List</h2>
              <Spacer />
            </>
          ) : null}
          <div className="page__btn-container row">
            {!soundRecordings.length ? (
              <p>No resources</p>
            ) : (
              <h2>Resource List</h2>
            )}
            <button className="btn btn-primary" onClick={toggle}>
              Add&nbsp;Resource
            </button>
          </div>
          {soundRecordings.length ? (
            <>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>Resource Type</th>
                    <th>Resource Reference</th>
                    <th>Type</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {soundRecordings.map((el: any, i: number) => {
                    const data = getMainKey(el);

                    return (
                      <tr key={`sr-${i}`}>
                        <td>{data.title}</td>
                        <td>{el[data.key]?.resource_reference || "-"}</td>
                        <td>
                          {typeof el[data.key].type?.value === "string"
                            ? el[data.key].type?.value
                            : "-"}
                        </td>
                        <td>{getFormattedDate(el.created_at)}</td>
                        <td>{getFormattedDate(el.updated_at)}</td>
                        <td>
                          <LinkBtn
                            variant="link"
                            onClick={() => {
                              history.push(
                                `/ern/${id}/resources/${data.url}/${el.id}/`
                              );
                            }}
                          >
                            Edit
                          </LinkBtn>
                          <Dot />
                          <LinkBtn
                            variant="link"
                            onClick={() => {
                              deleteData(el.id, data.key);
                            }}
                          >
                            Delete
                          </LinkBtn>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : null}
        </Container>
      </>
    );
  }
);
