import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ExportBtn } from "./ExportBtn";
import "./breadcrumbs.scss";

export const Breadcrumbs = (props: {
  id?: string;
  parents: {
    link?: string;
    name: string;
  }[];
}) => {
  const elements = [
    {
      name: "ERN",
      link: "/ern",
    },
    { name: "NewReleaseMessage" },
    ...(props.parents || []),
  ];

  return (
    <div className="breadcrumbs-container">
      <Breadcrumb>
        {elements.map((el: any, i: number) => (
          <React.Fragment key={`${el.name}_${i}`}>
            <Breadcrumb.Item
              href={el.link}
              linkProps={{ to: el.link }}
              linkAs={Link}
              active={window.location.pathname === el.link || !el.link}
            >
              <span className="breadcrumbs-link">
                {el.name.replace(/-/g, " ")}
              </span>
            </Breadcrumb.Item>
          </React.Fragment>
        ))}
      </Breadcrumb>
      {props.id ? (
        <div className="export-btn-container">
          <ExportBtn id={props.id} variant="success" />
        </div>
      ) : null}
    </div>
  );
};
