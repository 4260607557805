const getData = (id: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${id}/cue_sheet_lists`,
});

const deleteData = (newReleaseMessageId: string, id: string) => ({
  method: "delete",
  url: `/ern/new_release_messages/${newReleaseMessageId}/cue_sheet_lists/cue_sheets/${id}`,
});

export default {
  getData,
  deleteData,
};
