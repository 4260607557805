import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { getOption } from "../../utils/parse-options";
import { TableComponent } from "../TableComponent";

const options = [
  "AsPerContract",
  "ConnectedDevice",
  "GameConsole",
  "Jukebox",
  "KaraokeMachine",
  "Kiosk",
  "LocalStorageJukebox",
  "PersonalComputer",
  "PhysicalMediaWriter",
  "PortableDevice",
  "RemoteStorageJukebox",
  "Unknown",
  "UserDefined",
];

const UserDefinedSelect = React.lazy(() =>
  import("../UserDefinedSelect").then((module) => ({
    default: module.UserDefinedSelect,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = ["User Interface Type", "Namespace", "User Defined Value"];

export const UserInterfaceTypesTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Tab.Pane eventKey="user_interface_types" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => (
          <React.Fragment key={index}>
            <td>{getOption(el.value || "")}</td>
            <td>{el.namespace || ""}</td>
            <td>{el.userDefinedValue || ""}</td>
          </React.Fragment>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <UserDefinedSelect
              dataSelect={true}
              id="user_interface_types"
              label="User Interface Type"
              onChange={onChange}
              value={value}
              options={options}
              valueKey="value"
              withNamespace
            />
          </React.Suspense>
        )}
        modalTitle="User Interface Type"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
