export const getHashSum = (val?: string) => {
  if (val && val.length > 32) {
    return `${val.substring(0, 33)}...`;
  }

  return val || "";
};

export const getAlgorithmLabel = (val?: string) => {
  if (val) return `(${val})`;

  return "";
};
