import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const Input = React.lazy(() =>
  import("../Input").then((module) => ({
    default: module.Input,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = ["Audio Chapter Reference"];

export const AudioChapterReferenceTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="audioChapterReference" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => (
          <React.Fragment key={index}>
            <td>{el?.audioChapterReference || ""}</td>
          </React.Fragment>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <Input
              id="audio-chapter-reference"
              label="Audio Chapter Reference"
              onChange={(audioChapterReference: string) =>
                onChange({ audioChapterReference })
              }
              pattern="[-\d_a-zA-Z]+"
              prependText="X"
              value={value?.audioChapterReference || ""}
            />
          </React.Suspense>
        )}
        modalTitle="Audio Chapter Reference"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
