import converter from "../api-converters/related-releases";

const patchData = (
  id: string,
  newReleaseMessageId: string,
  resourceData: any,
  data: any,
  type: string,
  key?: number
) => {
  switch (type) {
    case "sound-recording":
      return {
        method: "patch",
        url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/sound_recordings/${id}`,
        data: converter.getDataForUpdate(resourceData, data, type, key),
      };
    case "sheet-music":
      return {
        method: "patch",
        url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/sheet_music/${id}`,
        data: converter.getDataForUpdate(resourceData, data, type, key),
      };
    case "image":
    case "video":
    case "text":
      return {
        method: "patch",
        url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/${type}s/${id}`,
        data: converter.getDataForUpdate(resourceData, data, type, key),
      };
    case "software":
      return {
        method: "patch",
        url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/${type}/${id}`,
        data: converter.getDataForUpdate(resourceData, data, type, key),
      };
    case "release":
      return {
        method: "patch",
        url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/${type}s/${id}`,
        data: converter.getDataForUpdate(resourceData, data, type, key),
      };
    case "track-release":
      return {
        method: "patch",
        url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/track_releases/${id}`,
        data: converter.getDataForUpdate(resourceData, data, type, key),
      };
    default:
      return {};
  }
};

const getData = (id: string, newReleaseMessageId: string, type: string) => {
  switch (type) {
    case "sound-recording":
      return {
        method: "get",
        url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/sound_recordings/${id}`,
      };
    case "sheet-music":
      return {
        method: "get",
        url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/sheet_music/${id}`,
      };
    case "image":
    case "video":
    case "text":
      return {
        method: "get",
        url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/${type}s/${id}`,
      };
    case "software":
      return {
        method: "get",
        url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/${type}/${id}`,
      };
    case "release":
      return {
        method: "get",
        url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/${type}s/${id}`,
      };
    case "track-release":
      return {
        method: "get",
        url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/track_releases/${id}`,
      };
    default:
      return {};
  }
};

export default {
  patchData,
  getData,
};
