const signIn = (email: string, password: string) => ({
  method: "post",
  url: "/users/sign_in",
  data: {
    user: {
      email,
      password,
    },
  },
});

const signUp = (
  email: string,
  password: string,
  password_confirmation: string
) => ({
  method: "post",
  url: "/users",
  data: {
    user: {
      email,
      password,
      password_confirmation,
    },
  },
});

const signUpConfirmation = (token: string) => ({
  method: "get",
  url: `/users/confirmation?confirmation_token=${token}`,
});

const signOut = () => ({
  method: "delete",
  url: "/users/sign_out",
});

const forgotPassword = (email: string) => ({
  method: "post",
  url: "/users/password",
  data: {
    user: {
      email,
    },
  },
});

const resetPasswordCheck = (token: string) => ({
  method: "get",
  url: `/users/password/edit?reset_password_token=${token}`,
});

const resetPassword = (
  password: string,
  password_confirmation: string,
  reset_password_token: string
) => ({
  method: "patch",
  url: "/users/password",
  data: {
    user: {
      password,
      password_confirmation,
      reset_password_token,
    },
  },
});

const getAccount = () => ({
  method: "get",
  url: "/account",
});

const updateAccount = (data: any) => ({
  method: "patch",
  url: "/account",
  data: {
    user: data,
  },
});

export default {
  signIn,
  signUp,
  signUpConfirmation,
  signOut,
  forgotPassword,
  resetPasswordCheck,
  resetPassword,
  getAccount,
  updateAccount,
};
