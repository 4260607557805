import React, { useState } from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Chapter } from "./pages/Chapters/Chapter";
import { ChapterElement } from "./pages/Chapters/ChapterElement";
import { Chapters } from "./pages/Chapters";
import { Confirmation } from "./pages/Confirmation";
import { Cue } from "./pages/CueSheetList/Cue";
import { CueSheet } from "./pages/CueSheetList/CueSheet";
import { CueSheetList } from "./pages/CueSheetList/CueSheetList";
import { DealList } from "./pages/Deals/DealList";
import { Deals } from "./pages/Deals/Deals";
import { ErnLayout } from "./pages/Ern/ErnLayout";
import { ErnNew } from "./pages/Ern/ErnNew";
import { Home } from "./pages/Home";
import { Image } from "./pages/Resources/Image";
import { Login } from "./pages/Login";
import { MessageHeader } from "./pages/MessageHeader";
import { NotFound } from "./pages/NotFound";
import { Party } from "./pages/PartyList/Party";
import { PartyList } from "./pages/PartyList";
import { Password } from "./pages/Password";
import { RelatedRelease } from "./pages/Resources/RelatedRelease";
import { Release } from "./pages/ReleaseList/Release";
import { ReleaseDeal } from "./pages/Deals/ReleaseDeal";
import { ReleaseList } from "./pages/ReleaseList";
import { ReleaseVisibility } from "./pages/Deals/ReleaseVisibility";
import { ResourceGroup } from "./pages/Resources/ResourceGroup";
import { Resources } from "./pages/Resources";
import { Sidebar } from "./components/Sidebar";
import { SheetMusic } from "./pages/Resources/SheetMusic";
import { Software } from "./pages/Resources/Software";
import { SoundRecording } from "./pages/Resources/SoundRecording";
import { SupplementalDocs } from "./pages/SupplementalDocs";
import { Text } from "./pages/Resources/Text";
import { TrackRelease } from "./pages/ReleaseList/TrackRelease";
import { TrackReleaseVisibility } from "./pages/Deals/TrackReleaseVisibility";
import { Video } from "./pages/Resources/Video";
import "./App.scss";

const getBreadcrumbsForRR = (params: any) => {
  if (params?.type === "release") {
    return [
      {
        name: "Release List",
        link: ["/ern", `/${params?.newReleaseMessageId}`, "/release-list"].join(
          ""
        ),
      },
      {
        name: "Release",
        link: [
          "/ern",
          `/${params?.newReleaseMessageId}`,
          "/release-list/releases",
          `/${params?.id}`,
        ].join(""),
      },
      { name: "Related Release" },
    ];
  } else if (params?.type === "track-release") {
    return [
      {
        name: "Release List",
        link: ["/ern", `/${params?.newReleaseMessageId}`, "/release-list"].join(
          ""
        ),
      },
      {
        name: "Track Release",
        link: [
          "/ern",
          `/${params?.newReleaseMessageId}`,
          "/release-list/track-release",
          `/${params?.id}`,
        ].join(""),
      },
      { name: "Related Release" },
    ];
  } else {
    return [
      {
        name: "Resource List",
        link: ["/ern", `/${params?.newReleaseMessageId}`, "/resources"].join(
          ""
        ),
      },
      {
        name: params.type,
        link: [
          "/ern",
          `/${params?.newReleaseMessageId}`,
          `/resources/${params?.type}`,
          `/${params?.id}`,
        ].join(""),
      },
      { name: "Related Release" },
    ];
  }
};

const renderBaseLayout = (
  Component: any,
  props: any,
  is404?: boolean,
  setIs404?: (is404: boolean) => void,
  breadcrumbsProps?: any,
  hideSidebar?: boolean
) => {
  if (is404) {
    return (
      <div className="content">
        <NotFound />
      </div>
    );
  }

  return (
    <>
      {hideSidebar ? null : <Sidebar {...props} />}
      <div className="content">
        <Breadcrumbs
          {...(breadcrumbsProps || { parents: [] })}
          id={props.match.params.newReleaseMessageId}
        />
        <Component {...props} setIs404={setIs404} />
      </div>
    </>
  );
};

const renderAuthLayout = (props: any) => <props.Component />;

function App() {
  const [is404, setIs404] = useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <main role="main" className="main">
          <Switch>
            <Route
              exact
              path="/"
              render={(props: any) => (
                <div className="content">
                  <Home {...props} />
                </div>
              )}
            />
            <Route
              exact
              path="/login"
              render={(props: any) => (
                <div className="content">
                  <Login {...props} />
                </div>
              )}
            />
            <Route
              exact
              path="/users/password/edit"
              render={(props: any) => (
                <div className="content">
                  <Password {...props} />
                </div>
              )}
            />
            <Route
              exact
              path="/users/confirmation"
              render={(props: any) => (
                <div className="content">
                  <Confirmation {...props} />
                </div>
              )}
            />
            <Route
              exact
              path="/ern"
              render={(props: any) =>
                renderAuthLayout({
                  ...props,
                  Component: () => <ErnLayout {...props} setIs404={setIs404} />,
                })
              }
            />
            <Route
              exact
              path="/ern/new"
              render={(props: any) =>
                renderBaseLayout(
                  ErnNew,
                  props,
                  is404,
                  setIs404,
                  undefined,
                  true
                )
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId"
              render={(props: any) =>
                renderBaseLayout(
                  ErnNew,
                  props,
                  is404,
                  setIs404,
                  undefined,
                  true
                )
              }
            />
            <Route
              path="/ern/:newReleaseMessageId/general"
              render={(props: any) =>
                renderBaseLayout(ErnNew, props, is404, setIs404, {
                  parents: [{ name: "General" }],
                })
              }
            />
            <Route
              path="/ern/:newReleaseMessageId/message-header"
              render={(props: any) =>
                renderBaseLayout(MessageHeader, props, is404, setIs404, {
                  parents: [{ name: "Message Header" }],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources"
              render={(props: any) =>
                renderBaseLayout(Resources, props, is404, setIs404, {
                  parents: [{ name: "Resource List" }],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/sound-recording"
              render={(props: any) =>
                renderBaseLayout(SoundRecording, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Sound Recording" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/sound-recording/:id"
              render={(props: any) =>
                renderBaseLayout(SoundRecording, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Sound Recording" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/image"
              render={(props: any) =>
                renderBaseLayout(Image, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resources",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Image" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/image/:id"
              render={(props: any) =>
                renderBaseLayout(Image, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Image" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/sheet-music"
              render={(props: any) =>
                renderBaseLayout(SheetMusic, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Sheet Music" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/sheet-music/:id"
              render={(props: any) =>
                renderBaseLayout(SheetMusic, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Sheet Music" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/software"
              render={(props: any) =>
                renderBaseLayout(Software, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Software" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/software/:id"
              render={(props: any) =>
                renderBaseLayout(Software, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Software" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/text"
              render={(props: any) =>
                renderBaseLayout(Text, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Text" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/text/:id"
              render={(props: any) =>
                renderBaseLayout(Text, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Text" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/video"
              render={(props: any) =>
                renderBaseLayout(Video, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Video" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/resources/video/:id"
              render={(props: any) =>
                renderBaseLayout(Video, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Resource List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/resources",
                      ].join(""),
                    },
                    { name: "Video" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/:type/related-releases"
              render={(props: any) =>
                renderBaseLayout(RelatedRelease, props, is404, setIs404, {
                  parents: getBreadcrumbsForRR(props?.match?.params),
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/:type/:id/related-releases"
              render={(props: any) =>
                renderBaseLayout(RelatedRelease, props, is404, setIs404, {
                  parents: getBreadcrumbsForRR(props?.match?.params),
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/:type/:id/related-releases/:key"
              render={(props: any) =>
                renderBaseLayout(RelatedRelease, props, is404, setIs404, {
                  parents: getBreadcrumbsForRR(props?.match?.params),
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/:type/:id/resource-group"
              render={(props: any) =>
                renderBaseLayout(ResourceGroup, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Release List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                      ].join(""),
                    },
                    {
                      name: props.match.params.type,
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                        "/releases",
                      ].join(""),
                    },
                    { name: "Resource Group" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/:type/:id/resource-group/:key"
              render={(props: any) =>
                renderBaseLayout(ResourceGroup, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Release List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                      ].join(""),
                    },
                    {
                      name: props.match.params.type,
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                        "/releases",
                        `/${props.match.params.id}`,
                      ].join(""),
                    },
                    { name: "Resource Group" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/:type/:id/resource-group"
              render={(props: any) =>
                renderBaseLayout(ResourceGroup, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Release List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                      ].join(""),
                    },
                    {
                      name: props.match.params.type,
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                        "/releases",
                      ].join(""),
                    },
                    { name: "Resource Group" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/:type/:id/resource-group/:key"
              render={(props: any) =>
                renderBaseLayout(ResourceGroup, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Release List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                      ].join(""),
                    },
                    {
                      name: props.match.params.type,
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                        "/releases",
                        `/${props.match.params.id}`,
                      ].join(""),
                    },
                    { name: "Resource Group" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-list"
              render={(props: any) =>
                renderBaseLayout(ReleaseList, props, is404, setIs404, {
                  parents: [{ name: "Release List" }],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-list/releases"
              render={(props: any) =>
                renderBaseLayout(Release, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Release List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                      ].join(""),
                    },
                    { name: "Release" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-list/releases/:id"
              render={(props: any) =>
                renderBaseLayout(Release, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Release List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                      ].join(""),
                    },
                    { name: "Release" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-list/track-release"
              render={(props: any) =>
                renderBaseLayout(TrackRelease, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Release List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                      ].join(""),
                    },
                    { name: "Track Release" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-list/track-release/:id"
              render={(props: any) =>
                renderBaseLayout(TrackRelease, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Release List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-list",
                      ].join(""),
                    },
                    { name: "Track Release" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/deal-list"
              render={(props: any) =>
                renderBaseLayout(DealList, props, is404, setIs404, {
                  parents: [{ name: "Deal List" }],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-deal"
              render={(props: any) =>
                renderBaseLayout(ReleaseDeal, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Deal List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/deal-list",
                      ].join(""),
                    },
                    { name: "Release Deal" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-deal/:id"
              render={(props: any) =>
                renderBaseLayout(ReleaseDeal, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Deal List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/deal-list",
                      ].join(""),
                    },
                    { name: "Release Deal" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-visibility"
              render={(props: any) =>
                renderBaseLayout(ReleaseVisibility, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Deal List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/deal-list",
                      ].join(""),
                    },
                    { name: "Release Visibility" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/release-visibility/:id"
              render={(props: any) =>
                renderBaseLayout(ReleaseVisibility, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Deal List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/deal-list",
                      ].join(""),
                    },
                    { name: "Release Visibility" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/track-release-visibility"
              render={(props: any) =>
                renderBaseLayout(
                  TrackReleaseVisibility,
                  props,
                  is404,
                  setIs404,
                  {
                    parents: [
                      {
                        name: "Deal List",
                        link: [
                          "/ern",
                          `/${props.match.params.newReleaseMessageId}`,
                          "/deal-list",
                        ].join(""),
                      },
                      { name: "Track Release Visibility" },
                    ],
                  }
                )
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/track-release-visibility/:id"
              render={(props: any) =>
                renderBaseLayout(
                  TrackReleaseVisibility,
                  props,
                  is404,
                  setIs404,
                  {
                    parents: [
                      {
                        name: "Deal List",
                        link: [
                          "/ern",
                          `/${props.match.params.newReleaseMessageId}`,
                          "/deal-list",
                        ].join(""),
                      },
                      { name: "Track Release Visibility" },
                    ],
                  }
                )
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/deal/:id"
              render={(props: any) =>
                renderBaseLayout(Deals, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Deal List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/deal-list",
                      ].join(""),
                    },
                    {
                      name: "Release Deal",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-deal",
                        `/${props.match.params.id}`,
                      ].join(""),
                    },
                    { name: "Deal" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/deal/:id/:dealsKey"
              render={(props: any) =>
                renderBaseLayout(Deals, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Deal List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/deal-list",
                      ].join(""),
                    },
                    {
                      name: "Release Deal",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/release-deal",
                        `/${props.match.params.id}`,
                      ].join(""),
                    },
                    { name: "Deal" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/party-list"
              render={(props: any) =>
                renderBaseLayout(PartyList, props, is404, setIs404, {
                  parents: [{ name: "Party List" }],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/party-list/party"
              render={(props: any) =>
                renderBaseLayout(Party, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Party List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/party-list",
                      ].join(""),
                    },
                    { name: "Party" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/party-list/party/:id"
              render={(props: any) =>
                renderBaseLayout(Party, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Party List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/party-list",
                      ].join(""),
                    },
                    { name: "Party" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/chapter-lists"
              render={(props: any) =>
                renderBaseLayout(Chapters, props, is404, setIs404, {
                  parents: [{ name: "Chapter Lists" }],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/chapter-list"
              render={(props: any) =>
                renderBaseLayout(Chapter, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Chapter Lists",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/chapter-lists",
                      ].join(""),
                    },
                    { name: "Chapter List" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/chapter-list/:id"
              render={(props: any) =>
                renderBaseLayout(Chapter, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Chapter Lists",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/chapter-lists",
                      ].join(""),
                    },
                    { name: "Chapter List" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/chapter/:id"
              render={(props: any) =>
                renderBaseLayout(ChapterElement, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Chapter Lists",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/chapter-lists",
                      ].join(""),
                    },
                    {
                      name: "Chapter List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/chapter-list",
                        `/${props.match.params.id}`,
                      ].join(""),
                    },
                    { name: "Chapter" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/chapter/:id/:key"
              render={(props: any) =>
                renderBaseLayout(ChapterElement, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Chapter Lists",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/chapter-lists",
                      ].join(""),
                    },
                    {
                      name: "Chapter List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/chapter-list",
                        `/${props.match.params.id}`,
                      ].join(""),
                    },
                    { name: "Chapter" },
                  ],
                })
              }
            />
            <Route
              path="/ern/:newReleaseMessageId/cue-sheet-list"
              render={(props: any) =>
                renderBaseLayout(CueSheetList, props, is404, setIs404, {
                  parents: [{ name: "Cue Sheet List" }],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/cue-sheet"
              render={(props: any) =>
                renderBaseLayout(CueSheet, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Cue Sheet List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/cue-sheet-list",
                      ].join(""),
                    },
                    { name: "Cue Sheet" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/cue-sheet/:id"
              render={(props: any) =>
                renderBaseLayout(CueSheet, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Cue Sheet List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/cue-sheet-list",
                      ].join(""),
                    },
                    { name: "Cue Sheet" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/cue-sheet/:id/cue"
              render={(props: any) =>
                renderBaseLayout(Cue, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Cue Sheet List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/cue-sheet-list",
                      ].join(""),
                    },
                    {
                      name: "Cue Sheet",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/cue-sheet",
                        `/${props.match.params.id}`,
                      ].join(""),
                    },
                    { name: "Cue" },
                  ],
                })
              }
            />
            <Route
              exact
              path="/ern/:newReleaseMessageId/cue-sheet/:id/cue/:key"
              render={(props: any) =>
                renderBaseLayout(Cue, props, is404, setIs404, {
                  parents: [
                    {
                      name: "Cue Sheet List",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/cue-sheet-list",
                      ].join(""),
                    },
                    {
                      name: "Cue Sheet",
                      link: [
                        "/ern",
                        `/${props.match.params.newReleaseMessageId}`,
                        "/cue-sheet",
                        `/${props.match.params.id}`,
                      ].join(""),
                    },
                    { name: "Cue" },
                  ],
                })
              }
            />
            <Route
              path="/ern/:newReleaseMessageId/supplemental-docs"
              render={(props: any) =>
                renderBaseLayout(SupplementalDocs, props, is404, setIs404, {
                  parents: [{ name: "Supplemental Docs" }],
                })
              }
            />
            <Route exact>
              <div className="content">
                <NotFound />
              </div>
            </Route>
          </Switch>
        </main>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
