import React, { useEffect, useState, useRef } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import { GeneralTab } from "../../components/tabs-cue-sheet/GeneralTab";
import { CueSheetIdsTab } from "../../components/tabs-cue-sheet/CueSheetIdsTab";
import { CuesTab } from "../../components/tabs-cue-sheet/CuesTab";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/cue-sheet";
import pageConverter from "../../api-converters/cue-sheet";

import "../page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "cue_sheet_ids", title: "Cue Sheet IDs" },
  { id: "cues", title: "Cues" },
];

export const CueSheet = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        id?: string;
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
    setSuccessAlertData: (method: string) => void;
  }) => {
    const id = props.match?.params?.id;
    const history = useHistory();
    const timeout: any = useRef(null);
    const validStatuses: any = useRef({
      general: undefined,
    });

    const maxTabWidth = 144;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState<any>({});

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const updateData = (data: any) => {
      setUpdatedData(data);
      setValidated(false);
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getDataForSet(data);

      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchData(
            id,
            props.match.params.newReleaseMessageId,
            data
          ),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      } else {
        props.api({
          ...pageApi.postData(props.match.params.newReleaseMessageId, data),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);

            history.push(
              `/ern/${props.match.params.newReleaseMessageId}/cue-sheet/${data.id}`,
              { showSuccessAlert: true }
            );
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      }
    };

    const submit = () => {
      setValidated(true);
      setIsValid({});

      timeout.current = setTimeout(() => {
        const allKeys = Object.keys(validStatuses.current);
        const validKeys = allKeys.filter(
          (el: any) => validStatuses.current[el]
        ).length;

        if (validKeys === allKeys.length) {
          setValidated(false);
          setDataSaving(true);
          saveData(updatedData);
        } else {
          props.setAlertData(
            ["Please correct the errors in the highlighted tabs and try again"],
            0
          );
          setDataSaving(false);
        }

        setIsValid(validStatuses.current);
      }, 1000);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(id, props.match?.params?.newReleaseMessageId),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }

      if (props.history?.location?.state?.showSuccessAlert) {
        props.setSuccessAlertData("post");
      }

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, []);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <div className="page">
        <h2 className="page__title">{id ? "Edit" : "Add"} Cue Sheet</h2>
        <Tab.Container defaultActiveKey="general">
          <Col
            sm={12}
            className="page__tab-links-container"
            ref={tabsRef}
            style={{
              width: `${tabsWidth}px`,
              overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
            }}
          >
            <Nav
              fill
              variant="tabs"
              style={{
                width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
              }}
            >
              {tabs.map((el: any) => (
                <Nav.Item key={el.id}>
                  <Nav.Link
                    eventKey={el.id}
                    className={
                      isValid[el.id] === false && validated ? "error-tab" : ""
                    }
                  >
                    {el.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <GeneralTab
                onChange={(generalData: any) => {
                  updateData({
                    ...updatedData,
                    generalData: {
                      ...(updatedData.generalData || {}),
                      ...generalData,
                    },
                  });
                }}
                value={baseData.generalData}
                setValidStatus={(general: boolean) => {
                  validStatuses.current.general = general;
                }}
                validated={validated}
              />
              <CueSheetIdsTab
                onChange={(cueSheetIds: any) => {
                  updateData({
                    ...updatedData,
                    cueSheetIds,
                  });
                }}
                value={baseData.cueSheetIds}
              />
              <CuesTab
                id={id}
                newReleaseMessageId={props.match?.params?.newReleaseMessageId}
                onChange={(cues: any) =>
                  updateData({
                    ...updatedData,
                    cues,
                  })
                }
                value={baseData.cues}
              />
            </Tab.Content>
          </Col>
        </Tab.Container>
        <div className="page__btn-container">
          <SpinnerBtn
            disabled={getIsEqual() || dataSaving}
            isLoading={dataSaving}
            onClick={submit}
            text={id ? "Update" : "Save"}
          />
        </div>
      </div>
    );
  }
);
