import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { MultiInput } from "../MultiInput";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const ReleaseDealGeneral = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [data, setData] = useState<any>({});

  const onUpdate = (data: any) => {
    setData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated && formRef.current) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, data]);

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <MultiInput
          data={data}
          dataKey="dealReleaseReferences"
          id="dealReleaseReferences"
          label="Deal Release References"
          prependText="R"
          update={onUpdate}
        />
      </Form>
    </Tab.Pane>
  );
};
