import React, { useEffect, useState, useRef } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import { DealsGeneral } from "../../components/tabs-deal/DealsGeneral";
import { DistributionChannelPagesTab } from "../../components/tabs-deal/DistributionChannelPagesTab";
import { DistributionChannelsTab } from "../../components/tabs-deal/DistributionChannelsTab";
import { ExcludedDistributionChannelsTab } from "../../components/tabs-deal/ExcludedDistributionChannelsTab";
import { CarrierTypesTab } from "../../components/tabs-deal/CarrierTypesTab";
import { CommercialModelTypesTab } from "../../components/tabs-deal/CommercialModelTypesTab";
import { ExcludedTerritoryCodesTab } from "../../components/tabs-deal/ExcludedTerritoryCodesTab";
import { PhysicalReturnsTab } from "../../components/tabs-deal/PhysicalReturnsTab";
import { PriceInformationsTab } from "../../components/tabs-deal/PriceInformationsTab";
import { PromotionalCodeTab } from "../../components/tabs-deal/PromotionalCodeTab";
import { RightsClaimPoliciesTab } from "../../components/tabs-deal/RightsClaimPoliciesTab";
import { TechnicalInstantiationTab } from "../../components/tabs-deal/TechnicalInstantiationTab";
import { TerritoryCodesTab } from "../../components/tabs-deal/TerritoryCodesTab";
import { UserInterfaceTypesTab } from "../../components/tabs-deal/UserInterfaceTypesTab";
import { UseTypesTab } from "../../components/tabs-deal/UseTypesTab";
import { ValidityPeriodsTab } from "../../components/tabs-deal/ValidityPeriodsTab";
import { Resources as Context, Value_Deals } from "../../contexts/resource";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/release-deal";
import pageConverter from "../../api-converters/release-deal";

import "../page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "carrier_types", title: "Carrier Types" },
  { id: "commercial_model_types", title: "Commercial Model Type" },
  { id: "distribution_channel_pages", title: "Distribution Channel Pages" },
  { id: "distribution_channels", title: "Distribution Channels" },
  {
    id: "excluded_distribution_channels",
    title: "Excluded Distribution Channels",
  },
  { id: "excluded_territory_codes", title: "Excluded Territory Code" },
  { id: "physical_returns", title: "Physical Return" },
  { id: "price_informations", title: "Price Information" },
  { id: "promotional_code", title: "Promotional Code" },
  { id: "rights_claim_policies", title: "Rights Claim Policies" },
  { id: "technical_instantiation", title: "Technical Instantiation" },
  { id: "territory_codes", title: "Territory Code" },
  { id: "user_interface_types", title: "User Interface Type" },
  { id: "use_types", title: "Use Type" },
  { id: "validity_periods", title: "Validity Periods" },
];

export const Deals = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        dealsKey?: string;
        id?: string;
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
  }) => {
    const id = props.match?.params?.id;
    const dealsKey = props.match?.params?.dealsKey;
    const timeout: any = useRef(null);
    const history = useHistory();

    const maxTabWidth = 180;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [resourceData, setResourceData] = useState<any>({});
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);

    const updateData = (data: any) => {
      setUpdatedData(data);
    };

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      setResourceData(data);

      const parsedData = pageConverter.getDealsDataForSet(data, dealsKey);

      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchDealsData(
            resourceData,
            props.match.params.newReleaseMessageId,
            data,
            id,
            dealsKey
          ),
          successCb: () => {
            if (!dealsKey) {
              const data = resourceData.release_deal.deals || [];
              history.replace(
                `/ern/${props.match.params.newReleaseMessageId}/deal/${id}/${
                  data.length - 1
                }`
              );
            }
            setDataSaving(false);
          },
        });
      }
    };

    const submit = () => {
      setDataSaving(true);
      saveData(updatedData);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(props.match?.params?.newReleaseMessageId, id),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, [props.match.params]);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <div className="page">
        <h2 className="page__title">
          {dealsKey !== undefined ? "Edit" : "Add"}
          {` Deal`}
        </h2>
        <Context.Provider value={Value_Deals}>
          <Tab.Container defaultActiveKey="general">
            <Col
              sm={12}
              className="page__tab-links-container"
              ref={tabsRef}
              style={{
                width: `${tabsWidth}px`,
                overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
              }}
            >
              <Nav
                fill
                variant="tabs"
                style={{
                  width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
                }}
              >
                {tabs.map((el: any) => (
                  <Nav.Item key={el.id}>
                    <Nav.Link eventKey={el.id}>{el.title}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <DealsGeneral
                  onChange={(data: any) =>
                    updateData({
                      ...updatedData,
                      ...data,
                    })
                  }
                  value={baseData}
                />
              </Tab.Content>
              <Tab.Content>
                <CarrierTypesTab
                  onChange={(carrierTypes: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        carrierTypes,
                      },
                    })
                  }
                  value={baseData.dealTerms?.carrierTypes}
                />
              </Tab.Content>
              <Tab.Content>
                <CommercialModelTypesTab
                  onChange={(commercialModelTypes: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        commercialModelTypes,
                      },
                    })
                  }
                  value={baseData.dealTerms?.commercialModelTypes}
                />
              </Tab.Content>
              <Tab.Content>
                <DistributionChannelPagesTab
                  onChange={(distributionChannelPages: any) =>
                    updateData({
                      ...updatedData,
                      distributionChannelPages,
                    })
                  }
                  value={baseData.distributionChannelPages}
                />
              </Tab.Content>
              <Tab.Content>
                <DistributionChannelsTab
                  onChange={(distributionChannels: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        distributionChannels,
                      },
                    })
                  }
                  value={baseData.dealTerms?.distributionChannels}
                />
              </Tab.Content>
              <Tab.Content>
                <ExcludedDistributionChannelsTab
                  onChange={(excludedDistributionChannels: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        excludedDistributionChannels,
                      },
                    })
                  }
                  value={baseData.dealTerms?.excludedDistributionChannels}
                />
              </Tab.Content>
              <Tab.Content>
                <ExcludedTerritoryCodesTab
                  onChange={(excludedTerritoryCodes: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        excludedTerritoryCodes,
                      },
                    })
                  }
                  value={baseData.dealTerms?.excludedTerritoryCodes}
                />
              </Tab.Content>
              <Tab.Content>
                <PhysicalReturnsTab
                  onChange={(physicalReturns: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        physicalReturns,
                      },
                    })
                  }
                  value={baseData.dealTerms?.physicalReturns}
                />
              </Tab.Content>
              <Tab.Content>
                <PriceInformationsTab
                  onChange={(priceInformations: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        priceInformations,
                      },
                    })
                  }
                  value={baseData.dealTerms?.priceInformations}
                />
              </Tab.Content>
              <Tab.Content>
                <PromotionalCodeTab
                  onChange={(promotionalCode: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        promotionalCode,
                      },
                    })
                  }
                  value={baseData.dealTerms?.promotionalCode}
                />
              </Tab.Content>
              <Tab.Content>
                <RightsClaimPoliciesTab
                  onChange={(rightsClaimPolicies: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        rightsClaimPolicies,
                      },
                    })
                  }
                  value={baseData.dealTerms?.rightsClaimPolicies}
                />
              </Tab.Content>
              <Tab.Content>
                <TechnicalInstantiationTab
                  onChange={(technicalInstantiation: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        technicalInstantiation,
                      },
                    })
                  }
                  value={baseData.dealTerms?.technicalInstantiation}
                />
              </Tab.Content>
              <Tab.Content>
                <TerritoryCodesTab
                  onChange={(territoryCodes: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        territoryCodes,
                      },
                    })
                  }
                  value={baseData.dealTerms?.territoryCodes}
                />
              </Tab.Content>
              <Tab.Content>
                <UserInterfaceTypesTab
                  onChange={(userInterfaceTypes: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        userInterfaceTypes,
                      },
                    })
                  }
                  value={baseData.dealTerms?.userInterfaceTypes}
                />
              </Tab.Content>
              <Tab.Content>
                <UseTypesTab
                  onChange={(useTypes: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        useTypes,
                      },
                    })
                  }
                  value={baseData.dealTerms?.useTypes}
                />
              </Tab.Content>
              <Tab.Content>
                <ValidityPeriodsTab
                  onChange={(validityPeriods: any) =>
                    updateData({
                      ...updatedData,
                      dealTerms: {
                        ...(updatedData.dealTerms || {}),
                        validityPeriods,
                      },
                    })
                  }
                  value={baseData.dealTerms?.validityPeriods}
                />
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Context.Provider>
        <div className="page__btn-container">
          <SpinnerBtn
            disabled={getIsEqual() || dataSaving}
            isLoading={dataSaving}
            onClick={submit}
            text={
              id && props.match?.params?.dealsKey !== undefined
                ? "Update"
                : "Save"
            }
          />
        </div>
      </div>
    );
  }
);
