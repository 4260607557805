import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Tab } from "react-bootstrap";
import { Input } from "../Input";
import { InputDuration } from "../InputDuration";
import { SelectData } from "../SelectData";
import { getOption } from "../../utils/parse-options";
import data from "../../data/resource-group-types.json";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (isValid: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <SelectData
          id="resource_group_type"
          data={data}
          label="Resource Group Type"
          value={{
            label: getOption(generalData.resourceGroupType || ""),
            value: generalData.resourceGroupType || "",
          }}
          onChoose={(change: any) =>
            onUpdate({ ...generalData, resourceGroupType: change?.value || "" })
          }
        />
        <Input
          id="resource_group_release_reference"
          label="Resource Group Release Reference"
          prependText="R"
          pattern="[\d\-_a-zA-Z]+"
          value={generalData.resourceGroupReleaseReference || ""}
          onChange={(resourceGroupReleaseReference) =>
            onUpdate({ ...generalData, resourceGroupReleaseReference })
          }
        />
        <InputDuration
          id="duration"
          onChange={(duration) => onUpdate({ ...generalData, duration })}
          value={generalData.duration || ""}
        />
        <Form.Group as={Row}>
          <Col sm={12}>
            <Form.Check
              inline
              checked={generalData.displaySequence || false}
              id="display_sequence"
              type="checkbox"
              label="Display Sequence"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  displaySequence: !generalData.displaySequence,
                  noDisplaySequence: generalData.displaySequence,
                })
              }
            />
          </Col>
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
