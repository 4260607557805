import React from "react";
import { Input } from "./Input";
import { TerritorySelect } from "./TerritorySelect";
import { Form } from "react-bootstrap";

interface Props {
  checkLabel?: string;
  id: string;
  isTerritory?: boolean;
  eventDate: any;
  keyName: string;
  label: string;
  updateEventDate: (event: any) => void;
  withApproximate?: boolean;
}

export const FulfillmentDateWithTerritory = ({
  checkLabel,
  id,
  eventDate,
  keyName,
  label,
  updateEventDate,
  withApproximate,
}: Props) => (
  <div className="form-group event-date">
    <Input
      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}|[0-9]{4}-[0-9]{2}|[0-9]{4}"
      invalidFeedback="Date must be valid in the form YYYY, YYYY-MM or YYYY-MM-DD"
      id={`${id}-eventDateDate`}
      label={label}
      value={eventDate.value}
      onChange={(update: string) => updateEventDate({ value: update })}
    />
    <Form.Group>
      <Form.Check
        checked={eventDate[keyName] || false}
        id={`${id}-eventDateDefault`}
        inline
        type="checkbox"
        label={checkLabel || "Is Default?"}
        onChange={() => updateEventDate({ [keyName]: !eventDate[keyName] })}
      />
      {withApproximate ? (
        <Form.Check
          checked={eventDate.approximate || false}
          id={`${id}-eventDateApproximate`}
          inline
          type="checkbox"
          label={checkLabel || "Approximate?"}
          onChange={() =>
            updateEventDate({ approximate: !eventDate.approximate })
          }
        />
      ) : null}
    </Form.Group>
    <TerritorySelect
      id="event-date-territory-select"
      value={{
        label: eventDate.applicableTerritoryCode || "",
        value: eventDate.applicableTerritoryCode || "",
      }}
      label="Applicable Territory Code"
      onChange={(val) =>
        updateEventDate({
          applicableTerritoryCode: val.value,
        })
      }
    />
  </div>
);
