import React from "react";
import { AddDeleteWrapper } from "./AddDeleteWrapper";
import { Input } from "./Input";

interface Props {
  value: any;
  onChange: (updates: any) => void;
}

export const SheetMusicCodecType = (props: Props) => {
  const getIsShow = () =>
    !!Object.keys(props.value?.sheetMusicCodecType || {}).map(
      (el: string) => props.value?.sheetMusicCodecType?.[el]
    ).length;

  const onDelete = () => {
    props.onChange({ sheetMusicCodecType: {} });
  };

  return (
    <AddDeleteWrapper isShow={getIsShow()} needCheck={true} onDelete={onDelete}>
      <Input
        id="sheet-music-type"
        label="Sheet Music Codec Type"
        onChange={(value) =>
          props.onChange({
            sheetMusicCodecType: {
              ...(props.value?.sheetMusicCodecType || {}),
              value,
            },
          })
        }
        value={props.value.sheetMusicCodecType?.value}
      />
      <Input
        id="sheet-music-codec-user-defined"
        label="User Defined Value"
        onChange={(userDevinedValue) =>
          props.onChange({
            sheetMusicCodecType: {
              ...(props.value?.sheetMusicCodecType || {}),
              userDevinedValue,
            },
          })
        }
        value={props.value.sheetMusicCodecType?.userDevinedValue}
      />
      <Input
        id="sheet-music-codec-namespace"
        label="Namespace"
        onChange={(namespace) =>
          props.onChange({
            sheetMusicCodecType: {
              ...(props.value?.sheetMusicCodecType || {}),
              namespace,
            },
          })
        }
        value={props.value.sheetMusicCodecType?.namespace}
      />
      <Input
        id="sheet-music-codec-version"
        label="Version"
        onChange={(version) =>
          props.onChange({
            sheetMusicCodecType: {
              ...(props.value?.sheetMusicCodecType || {}),
              version,
            },
          })
        }
        value={props.value.sheetMusicCodecType?.version}
      />
    </AddDeleteWrapper>
  );
};
