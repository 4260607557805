import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./Sidebar.scss";

export const Sidebar = (props: {
  match: {
    params: {
      newReleaseMessageId: string;
    };
  };
}) => {
  const id = props.match.params.newReleaseMessageId;

  return (
    <div className="sidebar bg-light">
      <ul className="sidebar__pages">
        <NavItem text="General" navTo={`/ern/${id}/general`} />
        <NavItem text="Message Header" navTo={`/ern/${id}/message-header`} />
        <NavItem text="Resource List" navTo={`/ern/${id}/resources`} />
        <NavItem text="Release List" navTo={`/ern/${id}/release-list`} />
        <NavItem text="Deal List" navTo={`/ern/${id}/deal-list`} />
        <NavItem text="Party List" navTo={`/ern/${id}/party-list`} />
        <NavItem text="Chapter List" navTo={`/ern/${id}/chapter-lists`} />
        <NavItem text="Cue Sheet List" navTo={`/ern/${id}/cue-sheet-list`} />
        <NavItem
          text="Supplemental Docs"
          navTo={`/ern/${id}/supplemental-docs`}
        />
      </ul>
    </div>
  );
};

interface Props {
  navTo: string;
  text: string;
}

const NavItem = (props: Props) => {
  const location = useLocation();

  const isActive =
    (location.pathname.match("message-header") &&
      props.navTo.match("message-header")) ||
    ((location.pathname.match("/release/") ||
      location.pathname.match("/track-release/")) &&
      props.navTo.match("release-list")) ||
    (props.navTo.match("/resources") &&
      location.pathname.match("/related-releases") &&
      !location.pathname.match("/release/") &&
      !location.pathname.match("/track-release/")) ||
    location.pathname.match(props.navTo);

  return (
    <Link to={props.navTo}>
      <li
        data-page="messageHeader"
        className={`sidebar__pages__item ${
          isActive ? "sidebar__pages__item--active" : ""
        }`}
      >
        {props.text}
      </li>
    </Link>
  );
};

export const SidebarLight = (props: { title: string }) => (
  <div className="sidebar bg-light">
    <ul className="sidebar__pages">
      <li
        data-page="messageHeader"
        className="sidebar__pages__item sidebar__pages__item--active"
      >
        {props.title}
      </li>
    </ul>
  </div>
);
