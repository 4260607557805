import React, { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import withAuth from "../hocs/withAuth";

export const SignInBtn = withAuth((props: any) => {
  const [text, setText] = useState("Sign in");

  const onLogin = () => {
    if (!props.isAuthorized) {
      props.toggleAuth();
    } else {
      props.onSignOut();
    }
  };

  useEffect(() => {
    if (props.isAuthorized) {
      setText("Sign out");
    } else {
      setText("Sign in");
    }
  }, [props.isAuthorized]);

  return (
    <Row>
      {props.isAuthorized ? (
        <>
          <Button onClick={props.toggleAccount} variant="link">
            Account
          </Button>
          <span style={{ display: "block", width: "15px" }} />
        </>
      ) : null}
      <Button onClick={onLogin} variant="outline-primary">
        {text}
      </Button>
    </Row>
  );
});
