import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../hooks/useArray";
import { TableComponent } from "./TableComponent";
import { getOption } from "../utils/parse-options";

const ParentalWarning = React.lazy(() =>
  import("./ParentalWarning").then((module) => ({
    default: module.ParentalWarning,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Warning",
  "Territory code",
  "Default",
  "User Defined Value",
  "Namespace",
];

export const ParentalWarningTypeWithTerritoryTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="parentalWarnings" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{getOption(el.value)}</td>
            <td>{el.applicableTerritoryCode || ""}</td>
            <td>{`${el.default || false}`}</td>
            <td>{el.userDefinedValue}</td>
            <td>{el.namespace}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <ParentalWarning onChange={onChange} value={value} />
          </React.Suspense>
        )}
        modalTitle="Parental Warning"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
