import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { LanguageScriptField } from "../../components/LanguageField";
import { SelectData } from "../../components/SelectData";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/message-header";

import "../page.scss";
import { getOption } from "../../utils/parse-options";

const dataVersionId = [
  "Audio",
  "DjMix",
  "LongFormMusicalWorkVideo",
  "MixedMedia",
  "Ringtone",
  "SimpleAudioSingle",
  "SimpleVideoSingle",
  "Video",
];

const dataVersionVariantId = ["BoxedSet", "BoxedSet Classical", "Classical"];

export const ErnNew = withApi(
  (props: {
    api: any;
    match: {
      params: {
        newReleaseMessageId?: string;
      };
    };
  }) => {
    const id = props.match?.params?.newReleaseMessageId;
    const history = useHistory();

    const [dataSaving, setDataSaving] = useState(false);
    const [languageAndScriptCode, setLanguageAndScriptCode] = useState("");
    const [releaseProfileVersionId, setReleaseProfileVersionId] = useState("");
    const [releaseProfileVersionVariantId, setReleaseProfileVersionVariantId] =
      useState("");
    const [ddexVersion, setDdexVersion] = useState("");

    const getData = (baseId: string) => {
      props.api({
        ...pageApi.getId(baseId),
        skipAlert: true,
        successCb: ({ data }: any) => {
          setLanguageAndScriptCode(
            data.new_release_message.language_and_script_code
          );
          setReleaseProfileVersionId(
            data.new_release_message.release_profile_version_id
          );
          setReleaseProfileVersionVariantId(
            data.new_release_message.release_profile_version_variant_id
          );
          setDdexVersion(data.new_release_message.version);
        },
      });
    };

    const submit = async () => {
      setDataSaving(true);

      if (!id) {
        props.api({
          ...pageApi.createId(),
          data: {
            new_release_message: {
              language_and_script_code: languageAndScriptCode,
              release_profile_version_id: releaseProfileVersionId,
              release_profile_version_variant_id:
                releaseProfileVersionVariantId,
              version: ddexVersion,
            },
          },
          successCb: ({ data }: any) => {
            setDataSaving(false);
            history.push(`/ern/${data.id}/general`, {
              showSuccessAlert: true,
            });
          },
        });
      } else {
        props.api({
          ...pageApi.updateId(id),
          data: {
            new_release_message: {
              language_and_script_code: languageAndScriptCode,
              release_profile_version_id: releaseProfileVersionId,
              release_profile_version_variant_id:
                releaseProfileVersionVariantId,
              version: ddexVersion,
            },
          },
          successCb: ({ data }: any) => {
            setDataSaving(false);
            history.push(`/ern/${data.id}/general`, {
              showSuccessAlert: true,
            });
          },
        });
      }
    };

    useEffect(() => {
      if (id) getData(id);
    }, []);

    return (
      <div className="page container">
        <h4 className="page__title">
          {id ? "General" : "Create New Release Message"}
        </h4>
        <Form>
          <LanguageScriptField
            idDiff="language_and_script_code"
            inputWidth={8}
            labelWidth={4}
            onChange={setLanguageAndScriptCode}
            value={languageAndScriptCode}
          />
          <SelectData
            id="release_profile_version_id"
            data={dataVersionId}
            label="Release Profile Version Id"
            value={{
              label: getOption(releaseProfileVersionId || ""),
              value: releaseProfileVersionId || "",
            }}
            onChoose={(change: any) => setReleaseProfileVersionId(change.value)}
          />
          <SelectData
            id="release_profile_version_variant_id"
            data={dataVersionVariantId}
            label="Release Profile Version Variant Id"
            value={{
              label: getOption(releaseProfileVersionVariantId || ""),
              value: releaseProfileVersionVariantId || "",
            }}
            onChoose={(change: any) =>
              setReleaseProfileVersionVariantId(change.value)
            }
          />
          <SelectData
            id="release_profile_version_id"
            data={["4.2"]}
            label="DDEX Version"
            value={{
              label: ddexVersion || "",
              value: ddexVersion || "",
            }}
            onChoose={(change: any) => setDdexVersion(change.value)}
          />
        </Form>
        {!id ? (
          <div className="page__btn-container">
            <SpinnerBtn
              disabled={dataSaving}
              isLoading={dataSaving}
              onClick={submit}
              text={"Save"}
            />
          </div>
        ) : (
          <div className="page__btn-container">
            <SpinnerBtn
              disabled={dataSaving}
              isLoading={dataSaving}
              onClick={submit}
              text={"Update"}
            />
          </div>
        )}
      </div>
    );
  }
);
