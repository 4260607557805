import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { getOption } from "../../utils/parse-options";

const DisplayArtist = React.lazy(() =>
  import("../DisplayArtist").then((module) => ({
    default: module.DisplayArtist,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = ["Artistic Role", "Party Reference", "Role"];

export const DisplayArtistTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="artist" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{getOption(el.displayArtistRole?.value || "")}</td>
            <td>{el.artistPartyReference}</td>
            <td>
              {(el.artisticRoles || [])
                .map((val: any) => getOption(val.value || ""))
                .join(", ")}
            </td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <DisplayArtist onChange={onChange} value={value} />
          </React.Suspense>
        )}
        isSortable
        modalTitle="Display Artist"
        onSortTable={setDefault}
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
