import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { Input } from "../Input";
interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="releaseReference"
          label="Release Reference"
          invalidFeedback="Please provide a valid value."
          prependText="R"
          pattern="[\d\-_a-zA-Z]+"
          value={generalData.releaseReference || ""}
          onChange={(releaseReference) =>
            onUpdate({ ...generalData, releaseReference })
          }
        />
        <Input
          id="release_visibility_reference"
          label="Release Visibility Reference"
          prependText="V"
          value={generalData.releaseVisibilityReference || ""}
          onChange={(releaseVisibilityReference) =>
            onUpdate({ ...generalData, releaseVisibilityReference })
          }
        />
        <Input
          id="release_resource_reference"
          label="Release Resource Reference"
          prependText="A"
          value={generalData.releaseResourceReference || ""}
          onChange={(releaseResourceReference) =>
            onUpdate({ ...generalData, releaseResourceReference })
          }
        />
        <Input
          id="target_url"
          label="Target Url"
          value={generalData.targetUrl || ""}
          onChange={(targetUrl) => onUpdate({ ...generalData, targetUrl })}
        />
        <Form.Group>
          <Form.Check
            inline
            checked={generalData.mainRelease || false}
            id="mainRelease"
            type="checkbox"
            label="Is Main Release"
            onChange={() =>
              onUpdate({
                ...generalData,
                mainRelease: !generalData.mainRelease,
              })
            }
          />
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
