import { transformToCamelCase, transformToSnakeCase } from "./base-converters";

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    party: {
      ...converted.general_data,
      affiliations: converted.affiliations || [],
      party_ids: converted.party_ids || [],
      party_names: converted.party_names || [],
      related_parties: converted.related_parties || [],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: {
      artistProfilePages: converted.party.artistProfilePages,
      partyReference: converted.party.partyReference,
    },
    affiliations: converted.party.affiliations || [],
    partyIds: converted.party.partyIds || [],
    partyNames: converted.party.partyNames || [],
    relatedParties: converted.party.relatedParties || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
