import React from "react";
import { Input } from "./Input";

export const InputDuration = (props: {
  id?: string;
  inputWidth?: number;
  label?: string;
  labelWidth?: number;
  onChange: (val: any) => void;
  value: any;
}) => (
  <Input
    id={`${props.id || "_"}-input-duration`}
    inputWidth={props.inputWidth}
    label={props.label || "Duration"}
    labelWidth={props.labelWidth}
    onChange={props.onChange}
    pattern="^[-+]?P(?!$)(([-+]?\d+Y)|([-+]?\d+\.\d+Y$))?(([-+]?\d+M)|([-+]?\d+\.\d+M$))?(([-+]?\d+W)|([-+]?\d+\.\d+W$))?(([-+]?\d+D)|([-+]?\d+\.\d+D$))?(T(?=[\d+-])(([-+]?\d+H)|([-+]?\d+\.\d+H$))?(([-+]?\d+M)|([-+]?\d+\.\d+M$))?([-+]?\d+(\.\d+)?S)?)??$"
    value={props.value}
  />
);
