import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Select } from "./Select";
import data from "../data/unit-of-measure.json";

const options = data.map((el) => ({ label: el, value: el }));

export const SamplingRate = (props: {
  id: string;
  keyValue: string;
  label: string;
  onChange: (val: any) => void;
  step?: string;
  value: any;
}) => (
  <Form.Group as={Row}>
    <Form.Label column sm={4} htmlFor={props.id}>
      {props.label}
    </Form.Label>
    <Col sm={5}>
      <Form.Control
        id={props.id}
        onChange={(e) => props.onChange({ [props.keyValue]: e.target.value })}
        step={props.step || "0.01"}
        type="number"
        value={props.value?.[props.keyValue] || ""}
      />
    </Col>
    <Col sm={3}>
      <Select
        id={`${props.id}-unit-of-measure`}
        inputWidth={12}
        options={options}
        value={{
          label: props.value?.unitOfMeasure,
          value: props.value?.unitOfMeasure,
        }}
        onChoose={(value: any) =>
          props.onChange({ unitOfMeasure: value.value })
        }
      />
    </Col>
  </Form.Group>
);
