import React, { useRef, useState } from "react";
import update from "immutability-helper";
import { Form, Table, Col, Row, Button } from "react-bootstrap";
import { ModalAddEdit } from "./modals/ModalAddEdit";
import { ClickableText } from "./ClickableText";
import { Dot } from "./Dot";
import { TableRowComponent } from "./TableRowComponent";
import { ReactNode } from "react";

import "./TableComponent.scss";

interface Props {
  add: (data?: any) => void;
  addTitle?: string;
  backdropClassName?: string;
  data: any[];
  emptyElement: any;
  fields: string[];
  getCells: (el: any, index: number, rowIndex: number) => ReactNode;
  getModalContent?: (
    data: any,
    onChange: (data: any) => void,
    resetValidation: () => void
  ) => any;
  isSortable?: boolean;
  modalTitle?: string;
  onSortTable?: (data: any) => void;
  remove: (index: number) => void;
  ResetComponent?: () => any;
  rowSpanKey?: string;
  update: (data: any, index: number) => void;
}

export const TableComponent = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [activeElement, setActiveElement] = useState<null | number>(null);
  const [activeData, setActiveData] = useState<null | any>(null);
  const [validated, setValidated] = useState<boolean>(false);

  const toggleModal = (index = -1) => {
    if (props.getModalContent) {
      setValidated(false);

      if (activeElement !== null) {
        setActiveData(null);
        setActiveElement(null);
      } else if (index === -1) {
        setActiveData(props.emptyElement);
        setActiveElement(-1);
      } else {
        setActiveData(props.data[index]);
        setActiveElement(index);
      }
    } else if (index === -1) {
      props.add();
    } else if (index > -1) {
      props.update(null, index);
    }
  };

  const getModalTitle = () => {
    if (activeElement === null) return "";
    if (activeElement === -1) return `Add ${props.modalTitle}`;

    return `Edit ${props.modalTitle}`;
  };

  const getData = () => {
    if (activeElement === null) return null;

    return activeData;
  };

  const onSave = () => {
    if (!formRef.current) return;

    const isValid = formRef.current.checkValidity();

    if (!isValid) {
      setValidated(true);
    } else {
      if (activeElement === null || activeData === null) return;

      if (activeElement === -1) {
        props.add({
          ...activeData,
          ...(props.isSortable ? { id: props.data.length } : {}),
        });
      } else {
        props.update(activeData, activeElement);
      }

      toggleModal();
    }
  };

  const onChange = (data: any) => {
    setActiveData({
      ...activeData,
      ...data,
    });
  };

  const resetValidation = () => {
    setValidated(false);
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    if (props.onSortTable) {
      const sortedData = update(props.data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, props.data[dragIndex]],
        ],
      });

      props.onSortTable(sortedData);
    }
  };

  const renderRow = (index: number, child: any, id: number) => {
    if (props.isSortable) {
      return (
        <TableRowComponent id={id} index={index} key={id} moveRow={moveRow}>
          {child}
          <span className="dnd-icon" />
        </TableRowComponent>
      );
    }

    return <tr key={index}>{child}</tr>;
  };

  return (
    <>
      {props.getModalContent && activeElement !== null ? (
        <ModalAddEdit
          backdropClassName={props.backdropClassName}
          data={getData()}
          isAdd={activeElement === -1}
          isOpen={activeElement !== null}
          onSave={onSave}
          onChange={onChange}
          title={getModalTitle()}
          toggle={toggleModal}
        >
          <Form validated={validated} ref={formRef}>
            {props.getModalContent(activeData, onChange, resetValidation)}
          </Form>
        </ModalAddEdit>
      ) : null}
      {props.data.length ? (
        <Table responsive className={props.isSortable ? "sortable-table" : ""}>
          <thead>
            <tr>
              {[...props.fields, ""].map((el: string, index: number) => (
                <th key={index}>{el}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.data.map((el: any, index: number) => {
              if (
                !props.rowSpanKey ||
                (props.rowSpanKey && !(el[props.rowSpanKey] || []).length)
              ) {
                const child = (
                  <>
                    {props.getCells(el, index, 0)}
                    <td>
                      <Button variant="link" onClick={() => toggleModal(index)}>
                        Edit
                      </Button>
                      <Dot />
                      <Button
                        variant="link"
                        onClick={() => props.remove(index)}
                      >
                        Delete
                      </Button>
                    </td>
                  </>
                );
                return renderRow(index, child, el.id);
              }

              return (el[props.rowSpanKey] || []).map(
                (rowEl: any, rowIndex: number) => {
                  const rowsLength = el[props.rowSpanKey || ""].length;
                  const rowSpan = rowIndex ? undefined : rowsLength;

                  if (!rowSpan && rowsLength) {
                    return renderRow(
                      rowIndex,
                      props.getCells(el, index, rowIndex),
                      el.id
                    );
                  }

                  const child = (
                    <>
                      {props.getCells(el, index, rowIndex)}
                      <td rowSpan={rowSpan}>
                        <Button
                          variant="link"
                          onClick={() => toggleModal(index)}
                        >
                          Edit
                        </Button>
                        <Dot />
                        <Button
                          variant="link"
                          onClick={() => props.remove(index)}
                        >
                          Delete
                        </Button>
                      </td>
                    </>
                  );

                  return renderRow(rowIndex, child, el.id);
                }
              );
            })}
          </tbody>
        </Table>
      ) : null}
      <Row>
        <Col sm={props.ResetComponent ? 1 : 12}>
          <ClickableText
            onClick={() => toggleModal(-1)}
            text={props.addTitle || "Add"}
            size="sm"
          />
        </Col>
        {props.ResetComponent ? (
          <Col sm={3}>{props.ResetComponent()}</Col>
        ) : null}
      </Row>
    </>
  );
};
