import React from "react";

const Input = React.lazy(() =>
  import("./Input").then((module) => ({
    default: module.Input,
  }))
);

const InputIsDefault = React.lazy(() =>
  import("./InputIsDefault").then((module) => ({
    default: module.InputIsDefault,
  }))
);

interface Props {
  label?: string;
  value: any;
  onChange: (val: any) => void;
  withReplaced?: boolean;
}

export const ProprietaryIdElement = (props: Props) => (
  <>
    <React.Suspense fallback={null}>
      <Input
        id="proprietaryId"
        label={props.label || "Proprietary ID"}
        value={props.value?.value || ""}
        onChange={(value: string) => props.onChange({ value })}
      />
    </React.Suspense>
    <React.Suspense fallback={null}>
      <Input
        id="proprietaryIdNamespace"
        label="Namespace"
        value={props.value?.namespace || ""}
        onChange={(namespace: string) => props.onChange({ namespace })}
      />
    </React.Suspense>
    {props.withReplaced ? (
      <React.Suspense fallback={null}>
        <InputIsDefault
          id="is-replaced"
          label="Is Replaced?"
          value={props.value?.replaced || false}
          onChange={() => props.onChange({ replaced: !props.value?.replaced })}
        />
      </React.Suspense>
    ) : null}
  </>
);
