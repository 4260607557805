import React from "react";
import { Button } from "react-bootstrap";

interface Props {
  onClick: () => void;
  size?: "lg" | "sm";
  text: string;
  variant?: "danger" | "light";
}

export const ClickableText = (props: Props) => {
  return (
    <Button variant={props.variant} size={props.size} onClick={props.onClick}>
      {props.text}
    </Button>
  );
};
