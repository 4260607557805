import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const ResourceIdVideo = React.lazy(() =>
  import("../ResourceIdVideo").then((module) => ({
    default: module.ResourceIdVideo,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any;
}

const fields = [
  "ISRC",
  "ISAN",
  "VISAN",
  "Catalog Number",
  "Proprietary Id",
  "Is Replaced",
];

export const ResourceIdTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  const getId = (el: any) => {
    if (el?.proprietaryIds?.length) {
      const pIds: string[] = [];

      el.proprietaryIds.forEach((id: any) => {
        const value = id.value || "";
        const ns = id.namespace ? `(${id.namespace})` : "";

        pIds.push(`${value} ${ns}`);
      });

      return pIds.join(", ");
    }

    return "";
  };

  const getCatalogNumber = (el: any) => {
    const value = el.catalogNumber?.value || "";
    const ns = el.catalogNumber?.namespace
      ? `(${el.catalogNumber?.namespace})`
      : "";

    return `${value} ${ns}`;
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="resourceid" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.isrc || ""}</td>
            <td>{el.isan || ""}</td>
            <td>{el.visan || ""}</td>
            <td>{getCatalogNumber(el)}</td>
            <td>{getId(el)}</td>
            <td>{`${!!el.replaced}`}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <ResourceIdVideo onChange={onChange} value={value} />
          </React.Suspense>
        )}
        modalTitle="Resource Id"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
