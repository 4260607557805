import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const TitleField = React.lazy(() =>
  import("../TitleField").then((module) => ({
    default: module.TitleField,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any;
}

const fields = [
  "Title Text",
  "Territory Code",
  "Language And Script Code",
  "Is Default",
];

export const DisplayTitleTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="displayTitle" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.titleText}</td>
            <td>{el.applicableTerritoryCode}</td>
            <td>{el.languageAndScriptCode || ""}</td>
            <td>{`${!!el.default}`}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <TitleField
              fieldTitle="Display Title"
              title={value}
              updateTitle={onChange}
            />
          </React.Suspense>
        )}
        modalTitle="Display Title"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
