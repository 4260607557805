import React, { useEffect, useRef, useState } from "react";
import { Form, Tab } from "react-bootstrap";
import { SamplingRate } from "../SamplingRate";
import { Input } from "../Input";
import { Spacer } from "../Spacer";
import { VideoDefinitionType } from "../VideoDefinitionType";

interface Props {
  onChange: (value: any) => void;
  value: any;
}

export const TechnicalInstantiationTab = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [data, setData] = useState<any>({});

  const onUpdate = (data: any) => {
    setData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="technical_instantiation" className="page__tab-content">
      <Form ref={formRef}>
        <SamplingRate
          id="bit-rate"
          keyValue="value"
          label="Bit Rate"
          onChange={(changes) =>
            onUpdate({
              ...data,
              bitRate: {
                ...(data.bitRate || {}),
                ...changes,
              },
            })
          }
          value={data.bitRate || {}}
        />
        <Input
          id="coding_type"
          label="Coding Type"
          min={0}
          onChange={(codingType: string) =>
            onUpdate({
              ...data,
              codingType,
            })
          }
          value={data.codingType}
        />
        <Spacer />
        <h5>Video Definition Type</h5>
        <VideoDefinitionType
          onChange={(val) =>
            onUpdate({
              ...data,
              videoDefinitionType: {
                ...(data.videoDefinitionType || {}),
                ...val,
              },
            })
          }
          value={data.videoDefinitionType || {}}
        />
      </Form>
    </Tab.Pane>
  );
};
