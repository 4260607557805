import React, { useEffect, useState, useRef } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import { GeneralTab } from "../../components/tabs-resource-group/GeneralTab";
import { AdditionalTitleTab } from "../../components/tables/AdditionalTitleTab";
import { CarrierTypesTab } from "../../components/tabs-resource-group/CarrierTypesTab";
import { DisplayArtistTab } from "../../components/tables/DisplayArtistTab";
import { DisplayTitleTab } from "../../components/tables/DisplayTitleTab";
import { DisplayTitleTextTab } from "../../components/tables/DisplayTitleTextTab";
import { LinkedReleaseResourceReferenceTab } from "../../components/tabs-track-release/LinkedReleaseResourceReferenceTab";
import { ReleaseIdTab } from "../../components/tables/ReleaseIdTab";
import { ResourceGroupContentItemsTab } from "../../components/tabs-resource-group/ResourceGroupContentItemsTab";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/resource-group";
import pageConverter from "../../api-converters/resource-group";

import "../page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "additionalTitle", title: "Additional Title" },
  { id: "carrier_types", title: "Carrier Types" },
  { id: "artist", title: "Display Artist" },
  { id: "displayTitle", title: "Display Title" },
  { id: "displayTitleText", title: "Display Title Text" },
  { id: "linkedReference", title: "Linked Release Resource Reference" },
  { id: "releaseId", title: "Release ID" },
  { id: "resource_group_content_items", title: "Resource Group Content Items" },
];

export const ResourceGroup = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        id?: string;
        key?: number;
        newReleaseMessageId: string;
        type: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
  }) => {
    const id = props.match?.params?.id;
    const timeout: any = useRef(null);
    const history = useHistory();
    const validStatuses: any = useRef({
      general: undefined,
      releaseId: undefined,
    });

    const maxTabWidth = 160;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [resourceData, setResourceData] = useState<any>({});
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState<any>({});

    const updateData = (data: any) => {
      setUpdatedData(data);
      setValidated(false);
    };

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getDataForSet(
        data,
        props.match?.params?.type,
        props.match?.params?.key
      );

      setResourceData(data);
      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchData(
            id,
            props.match.params.newReleaseMessageId,
            resourceData,
            data,
            props.match?.params?.type,
            props.match?.params?.key
          ),
          successCb: () => {
            if (!props.match?.params?.key) {
              const type = props.match?.params?.type;
              const key = pageConverter.getKeyForUpdate(type);
              const releases = resourceData[key].resource_group || [];

              history.replace(
                `/ern/${props.match.params.newReleaseMessageId}/${type}/${id}/resource-group/${releases.length}`
              );
            }

            setDataSaving(false);
          },
        });
      }
    };

    const submit = () => {
      setValidated(true);
      setIsValid({});

      timeout.current = setTimeout(() => {
        const allKeys = Object.keys(validStatuses.current);
        const validKeys = allKeys.filter(
          (el: any) => validStatuses.current[el]
        ).length;

        if (validKeys === allKeys.length) {
          setValidated(false);
          setDataSaving(true);
          saveData(updatedData);
        } else {
          props.setAlertData(
            ["Please correct the errors in the highlighted tabs and try again"],
            0
          );
          setDataSaving(false);
        }

        setIsValid(validStatuses.current);
      }, 1000);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(
            id,
            props.match?.params?.newReleaseMessageId,
            props.match?.params?.type
          ),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, [props.match.params]);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="page">
          <h2 className="page__title">
            {props.match?.params?.key !== undefined ? "Edit" : "Add"}
            {` Resource Group`}
          </h2>
          <Tab.Container defaultActiveKey="general">
            <Col
              sm={12}
              className="page__tab-links-container"
              ref={tabsRef}
              style={{
                width: `${tabsWidth}px`,
                overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
              }}
            >
              <Nav
                fill
                variant="tabs"
                style={{
                  width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
                }}
              >
                {tabs.map((el: any) => (
                  <Nav.Item key={el.id}>
                    <Nav.Link
                      eventKey={el.id}
                      className={
                        isValid[el.id] === false && validated ? "error-tab" : ""
                      }
                    >
                      {el.title}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <GeneralTab
                  onChange={(generalData: any) =>
                    updateData({
                      ...updatedData,
                      generalData,
                    })
                  }
                  setValidStatus={(general: boolean) => {
                    validStatuses.current.general = general;
                  }}
                  validated={validated}
                  value={baseData.generalData}
                />
                <AdditionalTitleTab
                  onChange={(additionalTitles: any) =>
                    updateData({
                      ...updatedData,
                      additionalTitles,
                    })
                  }
                  value={baseData.additionalTitles}
                />
                <CarrierTypesTab
                  onChange={(carrierTypes: any) =>
                    updateData({
                      ...updatedData,
                      carrierTypes,
                    })
                  }
                  value={baseData.carrierTypes}
                />
                <DisplayArtistTab
                  onChange={(displayArtists: any) => {
                    updateData({
                      ...updatedData,
                      displayArtists,
                    });
                  }}
                  value={baseData.displayArtists}
                />
                <DisplayTitleTab
                  onChange={(displayTitles: any) =>
                    updateData({
                      ...updatedData,
                      displayTitles,
                    })
                  }
                  value={baseData.displayTitles}
                />
                <DisplayTitleTextTab
                  onChange={(displayTitleTexts: any) =>
                    updateData({
                      ...updatedData,
                      displayTitleTexts,
                    })
                  }
                  value={baseData.displayTitleTexts}
                />
                <LinkedReleaseResourceReferenceTab
                  onChange={(linkedReleaseResourceReferences: any) =>
                    updateData({
                      ...updatedData,
                      linkedReleaseResourceReferences,
                    })
                  }
                  value={baseData.linkedReleaseResourceReferences}
                />
                <ReleaseIdTab
                  onChange={(releaseId: any) =>
                    updateData({
                      ...updatedData,
                      releaseId,
                    })
                  }
                  setValidStatus={(releaseId: boolean) => {
                    validStatuses.current.releaseId = releaseId;
                  }}
                  type="related-release"
                  validated={validated}
                  value={baseData.releaseId}
                />
                <ResourceGroupContentItemsTab
                  onChange={(resourceGroupContentItems: any) =>
                    updateData({
                      ...updatedData,
                      resourceGroupContentItems,
                    })
                  }
                  value={baseData.resourceGroupContentItems}
                />
              </Tab.Content>
            </Col>
          </Tab.Container>
          <div className="page__btn-container">
            <SpinnerBtn
              disabled={getIsEqual() || dataSaving}
              isLoading={dataSaving}
              onClick={submit}
              text={
                id && props.match?.params?.type !== undefined
                  ? "Update"
                  : "Save"
              }
            />
          </div>
        </div>
      </DndProvider>
    );
  }
);
