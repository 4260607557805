import React from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import "./Input.scss";

const InputPrepend = React.lazy(() =>
  import("./InputPrepend").then((module) => ({
    default: module.InputPrepend,
  }))
);

interface Props {
  autoFocus?: boolean;
  id?: string;
  inputButton?: {
    text: string;
    onClick: () => void;
  };
  inputWidth?: number;
  invalidFeedback?: string;
  label?: string;
  labelWidth?: number;
  min?: number;
  onChange?: (value: string) => void;
  pattern?: string;
  prependText?: string;
  readOnly?: boolean;
  type?: string;
  step?: string;
  value?: any;
}

export const Input = (props: Props) => {
  const {
    onChange,
    prependText,
    inputButton,
    invalidFeedback,
    id,
    label,
    labelWidth,
    inputWidth,
    value,
    ...rest
  } = props;

  return (
    <Form.Group as={Row} className="input-container">
      {props.label ? (
        <Form.Label column sm={labelWidth || 4} htmlFor={id || ""}>
          {label}
        </Form.Label>
      ) : null}
      <Col sm={inputWidth || 8}>
        <InputGroup>
          {prependText ? (
            <React.Suspense fallback={null}>
              <InputPrepend
                id={id}
                onChange={onChange}
                prependText={prependText}
                value={value || ""}
                {...rest}
              />
            </React.Suspense>
          ) : (
            <Form.Control
              className="input-element"
              id={id}
              onChange={(e: any) => onChange && onChange(e.target?.value || "")}
              value={value || ""}
              {...rest}
            />
          )}
          {inputButton && (
            <InputGroup.Append>
              <Button onClick={inputButton.onClick} variant="outline-secondary">
                {inputButton.text}
              </Button>
            </InputGroup.Append>
          )}
          {invalidFeedback && (
            <Form.Control.Feedback type="invalid">
              {invalidFeedback}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      </Col>
    </Form.Group>
  );
};
