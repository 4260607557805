import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Algorithm } from "./Algorithm";
import "./Input.scss";

const DataType = React.lazy(() =>
  import("./DataType").then((module) => ({
    default: module.DataType,
  }))
);

const File = React.lazy(() =>
  import("./File").then((module) => ({
    default: module.File,
  }))
);

const Input = React.lazy(() =>
  import("./Input").then((module) => ({
    default: module.Input,
  }))
);

export const Fingerprint = (props: {
  onChange: (val: any) => void;
  resetValidation?: () => void;
  skipDataType?: boolean;
  skipFingerprintValue?: boolean;
  value: any;
}) => {
  const [type, setType] = useState<any>("");

  const changeView = (value: string) => {
    setType(value);
    if (value === "file") {
      props.onChange({ fingerprintValue: "" });
    } else {
      props.onChange({ file: {} });
    }

    if (props.resetValidation) props.resetValidation();
  };

  useEffect(() => {
    if (type) return;

    if (props.value.fingerprintValue) {
      setType("value");
    } else if (props.value?.file?.uri) {
      setType("file");
    }
  }, [type, props.value]);

  return (
    <>
      <Algorithm
        id="fingerprint-form"
        value={props.value.algorithm}
        onChange={(algorithm) => props.onChange({ algorithm })}
      />
      <React.Suspense fallback={null}>
        <Input
          id="fingerprint-form--version"
          label="Version"
          onChange={(version) => props.onChange({ version })}
          value={props.value.version || ""}
        />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <Input
          id="fingerprint-form--parameter"
          label="Parameter"
          onChange={(parameter) => props.onChange({ parameter })}
          value={props.value.parameter || ""}
        />
      </React.Suspense>
      <Form.Group as={Row}>
        <Form.Label column sm={4} className="radio-label">
          Type
        </Form.Label>
        <Col sm={8}>
          <Form.Check
            inline
            checked={type === "file"}
            id="type-file"
            type="radio"
            label="File"
            onChange={() => changeView("file")}
          />
          <Form.Check
            inline
            checked={type === "value"}
            id="type-value"
            type="radio"
            label="Value"
            onChange={() => changeView("value")}
          />
        </Col>
      </Form.Group>
      {type === "file" ? (
        <React.Suspense fallback={null}>
          <File
            id="fingerprint-form--add"
            needCheck={false}
            skipDataType={props.skipDataType}
            value={props.value.file || {}}
            onChange={(val) =>
              props.onChange({
                file: {
                  ...props.value.file,
                  ...val,
                },
              })
            }
          />
        </React.Suspense>
      ) : null}
      {type === "value" ? (
        <>
          <React.Suspense fallback={null}>
            <Input
              id="fingerprint-form--fingerprint-value"
              label="Fingerprint Value"
              onChange={(fingerprintValue) =>
                props.onChange({ fingerprintValue })
              }
              value={props.value.fingerprintValue || ""}
            />
          </React.Suspense>
          {props.skipDataType || props.skipFingerprintValue ? null : (
            <React.Suspense fallback={null}>
              <DataType
                id="fingerpint-form-data"
                value={props.value.dataType}
                onChange={(dataType) => props.onChange({ dataType })}
              />
            </React.Suspense>
          )}
        </>
      ) : null}
    </>
  );
};
