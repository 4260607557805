import React, { useEffect, useContext } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { getOption } from "../../utils/parse-options";
import { Resources } from "../../contexts/resource";

const WorkRightsController = React.lazy(() =>
  import("../WorkRightsController").then((module) => ({
    default: module.WorkRightsController,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Rights Controller Party Reference",
  "Rights Control Types",
  "Rights Controller Type",
  "Rights Share Percentage",
  "Territories",
  "Start Date",
  "End Date",
];

export const WorkRightsControllerTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  const ctxt: any = useContext(Resources);
  const isMulti = !ctxt.workRightsController?.isStringTypes;

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Tab.Pane eventKey="workRightsController" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.rightsControllerPartyReference}</td>
            <td>
              {isMulti
                ? (el.rightsControlTypes || [])
                    .map((el: any) => getOption(el))
                    .join(", ")
                : getOption(el.rightsControlTypes)}
            </td>
            <td>{getOption(el.rightsControllerType || "")}</td>
            <td>{el.rightSharePercentage}</td>
            <td>
              {(el.territories || []).map((el: any) => el.value).join(", ")}
            </td>
            <td>{el.startDate}</td>
            <td>{el.endDate}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <WorkRightsController
              isMulti={isMulti}
              onChange={onChange}
              value={value}
            />
          </React.Suspense>
        )}
        modalTitle="Work Rights Controller"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
