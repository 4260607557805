import React, { useEffect, useState } from "react";
import { getOption } from "../utils/parse-options";

const Input = React.lazy(() =>
  import("./Input").then((module) => ({
    default: module.Input,
  }))
);

const Select = React.lazy(() =>
  import("./Select").then((module) => ({
    default: module.Select,
  }))
);

const SelectData = React.lazy(() =>
  import("./SelectData").then((module) => ({
    default: module.SelectData,
  }))
);

export const UserDefinedSelect = (props: {
  dataSelect?: boolean;
  id: string;
  label: string;
  onChange: (val: any) => void;
  options: string[];
  value: any;
  valueKey: string;
  valueKeyDefined?: string;
  valueKeyNamespace?: string;
  withNamespace?: boolean;
}) => {
  const [isDefined, setIsDefined] = useState(
    props.value?.[props.valueKey]?.value === "UserDefined"
  );

  const onUpdate = (value: any) => {
    props.onChange({
      [props.valueKey]: value?.value || "",
      [props.valueKeyDefined || "userDefinedValue"]: "",
    });
  };

  useEffect(() => {
    if (
      props.value?.[props.valueKey]?.value === "UserDefined" ||
      props.value?.[props.valueKey] === "UserDefined"
    ) {
      setIsDefined(true);
    } else {
      setIsDefined(false);
    }
  }, [props.value, props.valueKey]);

  return (
    <>
      {props.dataSelect ? (
        <React.Suspense fallback={null}>
          <SelectData
            id={`${props.id}-select`}
            data={props.options}
            label={props.label}
            value={{
              label: getOption(props.value?.[props.valueKey]),
              value: props.value?.[props.valueKey],
            }}
            onChoose={onUpdate}
          />
        </React.Suspense>
      ) : (
        <React.Suspense fallback={null}>
          <Select
            id={`${props.id}-select`}
            label={props.label}
            options={props.options.map((el) => ({ label: el, value: el }))}
            value={{
              label: getOption(props.value?.[props.valueKey]),
              value: props.value?.[props.valueKey],
            }}
            onChoose={onUpdate}
          />
        </React.Suspense>
      )}
      {isDefined ? (
        <React.Suspense fallback={null}>
          <Input
            id={`${props.id}-defined`}
            label="User Defined Value"
            onChange={(userDefinedValue) =>
              props.onChange({
                [props.valueKeyDefined || "userDefinedValue"]: userDefinedValue,
              })
            }
            value={
              props.value[props.valueKeyDefined || "userDefinedValue"] || ""
            }
          />
        </React.Suspense>
      ) : null}
      {props.withNamespace ? (
        <React.Suspense fallback={null}>
          <Input
            id={`${props.id}-namespace`}
            label="Namespace"
            onChange={(namespace: string) =>
              props.onChange({
                [props.valueKeyNamespace || "namespace"]: namespace,
              })
            }
            value={props.value?.[props.valueKeyNamespace || "namespace"] || ""}
          />
        </React.Suspense>
      ) : null}
    </>
  );
};
