import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { Input } from "../Input";
import { InputIsDefault } from "../InputIsDefault";
import { ProprietaryID } from "../ProprietaryIdTable";

interface Props {
  onChange: (value: any) => void;
  value: any;
}

export const ResourceIdTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  const getId = (el: any) => {
    if (el?.proprietaryIds?.length) {
      const pIds: string[] = [];

      el.proprietaryIds.forEach((id: any) => {
        const value = id.value || "";
        const ns = id.namespace ? `(${id.namespace})` : "";

        pIds.push(`${value} ${ns}`);
      });

      return pIds.join(", ");
    }

    return "";
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="resourceid" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={["ISMN", "Proprietary Id", "Is Replaced"]}
        getCells={(el: any) => (
          <>
            <td>{el.ismn || ""}</td>
            <td>{getId(el)}</td>
            <td>{`${!!el.replaced}`}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <>
            <Input
              id="isbn-input"
              inputWidth={8}
              pattern="^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$"
              autoFocus
              label="ISBN"
              labelWidth={4}
              onChange={(isbn: string) => onChange({ ...value, isbn })}
              value={value?.isbn || ""}
            />
            <Input
              id="issn-input"
              inputWidth={8}
              pattern="^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$"
              autoFocus
              label="ISSN"
              labelWidth={4}
              onChange={(issn: string) => onChange({ ...value, issn })}
              value={value?.issn || ""}
            />
            <Input
              id="sici-input"
              inputWidth={8}
              pattern="^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$"
              autoFocus
              label="SICI"
              labelWidth={4}
              onChange={(sici: string) => onChange({ ...value, sici })}
              value={value?.sici || ""}
            />
            <ProprietaryID
              value={value?.proprietaryIds || []}
              onChange={(data: any) =>
                onChange({
                  ...value,
                  ...data,
                })
              }
            />
            <InputIsDefault
              id="is-default"
              label="Is Replaced?"
              value={value?.replaced || false}
              onChange={() =>
                onChange({
                  ...value,
                  replaced: !value?.replaced,
                })
              }
            />
          </>
        )}
        modalTitle="Resource Id"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
