import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Input } from "./Input";

export const VideoDefinitionType = (props: {
  onChange: (val: any) => void;
  value: any;
}) => (
  <>
    <Form.Group as={Row}>
      <Form.Label column sm={4} className="radio-label">
        Value
      </Form.Label>
      <Col sm={8}>
        <Form.Check
          inline
          checked={props.value?.value === "High Definition"}
          id="video-codec-type-type-high"
          type="radio"
          label="High Definition"
          onChange={() => props.onChange({ value: "High Definition" })}
        />
        <Form.Check
          inline
          checked={props.value?.value === "Standard Definition"}
          id="video-codec-type-type-standard"
          type="radio"
          label="Standard Definition"
          onChange={() => props.onChange({ value: "Standard Definition" })}
        />
      </Col>
    </Form.Group>
    <Input
      id="video-codec-type-user-defined-value"
      label="User Defined Value"
      onChange={(userDefinedValue: string) =>
        props.onChange({ userDefinedValue })
      }
      value={props.value?.userDefinedValue || ""}
    />
    <Input
      id="video-codec-type-namespace"
      label="Namespace"
      onChange={(namespace: string) => props.onChange({ namespace })}
      value={props.value?.namespace || ""}
    />
  </>
);
