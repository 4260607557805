import converter from "../api-converters/release-deal";

const patchData = (newReleaseMessageId: string, data: any, id: string) => ({
  method: "patch",
  url: `/ern/new_release_messages/${newReleaseMessageId}/deal_list/release_deals/${id}`,
  data: converter.getDataForUpdate(data),
});

const patchDealsData = (
  resourceData: any[],
  newReleaseMessageId: string,
  data: any,
  id: string,
  dealsKey?: string
) => ({
  method: "patch",
  url: `/ern/new_release_messages/${newReleaseMessageId}/deal_list/release_deals/${id}`,
  data: converter.getDealsDataForUpdate(resourceData, data, dealsKey),
});

const postData = (newReleaseMessageId: string, data: any) => ({
  method: "post",
  url: `/ern/new_release_messages/${newReleaseMessageId}/deal_list/release_deals`,
  data: converter.getDataForPost(data),
});

const getData = (newReleaseMessageId: string, id: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${newReleaseMessageId}/deal_list/release_deals/${id}`,
});

export default {
  patchData,
  patchDealsData,
  postData,
  getData,
};
