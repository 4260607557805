import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const ResourceGroupContentItems = React.lazy(() =>
  import("../ResourceGroupContentItems").then((module) => ({
    default: module.ResourceGroupContentItems,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Release Resource Reference",
  "Pre Order Incentive Resource",
  "Bonus Resource",
  "Display Sequence",
  "Instant Gratification Resource",
];

export const ResourceGroupContentItemsTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane
      eventKey="resource_group_content_items"
      className="page__tab-content"
    >
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.releaseResourceReference}</td>
            <td>{`${el.preOrderIncentiveResource || false}`}</td>
            <td>{`${el.bonusResource || false}`}</td>
            <td>{`${el.displaySequence || false}`}</td>
            <td>{`${el.instantGratificationResource || false}`}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <ResourceGroupContentItems onChange={onChange} value={value} />
          </React.Suspense>
        )}
        modalTitle="Resource Group Content Item"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
