import { useState } from "react";

export function useArray<T>(): [
  T[],
  (t: T) => void,
  (index: number) => void,
  (t: T, index: number) => void,
  () => void,
  (data: any) => void
] {
  const [data, setData] = useState<T[]>([]);
  const remove = (index: number) => {
    const copy = [...data];
    copy.splice(index, 1);
    setData(copy);
  };
  const add = (t: T) => {
    const copy = [...(data || [])];
    copy.push(t);
    setData(copy);
  };

  const update = (t: T, index: number) => {
    const copy = [...data];
    copy[index] = t;
    setData(copy);
  };

  const reset = () => {
    setData([]);
  };

  const setDefault = (data: any) => setData(data);

  return [data, add, remove, update, reset, setDefault];
}
