import React from "react";
import { useISO31661Codes } from "../hooks/useISO3661Codes";
import { useTISCodes } from "../hooks/useTISCodes";
import { TISTerritory } from "../models/Territory";
import { Option, Select } from "./Select";

interface Props {
  id: string;
  inputWidth?: number;
  label: string;
  labelWidth?: number;
  onChange: (choice: { label: string; value: string }) => void;
  value?: {
    label: string;
    value: string;
  };
}

export const TerritorySelect = (props: Props) => {
  const { countries: isoCountries } = useISO31661Codes();
  const { countries: tisCountries } = useTISCodes();

  const mappedTis: TISTerritory[] = [];
  const unmappedTis: TISTerritory[] = [];

  tisCountries.forEach((country) => {
    if (isoCountries.find((isoCountry) => isoCountry.code === country.code)) {
      mappedTis.push(country);
    } else {
      unmappedTis.push(country);
    }
  });

  const options: Option[] = isoCountries.map((country) => {
    const options = mappedTis
      .filter((tisCountry) => {
        return tisCountry.code === country.code;
      })
      .map((tisCountry) => {
        return {
          value: `${tisCountry.tis}`,
          label: `${tisCountry.tis}`,
        };
      });

    if (country.name === "Spain") {
      options.push({
        label: `Ceuta, Spain`,
        value: "ES-CE",
      });
      options.push({
        label: `Canary Islands, Spain`,
        value: "ES-CN",
      });
      options.push({
        label: `Melilla, Spain`,
        value: "ES-ML",
      });
    }

    options.push({
      label: `${country.code}`,
      value: `${country.code}`,
    });

    return {
      label: country.name,
      value: country.name,
      options,
    };
  });

  options.push({
    label: "Worldwide",
    value: "Worldwide",
    options: [
      {
        label: "Worldwide",
        value: "Worldwide",
      },
    ],
  });

  unmappedTis.forEach((country) => {
    options.push({
      label: country.name,
      value: country.name,
      options: [
        {
          label: `${country.tis}`,
          value: `${country.tis}`,
        },
      ],
    });
  });

  return (
    <Select
      id={`${props.id}-territory-select`}
      inputWidth={props.inputWidth}
      label={props.label}
      labelWidth={props.labelWidth}
      options={options}
      onChoose={(option) =>
        props.onChange({
          label: option?.label || "",
          value: option?.value || "",
        })
      }
      value={props.value}
      includeGroupNameInSearch
    />
  );
};
