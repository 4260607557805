import React from "react";

export const Value_4_2 = {
  contributor: {
    isCredited: true,
    partyAsTable: true,
  },
  displayCredit: {
    nameUsedInDisplayCredit: false,
  },
  musicalWorkId: {
    withReplaced: false,
  },
  resourseRightsController: {
    periodOfRightsDelegation: {
      fullDateTime: true,
    },
  },
  resourceIds: {
    isOptionalIsmn: true,
  },
  workRightsController: {
    isStringTypes: true,
  },
};

export const Value_Release = {
  releaseLabelReference: {
    accessControlParty: true,
  },
};

export const Value_Deals = {
  resourseRightsController: {
    periodOfRightsDelegation: {
      fullDateTime: true,
      fullWithApproximate: true,
    },
  },
};

export const Value_Chapter = {
  displayCredit: {
    nameUsedInDisplayCredit: false,
  },
  contributor: {
    isCredited: true,
    key: "performer",
    partyAsTable: true,
  },
};

export const Value_Cue = {
  contributor: {
    isCredited: true,
    partyAsTable: true,
  },
  displayCredit: {
    nameUsedInDisplayCredit: false,
  },
  musicalWorkId: {
    withReplaced: false,
  },
};

export const Resources = React.createContext({});
