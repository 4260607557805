import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import "./TableComponent.scss";

interface Props {
  children: any;
  id: number;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

export const TableRowComponent = (props: Props) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "ROW",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) return;

      const hoverRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverRect.bottom - hoverRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset?.y || hoverRect.top) - hoverRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      props.moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "ROW",
    item: () => ({ id: props.id, index: props.index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  const cursor = isDragging ? "grabbing" : "grab";

  drag(drop(ref));

  return (
    <tr ref={ref} style={{ opacity, cursor }} data-handler-id={handlerId}>
      {props.children}
    </tr>
  );
};
