import React, { useEffect, useState } from "react";
import { Container, Button as LinkBtn } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Button";
import { Dot } from "../../components/Dot";
import { Modal } from "../../components/modals/Modal";
import { Select } from "../../components/Select";
import { Spacer } from "../../components/Spacer";
import useToggle from "../../hooks/useToggle";
import withApi from "../../hocs/withApi";
import { getFormattedDate } from "../../utils/get-formatted-date";
import pageApi from "../../api/deal-list";

const options = [
  { value: "release-deal", label: "Release Deal" },
  { value: "release-visibility", label: "Release Visibility" },
  { value: "track-release-visibility", label: "Track Release Visibility" },
];

export const DealList = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    history: any;
    match: {
      params: {
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: any) => void;
    setSuccessAlertData: (method: string) => void;
  }) => {
    const id = props.match.params.newReleaseMessageId;
    const history = useHistory();
    const [show, toggle] = useToggle();
    const [type, setType] = useState<any>(null);
    const [recordings, setRecordings] = useState<any>([]);

    const getMainKey = (data: any) => {
      if (data.release_visibility) {
        return {
          key: "release_visibilities",
          title: "Release Visibility",
          url: "release-visibility",
        };
      } else if (data.track_release_visibility) {
        return {
          key: "track_release_visibilities",
          title: "Track Release Visibility",
          url: "track-release-visibility",
        };
      } else if (data.release_deal) {
        return {
          key: "release_deals",
          title: "Release Deal",
          url: "release-deal",
        };
      }

      return { key: "-", title: "-", url: "-" };
    };

    const addResourceRedirect = () => {
      if (type) {
        history.push(`/ern/${id}/${type.value}`);
        toggle();
      }
    };

    const getData = () => {
      props.api({
        ...pageApi.getData(id),
        skipAlert: true,
        successCb: ({ data }: any) => {
          if (data) {
            setRecordings(data);
          }
        },
      });
    };

    const deleteData = (deleteId: string, key: string) => {
      props.api({
        ...pageApi.deleteData(id, deleteId, key),
        successCb: getData,
      });
    };

    useEffect(() => {
      if (props.history?.location?.state?.showSuccessAlert) {
        props.setSuccessAlertData("delete");
      }

      if (id) {
        getData();
      } else {
        props.setAlertData(["Please, create message_header_id"]);
      }
    }, []);

    return (
      <>
        {show && (
          <Modal onClose={toggle} title="Add Deal">
            <div className="form-group">
              <Select
                id="index-type"
                onChoose={setType}
                label="Type"
                options={options}
              />
            </div>
            <div className="modal-footer">
              <Button variant="btn-secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button disabled={type === null} onClick={addResourceRedirect}>
                Add
              </Button>
            </div>
          </Modal>
        )}
        <Container>
          {!recordings.length ? (
            <>
              <h2>Deal List</h2>
              <Spacer />
            </>
          ) : null}
          <div className="page__btn-container row">
            {!recordings.length ? <p>No deals</p> : <h2>Deal List</h2>}
            <button className="btn btn-primary" onClick={toggle}>
              Add&nbsp;Deal
            </button>
          </div>
          {recordings.length ? (
            <>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>Resource</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {recordings.map((el: any, i: number) => {
                    const data = getMainKey(el);

                    return (
                      <tr key={`sr-${i}`}>
                        <td>{data.title}</td>
                        <td>{getFormattedDate(el.created_at)}</td>
                        <td>{getFormattedDate(el.updated_at)}</td>
                        <td>
                          <LinkBtn
                            variant="link"
                            onClick={() => {
                              history.push(`/ern/${id}/${data.url}/${el.id}/`);
                            }}
                          >
                            Edit
                          </LinkBtn>
                          <Dot />
                          <LinkBtn
                            variant="link"
                            onClick={() => {
                              deleteData(el.id, data.key);
                            }}
                          >
                            Delete
                          </LinkBtn>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : null}
        </Container>
      </>
    );
  }
);
