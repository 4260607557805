import React, { useEffect, useState, useRef } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GeneralTab } from "../../components/tabs-chapter/GeneralTab";
import { ChaptersTab } from "../../components/tabs-chapter/ChaptersTab";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/chapter";
import pageConverter from "../../api-converters/chapter";

import "../page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "chapters", title: "Chapters" },
];

export const Chapter = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        id?: string;
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
  }) => {
    const id = props.match?.params?.id;
    const timeout: any = useRef(null);
    const history = useHistory();
    const validStatuses: any = useRef({
      general: undefined,
    });

    const maxTabWidth = 150;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState<any>({});

    const updateData = (data: any) => {
      setUpdatedData(data);
      setValidated(false);
    };

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getDataForSet(data);

      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchData(
            id,
            props.match.params.newReleaseMessageId,
            data
          ),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      } else {
        props.api({
          ...pageApi.postData(props.match.params.newReleaseMessageId, data),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);

            history.push(
              `/ern/${props.match.params.newReleaseMessageId}/chapter-list/${data.id}`,
              { showSuccessAlert: true }
            );
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      }
    };

    const submit = () => {
      setValidated(true);
      setIsValid({});

      timeout.current = setTimeout(() => {
        const allKeys = Object.keys(validStatuses.current);
        const validKeys = allKeys.filter(
          (el: any) => validStatuses.current[el]
        ).length;

        if (validKeys === allKeys.length) {
          setValidated(false);
          setDataSaving(true);
          saveData(updatedData);
        } else {
          props.setAlertData(
            ["Please correct the errors in the highlighted tabs and try again"],
            0
          );
          setDataSaving(false);
        }

        setIsValid(validStatuses.current);
      }, 1000);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(id, props.match?.params?.newReleaseMessageId),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, [props.match.params]);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="page">
          <h2 className="page__title">
            {id !== undefined ? "Edit Chapter List" : "Add Chapter List"}
          </h2>
          <Tab.Container defaultActiveKey="general">
            <Col
              sm={12}
              className="page__tab-links-container"
              ref={tabsRef}
              style={{
                width: `${tabsWidth}px`,
                overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
              }}
            >
              <Nav
                fill
                variant="tabs"
                style={{
                  width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
                }}
              >
                {tabs.map((el: any) => (
                  <Nav.Item key={el.id}>
                    <Nav.Link
                      eventKey={el.id}
                      className={
                        isValid[el.id] === false && validated ? "error-tab" : ""
                      }
                    >
                      {el.title}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <GeneralTab
                  onChange={(generalData: any) =>
                    updateData({
                      ...updatedData,
                      generalData,
                    })
                  }
                  setValidStatus={(general: boolean) => {
                    validStatuses.current.general = general;
                  }}
                  validated={validated}
                  value={baseData.generalData}
                />
              </Tab.Content>
              <Tab.Content>
                <ChaptersTab
                  id={id}
                  newReleaseMessageId={props.match?.params?.newReleaseMessageId}
                  onChange={(chapters: any) =>
                    updateData({
                      ...updatedData,
                      chapters,
                    })
                  }
                  value={baseData.chapters}
                />
              </Tab.Content>
            </Col>
          </Tab.Container>
          <div className="page__btn-container">
            <SpinnerBtn
              disabled={getIsEqual() || dataSaving}
              isLoading={dataSaving}
              onClick={submit}
              text={id !== undefined ? "Update" : "Save"}
            />
          </div>
        </div>
      </DndProvider>
    );
  }
);
