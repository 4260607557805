export const getFormattedDate = (serverDate: string) => {
  if (!serverDate) return "-";

  const date = new Date(serverDate);

  return Intl.DateTimeFormat("default", {
    dateStyle: "short",
    timeStyle: "short",
  }).format(date);
};
