import React, { useEffect, useState } from "react";
import { Button as LinkBtn, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ExportBtn } from "../../components/ExportBtn";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Dot } from "../../components/Dot";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/ern";
import { getFormattedDate } from "../../utils/get-formatted-date";
import "../page.scss";

export const Ern = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    isAuthorized: boolean;
    setAlertData: (errors: any) => void;
  }) => {
    const history = useHistory();
    const [list, setList] = useState<any>([]);

    const onCreate = (e: any) => {
      e.preventDefault();
      history.push("/ern/new");
    };

    const getData = () => {
      props.api({
        ...pageApi.getData(),
        skipAlert: true,
        successCb: ({ data }: any) => {
          if (data) setList(data.reverse());
        },
      });
    };

    const getSenderName = (el: any) => {
      const messageHeader = el?.new_release_message?.message_header;

      return messageHeader?.message_sender?.party_name?.full_name;
    };

    const getSenderID = (el: any) => {
      const messageHeader = el?.new_release_message?.message_header;

      return messageHeader?.message_sender?.party_id;
    };

    const deleteData = (id: string) => {
      props.api({
        ...pageApi.deleteData(id),
        successCb: getData,
        errorCb: getData,
      });
    };

    const getButtons = (id: string) => (
      <>
        <LinkBtn
          variant="link"
          onClick={() => {
            history.push(`/ern/${id}/general`);
          }}
        >
          Edit
        </LinkBtn>
        <Dot />
        <LinkBtn variant="link" onClick={() => deleteData(id)}>
          Delete
        </LinkBtn>
        <Dot />
        <ExportBtn id={id} variant="link" />
      </>
    );

    useEffect(() => {
      getData();
    }, [props.isAuthorized]);

    return (
      <>
        <Breadcrumbs parents={[]} />
        <Container>
          <div className="page__btn-container row">
            <h4>New Release Messages</h4>
            <LinkBtn
              className="btn btn-primary"
              href="/ern/new"
              onClick={onCreate}
            >
              Create
            </LinkBtn>
          </div>
          {list.length ? (
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th>Version</th>
                  <th>Sender Name</th>
                  <th>Sender ID</th>
                  <th>Party ID</th>
                  <th>Party</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.map((el: any, i: number) => {
                  const recipients =
                    el?.new_release_message?.message_header?.message_recipients;
                  const length = recipients?.length;

                  if (length) {
                    return recipients.map((r: any, j: number) => {
                      if (!j) {
                        return (
                          <tr key={`sr-${j}`}>
                            <td rowSpan={length}>
                              {el?.new_release_message?.version || "-"}
                            </td>
                            <td rowSpan={length}>{getSenderName(el) || "-"}</td>
                            <td rowSpan={length}>{getSenderID(el) || "-"}</td>
                            <td>{r.party_id || "-"}</td>
                            <td>{r.party_name?.full_name || "-"}</td>
                            <td rowSpan={length}>
                              {getFormattedDate(el.created_at)}
                            </td>
                            <td rowSpan={length}>
                              {getFormattedDate(el.updated_at)}
                            </td>
                            <td rowSpan={length}>{getButtons(el?.id)}</td>
                          </tr>
                        );
                      }

                      return (
                        <tr key={`sr-${j}`}>
                          <td>{r.party_id || "-"}</td>
                          <td>{r.party_name?.full_name || "-"}</td>
                        </tr>
                      );
                    });
                  }

                  return (
                    <tr key={`sr-${i}`}>
                      <td>{el?.new_release_message?.version || "-"} </td>
                      <td>{getSenderName(el) || "-"}</td>
                      <td>{getSenderID(el) || "-"}</td>
                      <td>-</td>
                      <td>-</td>
                      <td>{getFormattedDate(el.created_at)}</td>
                      <td>{getFormattedDate(el.updated_at)}</td>
                      <td>{getButtons(el?.id)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No New Release messages have been created</p>
          )}
        </Container>
      </>
    );
  }
);
