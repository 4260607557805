import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, Tab } from "react-bootstrap";
import { Input } from "../Input";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="message_id"
          label="Message ID"
          value={generalData.messageId || ""}
          onChange={(messageId) => onUpdate({ ...generalData, messageId })}
        />
        <Input
          id="message_thread_id"
          label="Message Thread ID"
          value={generalData.messageThreadId || ""}
          onChange={(messageThreadId) =>
            onUpdate({ ...generalData, messageThreadId })
          }
        />
        <Input
          id="message_file_name"
          label="Message File Name"
          value={generalData.messageFileName || ""}
          onChange={(messageFileName) =>
            onUpdate({ ...generalData, messageFileName })
          }
        />
        <Form.Group as={Row}>
          <Form.Label column sm={4} className="radio-label">
            Message Control Type
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              inline
              checked={generalData.messageControlType === "LiveMessage"}
              id="control-type-LiveMessage"
              type="radio"
              label="Live Message"
              onChange={() =>
                onUpdate({ ...generalData, messageControlType: "LiveMessage" })
              }
            />
            <Form.Check
              inline
              checked={generalData.messageControlType === "TestMessage"}
              id="control-type-TestMessage"
              type="radio"
              label="Test Message"
              onChange={() =>
                onUpdate({ ...generalData, messageControlType: "TestMessage" })
              }
            />
          </Col>
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
