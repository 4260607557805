import React from "react";

import { Modal as M } from "react-bootstrap";

import "./Modal.scss";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  title: string;
}

export const Modal = (props: Props) => {
  return (
    <M show onHide={props.onClose}>
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button onClick={props.onClose} className="close">
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">{props.children}</div>
    </M>
  );
};
