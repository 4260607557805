import React from "react";

interface Props {
  variant?: "btn-secondary" | "btn-danger";
  type?: "submit" | "button";
  disabled?: boolean;
}

export const Button = (
  props: Props & React.HTMLAttributes<HTMLButtonElement>
) => {
  const { variant, disabled, className, ...rest } = props;
  return (
    <button
      type={props.type || "button"}
      disabled={disabled}
      className={`btn ${variant || "btn-primary"} ${className || ""}`}
      {...rest}
    />
  );
};
