import React from "react";
import { Button, Spinner } from "react-bootstrap";

export const SpinnerBtn = (props: {
  disabled: boolean;
  isLoading: boolean;
  onClick: () => void;
  text?: string;
}) => (
  <Button onClick={props.onClick} disabled={props.disabled}>
    {props.isLoading ? (
      <>
        <Spinner as="span" animation="border" size="sm" />
        {"  "}
      </>
    ) : null}
    {props.text || "Save"}
  </Button>
);
