import React, { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";

const ReleaseVisibility = React.lazy(() =>
  import("../ReleaseVisibility").then((module) => ({
    default: module.ReleaseVisibility,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

export const ReleaseVisibilityTab = (props: Props) => {
  const [data, setData] = useState<any>({});

  const onUpdate = (data: any) => {
    setData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="release_visibilities" className="page__tab-content">
      <React.Suspense fallback={null}>
        <ReleaseVisibility onChange={onUpdate} value={data} />
      </React.Suspense>
    </Tab.Pane>
  );
};
