import React from "react";
import { Button, Modal } from "react-bootstrap";

export const TypeModal = (props: {
  isOpen: boolean;
  setType: (type: string) => void;
  toggle: () => void;
}) => (
  <Modal show={props.isOpen} onHide={props.toggle}>
    <Modal.Header closeButton>
      <Modal.Title>Release Type</Modal.Title>
    </Modal.Header>
    <Modal.Footer>
      <Button variant="primary" onClick={() => props.setType("release")}>
        Release
      </Button>
      <Button variant="primary" onClick={() => props.setType("trackRelease")}>
        Track Release
      </Button>
    </Modal.Footer>
  </Modal>
);
