import {
  transformToCamelCase,
  transformToSnakeCase,
  convertDisplayArtistsForTable,
  convertDisplayArtistsForApi,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getKeyForUpdate = (type: string) => {
  switch (type) {
    default:
      return type;
  }
};

const getKeyForSet = (type: string) => {
  switch (type) {
    default:
      return type;
  }
};

const getDataForUpdate = (
  resourceData: any,
  data: any,
  type: string,
  releaseKey?: number
) => {
  const key = getKeyForUpdate(type);
  const converted = transformToSnakeCase(data);

  if (releaseKey) {
    resourceData[key].resource_group[releaseKey] = {
      ...converted.general_data,
      additional_titles: convertAddTitlesForApi(
        converted.additional_titles || []
      ),
      carrier_types: converted.carrier_types || [],
      display_artists: convertDisplayArtistsForApi(
        converted.display_artists || []
      ),
      display_title_texts: converted.display_title_texts || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      linked_release_resource_references:
        converted.linked_release_resource_references || [],
      release_id: converted.release_id || [],
      resource_group_content_items:
        converted.resource_group_content_items || [],
    };

    return resourceData;
  }

  return {
    [key]: {
      ...resourceData[key],
      resource_group: [
        ...(resourceData[key].resource_group || []),
        {
          ...converted.general_data,
          additional_titles: convertAddTitlesForApi(
            converted.additional_titles || []
          ),
          carrier_types: converted.carrier_types || [],
          display_artists: convertDisplayArtistsForApi(
            converted.display_artists || []
          ),
          display_title_texts: converted.display_title_texts || [],
          display_titles: convertAddTitlesForApi(
            converted.display_titles || []
          ),
          linked_release_resource_references:
            converted.linked_release_resource_references || [],
          release_id: converted.release_id || [],
          resource_group_content_items:
            converted.resource_group_content_items || [],
        },
      ],
    },
  };
};

const getDataForSet = (data: any, type: string, releaseKey?: number) => {
  const key = getKeyForSet(type);
  const converted = transformToCamelCase(data);

  if (releaseKey !== undefined) {
    const resourceGroup = converted[key].resourceGroup[releaseKey];

    return {
      generalData: {
        displaySequence: resourceGroup.displaySequence,
        duration: resourceGroup.duration,
        noDisplaySequence: resourceGroup.noDisplaySequence,
        resourceGroupReleaseReference:
          resourceGroup.resourceGroupReleaseReference,
        resourceGroupType: resourceGroup.resourceGroupType,
      },
      additionalTitles: convertAddTitlesForTable(
        resourceGroup.additionalTitles
      ),
      carrierTypes: resourceGroup.carrierTypes || [],
      displayArtists: convertDisplayArtistsForTable(
        resourceGroup.displayArtists || []
      ),
      displayTitles: convertAddTitlesForTable(
        resourceGroup.displayTitles || []
      ),
      displayTitleTexts: resourceGroup.displayTitleTexts || [],
      linkedReleaseResourceReferences:
        resourceGroup.linkedReleaseResourceReferences || [],
      releaseId: resourceGroup.releaseId,
      resourceGroupContentItems: resourceGroup.resourceGroupContentItems || [],
    };
  }

  return {};
};

export default {
  getDataForUpdate,
  getDataForSet,
  getKeyForSet,
  getKeyForUpdate,
};
