import React from "react";
import { ProprietaryIdElement } from "./ProprietaryIdElement";
import { TableComponent } from "./TableComponent";

interface Props {
  value: any;
  onChange: (val: any) => void;
  withReplaced?: boolean;
}

export const ProprietaryID = (props: Props) => {
  const add = (t: any) => {
    const copy = [...(props.value || [])];
    copy.push(t);

    props.onChange({ proprietaryIds: copy });
  };

  const update = (t: any, index: number) => {
    const copy = [...props.value];
    copy[index] = t;

    props.onChange({ proprietaryIds: copy });
  };

  const remove = (index: number) => {
    const copy = [...props.value];
    copy.splice(index, 1);

    props.onChange({ proprietaryIds: copy });
  };

  const getFields = () => {
    const fields = ["Proprietary ID", "Namespace"];

    if (props.withReplaced) fields.push("Is Replaced");

    return fields;
  };

  const getCells = (el: any) => {
    if (props.withReplaced) {
      return (
        <>
          <td>{el.value || ""}</td>
          <td>{el.namespace || ""}</td>
          <td>{`${el.replaced || false}`}</td>
        </>
      );
    }

    return (
      <>
        <td>{el.value || ""}</td>
        <td>{el.namespace || ""}</td>
      </>
    );
  };

  return (
    <>
      <h5>Proprietary IDs</h5>
      <TableComponent
        add={add}
        addTitle="Add Proprietary ID"
        backdropClassName="top-overlay"
        data={props.value || []}
        emptyElement={{}}
        fields={getFields()}
        getCells={(el: any) => getCells(el)}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <ProprietaryIdElement
            value={value}
            onChange={onChange}
            withReplaced={props.withReplaced}
          />
        )}
        modalTitle="Proprietary ID"
        remove={remove}
        update={update}
      />
      <br />
    </>
  );
};
