import converter from "../api-converters/resource-group";

const patchData = (
  id: string,
  newReleaseMessageId: string,
  resourceData: any,
  data: any,
  type: string,
  key?: number
) => {
  switch (type) {
    case "release":
      return {
        method: "patch",
        url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/${type}s/${id}`,
        data: converter.getDataForUpdate(resourceData, data, type, key),
      };
    default:
      return {};
  }
};

const getData = (id: string, newReleaseMessageId: string, type: string) => {
  switch (type) {
    case "release":
      return {
        method: "get",
        url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/${type}s/${id}`,
      };
    default:
      return {};
  }
};

export default {
  patchData,
  getData,
};
