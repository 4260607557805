import {
  transformToCamelCase,
  transformToSnakeCase,
  convertDisplayArtistsForTable,
  convertDisplayArtistsForApi,
  convertBaseIdForTable,
  convertBaseIdForApi,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getGeneralDataForSet = (data: any) => ({
  containsHiddenContent: data.containsHiddenContent,
  languageAndScriptCode: data.languageAndScriptCode,
  resourceReference: data.resourceReference,
  supplemental: data.supplemental,
  type: data.type,
});

const getDatesForSet = (data: any) => ({
  creationDate: data.creationDate || {},
  firstPublicationDates: data.firstPublicationDates || [],
});

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    image: {
      ...converted.general_data,
      ...(converted.dates || {}),
      additional_titles: convertAddTitlesForApi(converted.add_titles || []),
      c_lines: converted.c_lines,
      contributors: convertBaseIdForApi(converted.contributors || []),
      courtesy_lines: converted.courtesy_lines || [],
      descriptions: converted.descriptions || [],
      display_artists: convertDisplayArtistsForApi(
        converted.display_artists || []
      ),
      display_artist_names: converted.display_artist_names || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      parental_warning_types: converted.parental_warning_types,
      related_releases: converted.related_releases || [],
      related_resources: converted.related_resources || [],
      resource_ids: converted.resource_ids || [],
      resource_rights_controllers: converted.resource_rights_controllers || [],
      technical_details: converted.technical_details || [],
      version_types: converted.version_types || [],
      work_rights_controllers: converted.work_rights_controllers || [],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: getGeneralDataForSet(converted.image),
    addTitles: convertAddTitlesForTable(converted.image.additionalTitles || []),
    cLines: converted.image.cLines || [],
    contributors: convertBaseIdForTable(converted.image.contributors || []),
    courtesyLines: converted.image.courtesyLines || [],
    dates: getDatesForSet(converted.image),
    descriptions: converted.image.descriptions || [],
    displayArtists: convertDisplayArtistsForTable(
      converted.image.displayArtists || []
    ),
    displayArtistNames: converted.image.displayArtistNames || [],
    displayTitles: convertAddTitlesForTable(
      converted.image.displayTitles || []
    ),
    displayTitleTexts: converted.image.displayTitleTexts,
    parentalWarningTypes: converted.image.parentalWarningTypes,
    relatedReleases: converted.image.relatedReleases || [],
    relatedResources: converted.image.relatedResources || [],
    resourceIds: converted.image.resourceIds || [],
    resourceRightsControllers: converted.image.resourceRightsControllers || [],
    technicalDetails: converted.image.technicalDetails || [],
    versionTypes: converted.image.versionTypes || [],
    workRightsControllers: converted.image.workRightsControllers || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
