import React from "react";
import { Form } from "react-bootstrap";

interface Props {
  id: string;
  label?: string;
  name?: string;
  value: boolean;
  onChange: (updates: any) => void;
}

export const InputIsDefault = (props: Props) => (
  <Form.Group>
    <Form.Check
      {...(props.name ? { name: props.name } : {})}
      checked={props.value || false}
      id={`${props.id}-is-default`}
      type={props.name ? "radio" : "checkbox"}
      label={props.label || "Is Default?"}
      onChange={() =>
        props.onChange({
          default: !props.value,
        })
      }
    />
  </Form.Group>
);
