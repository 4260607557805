import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";

const ReleaseId = React.lazy(() =>
  import("../ReleaseId").then((module) => ({
    default: module.ReleaseId,
  }))
);

interface Props {
  onChange: (data: any) => void;
  setValidStatus?: (isValid: boolean) => void;
  validated?: boolean;
  value: any;
  type: "related-release" | "track-release" | "release";
}

export const ReleaseIdTab = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [data, setData] = useState<any>({});

  const onUpdate = (data: any) => {
    setData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.setValidStatus && props.validated && formRef.current) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, data]);

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="releaseId" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <React.Suspense fallback={null}>
          <ReleaseId
            onChange={(value: any) => onUpdate({ ...data, ...value })}
            value={data}
            withReplaced={props.type === "track-release" ? true : false}
          />
        </React.Suspense>
      </Form>
    </Tab.Pane>
  );
};
