import React, { useState, useEffect, useRef } from "react";
import { Tab, Form } from "react-bootstrap";
import { Input } from "../Input";
import { InputDuration } from "../InputDuration";
import { LanguageScriptField } from "../LanguageField";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="chapter_reference"
          label="Chapter Reference"
          invalidFeedback="Please provide a valid value."
          prependText="X"
          pattern="[\d\-_a-zA-Z]+$"
          value={generalData.chapterReference || ""}
          onChange={(chapterReference) =>
            onUpdate({ ...generalData, chapterReference })
          }
        />
        <Input
          id="representative_image_reference"
          label="Representative Image Reference"
          invalidFeedback="Please provide a valid value."
          prependText="A"
          pattern="[\d\-_a-zA-Z]+$"
          value={generalData.representativeImageReference || ""}
          onChange={(representativeImageReference) =>
            onUpdate({ ...generalData, representativeImageReference })
          }
        />
        <InputDuration
          id="duration"
          onChange={(duration: any) => onUpdate({ ...generalData, duration })}
          value={generalData?.duration}
        />
        <Input
          id="start_time"
          label="Start Time"
          value={generalData.startTime || ""}
          onChange={(startTime) => onUpdate({ ...generalData, startTime })}
        />
        <Input
          id="end_time"
          label="End Time"
          value={generalData.endTime || ""}
          onChange={(endTime) => onUpdate({ ...generalData, endTime })}
        />
        <LanguageScriptField
          idDiff="general-language-field"
          value={generalData.languageAndScriptCode || ""}
          onChange={(languageAndScriptCode) =>
            onUpdate({ ...generalData, languageAndScriptCode })
          }
        />
      </Form>
    </Tab.Pane>
  );
};
