import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { getHashSum, getAlgorithmLabel } from "../../utils/hash-sum-value";
import { getOption } from "../../utils/parse-options";

const TechnicalDetailsImage = React.lazy(() =>
  import("../TechnicalDetailsImage").then((module) => ({
    default: module.TechnicalDetailsImage,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Technical Resource Details Reference",
  "Image Codec Type",
  "File",
  "Hash Sum",
];

export const TechnicalDetailsTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="technicalDetails" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => (
          <React.Fragment key={index}>
            <td>{el.technicalResourceDetailsReference}</td>
            <td>{getOption(el.imageCodecType?.value || "")}</td>
            <td>{el.file?.uri || ""}</td>
            <td>
              {getHashSum(el.file?.hashSum?.hashSumValue)}
              <br />
              {getAlgorithmLabel(el.file?.hashSum?.algorithm?.label)}
            </td>
          </React.Fragment>
        )}
        getModalContent={(
          value: any,
          onChange: (data: any) => void,
          resetValidation: () => void
        ) => (
          <React.Suspense fallback={null}>
            <TechnicalDetailsImage
              onChange={onChange}
              resetValidation={resetValidation}
              value={value}
            />
          </React.Suspense>
        )}
        modalTitle="Technical Details"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
