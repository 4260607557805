import React, { useEffect, useState } from "react";
import { Container, Button as LinkBtn } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Dot } from "../../components/Dot";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/party-list";
import { Spacer } from "../../components/Spacer";
import { getFormattedDate } from "../../utils/get-formatted-date";
import "../page.scss";

export const PartyList = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    match: {
      params: {
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: any) => void;
  }) => {
    const id = props.match.params.newReleaseMessageId;
    const history = useHistory();
    const [resordings, setRecordings] = useState<any>([]);

    const getData = () => {
      props.api({
        ...pageApi.getData(id),
        skipAlert: true,
        successCb: ({ data }: any) => {
          if (data) {
            setRecordings(data);
          }
        },
      });
    };

    const deleteData = (deleteId: string) => {
      props.api({
        ...pageApi.deleteData(id, deleteId),
        successCb: getData,
      });
    };

    const onAdd = () => history.push(`/ern/${id}/party-list/party`);

    const getPartyIds = (partyIds: any) => {
      return partyIds.map((el: any) => {
        const elements = Object.keys(el)
          .map((key: any) => {
            if (key !== "proprietary_ids" && el[key]) {
              return `<b>${key.replace(/_/g, " ")}:</b> ${el[key]}`;
            }

            return null;
          })
          .filter((el: any) => el);

        return (
          <>
            <span dangerouslySetInnerHTML={{ __html: elements.join(", ") }} />
            <br />
            <br />
          </>
        );
      });
    };

    useEffect(() => {
      if (id) {
        getData();
      } else {
        props.setAlertData(["Please, create message_header_id"]);
      }
    }, []);

    return (
      <>
        <Container>
          {!resordings.length ? (
            <>
              <h2>Party List</h2>
              <Spacer />
            </>
          ) : null}
          <div className="page__btn-container row">
            {!resordings.length ? <p>No parties</p> : <h2>Party List</h2>}
            <button className="btn btn-primary" onClick={onAdd}>
              Add&nbsp;Party
            </button>
          </div>
          {resordings.length ? (
            <>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>Party Reference</th>
                    <th>Party IDs</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {resordings.map((el: any, i: number) => (
                    <tr key={`sr-${i}`}>
                      <td>{el.party?.party_reference || ""}</td>
                      <td>{getPartyIds(el.party?.party_ids || [])}</td>
                      <td>{getFormattedDate(el.created_at)}</td>
                      <td>{getFormattedDate(el.updated_at)}</td>
                      <td>
                        <LinkBtn
                          variant="link"
                          onClick={() => {
                            history.push(
                              `/ern/${id}/party-list/party/${el.id}/`
                            );
                          }}
                        >
                          Edit
                        </LinkBtn>
                        <Dot />
                        <LinkBtn
                          variant="link"
                          onClick={() => deleteData(el.id)}
                        >
                          Delete
                        </LinkBtn>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : null}
        </Container>
      </>
    );
  }
);
