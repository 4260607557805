import transform from "lodash/transform";
import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import snakeCase from "lodash/snakeCase";

export const transformToCamelCase = (data: any) => {
  return transform(data, (acc: any, value: any, key: any, target: any) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? transformToCamelCase(value) : value;
  });
};

export const transformToSnakeCase = (data: any) => {
  return transform(data, (acc: any, value: any, key: any, target: any) => {
    const camelKey = isArray(target) ? key : snakeCase(key);

    acc[camelKey] = isObject(value) ? transformToSnakeCase(value) : value;
  });
};

export const convertDisplayArtistsForTable = (data: any) =>
  data.map((el: any, j: number) => ({
    ...el,
    id: j,
    titleDisplayInformations: (el.titleDisplayInformations || []).map(
      (subEl: any, i: number) => ({
        ...subEl,
        id: i,
      })
    ),
  }));

export const convertDisplayArtistsForApi = (data: any) =>
  data.map((el: any) => {
    delete el.id;

    return {
      ...el,
      title_display_informations: (el.title_display_informations || []).map(
        (subEl: any) => {
          delete subEl.id;

          return subEl;
        }
      ),
    };
  });

export const convertBaseIdForApi = (data: any) =>
  data.map((el: any) => {
    delete el.id;

    return el;
  });

export const convertBaseIdForTable = (data: any) =>
  data.map((el: any, i: number) => ({
    ...el,
    id: i,
  }));

export const convertAddTitlesForApi = (data: any) =>
  data
    .filter((el: any) => !!Object.keys(el).length)
    .map((el: any) => ({
      ...el,
      sub_titles: convertBaseIdForApi(
        (el.sub_titles || []).filter(
          (subEl: any) => !!Object.keys(subEl).length
        )
      ),
    }));

export const convertAddTitlesForTable = (data: any) =>
  data.map((el: any) => ({
    ...el,
    subTitles: el.subTitles.map((subEl: any, i: number) => ({
      ...subEl,
      id: i,
    })),
  }));
