import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { getOption } from "../../utils/parse-options";

const AdditionalTitle = React.lazy(() =>
  import("../AdditionalTitle").then((module) => ({
    default: module.AdditionalTitle,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Title Text",
  "Territory Code",
  "Language and Script Code",
  "Title Type",
  "Subtitles",
];

export const AdditionalTitleTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="additionalTitle" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        rowSpanKey="subTitles"
        getCells={(el: any, index: number, rowIndex: number) => {
          const rowsLength = (el.subTitles || []).length;
          const rowSpan = rowIndex ? undefined : rowsLength || 1;

          if (!rowSpan && rowsLength) {
            return <td>{el.subTitles[rowIndex]?.value || "-"}</td>;
          }

          return (
            <>
              <td rowSpan={rowSpan}>{el.titleText}</td>
              <td rowSpan={rowSpan}>{el.applicableTerritoryCode || ""}</td>
              <td rowSpan={rowSpan}>{el.languageAndScriptCode}</td>
              <td rowSpan={rowSpan}>{getOption(el.titleType || "")}</td>
              <td>{el.subTitles?.[rowIndex]?.value || "-"}</td>
            </>
          );
        }}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <AdditionalTitle id="add-title" onChange={onChange} value={value} />
          </React.Suspense>
        )}
        modalTitle="Additional Title"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
