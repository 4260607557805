import {
  transformToCamelCase,
  transformToSnakeCase,
  convertDisplayArtistsForTable,
  convertDisplayArtistsForApi,
  convertBaseIdForTable,
  convertBaseIdForApi,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getGeneralDataForUpdate = (data: any) => ({
  ...(data || {}),
  ...(data?.type ? { type: data.type } : {}),
});

const getGeneralDataForSet = (data: any) => ({
  ...(data || {}),
  ...(data?.type ? { type: data.type } : { type: {} }),
});

const getDatesForUpdate = (data: any) => ({
  creation_date: data.creation_date?.value ? data.creation_date : undefined,
  mastered_date: data.mastered_date?.value ? data.mastered_date : undefined,
  remastered_date: data.remastered_date?.value
    ? data.remastered_date
    : undefined,
  first_publication_dates: (data.first_publication_dates || []).length
    ? data.first_publication_dates
    : undefined,
});

const getDatesForSet = (data: any) => ({
  creationDate: data.creationDate || {},
  masteredDate: data.masteredDate || {},
  remasteredDate: data.remasteredDate || {},
  firstPublicationDates: data.firstPublicationDates || [],
});

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    sheet_music: {
      ...getGeneralDataForUpdate(converted.general_data),
      ...getDatesForUpdate(converted.dates || {}),
      additional_titles: convertAddTitlesForApi(converted.add_titles || []),
      c_lines: converted.c_lines || [],
      contributors: convertBaseIdForApi(converted.contributors || []),
      courtesy_lines: converted.courtesy_lines || [],
      display_artists: convertDisplayArtistsForApi(
        converted.display_artists || []
      ),
      display_artist_names: converted.display_artist_names || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      parental_warning_types: converted.parental_warning_types,
      related_releases: converted.related_releases || [],
      related_resources: converted.related_resources || [],
      resource_ids: converted.resource_ids || [],
      resource_rights_controllers: converted.resource_rights_controllers || [],
      resource_contained_resource_reference_list:
        converted.resource_contained_resource_reference_list || [],
      technical_details: converted.technical_details || [],
      version_types: converted.version_types || [],
      work_ids: converted.work_ids || [],
      work_rights_controllers: converted.work_rights_controllers || [],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: getGeneralDataForSet(converted.sheetMusic),
    addTitles: convertAddTitlesForTable(
      converted.sheetMusic.additionalTitles || []
    ),
    cLines: converted.sheetMusic.cLines || [],
    contributors: convertBaseIdForTable(
      converted.sheetMusic.contributors || []
    ),
    courtesyLines: converted.sheetMusic.courtesyLines || [],
    dates: getDatesForSet(converted.sheetMusic),
    displayArtists: convertDisplayArtistsForTable(
      converted.sheetMusic.displayArtists || []
    ),
    displayArtistNames: converted.sheetMusic.displayArtistNames,
    displayTitles: convertAddTitlesForTable(
      converted.sheetMusic.displayTitles || []
    ),
    displayTitleTexts: converted.sheetMusic.displayTitleTexts,
    parentalWarningTypes: converted.sheetMusic.parentalWarningTypes,
    relatedReleases: converted.sheetMusic.relatedReleases,
    relatedResources: converted.sheetMusic.relatedResources || [],
    resourceIds: converted.sheetMusic.resourceIds || [],
    resourceContainedResourceReferenceList:
      converted.sheetMusic.resourceContainedResourceReferenceList || [],
    resourceRightsControllers:
      converted.sheetMusic.resourceRightsControllers || [],
    technicalDetails: converted.sheetMusic.technicalDetails || [],
    versionTypes: converted.sheetMusic.versionTypes || [],
    workIds: converted.sheetMusic.workIds || [],
    workRightsControllers: converted.sheetMusic.workRightsControllers || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
