import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const BaseValueComponent = React.lazy(() =>
  import("../BaseValueComponent").then((module) => ({
    default: module.BaseValueComponent,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = ["Deitie", "Applicable Territory Code", "Is Default"];

export const DeitiesTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value && !data.length) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="deities" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.value || ""}</td>
            <td>{el.applicableTerritoryCode}</td>
            <td>{`${el.default || false}`}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <BaseValueComponent
              hideLang
              label="Deitie"
              onChange={onChange}
              value={value}
            />
          </React.Suspense>
        )}
        modalTitle="Deitie"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
