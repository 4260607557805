import React, { useEffect, useState, useRef } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import { GeneralTab } from "../../components/tabs-chapter/ChapterElementGeneralTab";
import { AdditionalTitleTab } from "../../components/tables/AdditionalTitleTab";
import { ChapterIdsTab } from "../../components/tabs-chapter/ChapterIdsTab";
import { CharacterTab } from "../../components/tables/CharacterTab";
import { ContributorTab } from "../../components/tables/ContributorTab";
import { DisplayTitleTab } from "../../components/tables/DisplayTitleTab";
import { DisplayTitleTextTab } from "../../components/tables/DisplayTitleTextTab";
import { Resources as Context, Value_Chapter } from "../../contexts/resource";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/chapter";
import pageConverter from "../../api-converters/chapter";

import "../page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "additionalTitle", title: "Additional Title" },
  { id: "chapter_ids", title: "Chapter IDs" },
  { id: "characters", title: "Characters" },
  { id: "contributors", title: "Contributors" },
  { id: "displayTitleText", title: "Display Title Text" },
  { id: "displayTitle", title: "Display Titles" },
];

export const ChapterElement = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        id?: string;
        key?: number;
        newReleaseMessageId: string;
        type: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
  }) => {
    const id = props.match?.params?.id;
    const timeout: any = useRef(null);
    const history = useHistory();
    const validStatuses: any = useRef({
      general: undefined,
    });

    const maxTabWidth = 150;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [resourceData, setResourceData] = useState<any>({});
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState<any>({});

    const updateData = (data: any) => {
      setUpdatedData(data);
      setValidated(false);
    };

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getElementDataForSet(
        data,
        props.match?.params?.key
      );

      setResourceData(data);
      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const getData = () => {
      if (id) {
        props.api({
          ...pageApi.getData(id, props.match?.params?.newReleaseMessageId),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchElementData(
            id,
            props.match.params.newReleaseMessageId,
            resourceData,
            data,
            props.match?.params?.key
          ),
          successCb: () => {
            if (!props.match?.params?.key) {
              const chapters = resourceData.chapter.chapters || [];

              history.replace(
                `/ern/${props.match.params.newReleaseMessageId}/chapter/${id}/${chapters.length}`
              );
            }

            setDataSaving(false);
            getData();
          },
        });
      }
    };

    const submit = () => {
      setValidated(true);
      setIsValid({});

      timeout.current = setTimeout(() => {
        const allKeys = Object.keys(validStatuses.current);
        const validKeys = allKeys.filter(
          (el: any) => validStatuses.current[el]
        ).length;

        if (validKeys === allKeys.length) {
          setValidated(false);
          setDataSaving(true);
          saveData(updatedData);
        } else {
          props.setAlertData(
            ["Please correct the errors in the highlighted tabs and try again"],
            0
          );
          setDataSaving(false);
        }

        setIsValid(validStatuses.current);
      }, 1000);
    };

    useEffect(() => {
      if (id) getData();

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, [props.match.params]);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="page">
          <h2 className="page__title">
            {props.match?.params?.key !== undefined ? "Edit" : "Add"}
            {` Chapter`}
          </h2>
          <Context.Provider value={Value_Chapter}>
            <Tab.Container defaultActiveKey="general">
              <Col
                sm={12}
                className="page__tab-links-container"
                ref={tabsRef}
                style={{
                  width: `${tabsWidth}px`,
                  overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
                }}
              >
                <Nav
                  fill
                  variant="tabs"
                  style={{
                    width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
                  }}
                >
                  {tabs.map((el: any) => (
                    <Nav.Item key={el.id}>
                      <Nav.Link
                        eventKey={el.id}
                        className={
                          isValid[el.id] === false && validated
                            ? "error-tab"
                            : ""
                        }
                      >
                        {el.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <GeneralTab
                    onChange={(generalData: any) =>
                      updateData({
                        ...updatedData,
                        generalData,
                      })
                    }
                    setValidStatus={(general: boolean) => {
                      validStatuses.current.general = general;
                    }}
                    validated={validated}
                    value={baseData.generalData}
                  />
                </Tab.Content>
                <Tab.Content>
                  <AdditionalTitleTab
                    onChange={(additionalTitles: any) =>
                      updateData({
                        ...updatedData,
                        additionalTitles,
                      })
                    }
                    value={baseData.additionalTitles}
                  />
                </Tab.Content>
                <Tab.Content>
                  <ChapterIdsTab
                    onChange={(chapterIds: any) => {
                      updateData({
                        ...updatedData,
                        chapterIds,
                      });
                    }}
                    value={baseData.chapterIds}
                  />
                </Tab.Content>
                <Tab.Content>
                  <CharacterTab
                    onChange={(characters: any) => {
                      updateData({
                        ...updatedData,
                        characters,
                      });
                    }}
                    value={baseData.characters}
                  />
                </Tab.Content>
                <Tab.Content>
                  <ContributorTab
                    onChange={(contributors: any) => {
                      updateData({
                        ...updatedData,
                        contributors,
                      });
                    }}
                    value={baseData.contributors}
                  />
                </Tab.Content>
                <Tab.Content>
                  <DisplayTitleTab
                    onChange={(displayTitles: any) =>
                      updateData({
                        ...updatedData,
                        displayTitles,
                      })
                    }
                    value={baseData.displayTitles}
                  />
                </Tab.Content>
                <Tab.Content>
                  <DisplayTitleTextTab
                    onChange={(displayTitleTexts: any) =>
                      updateData({
                        ...updatedData,
                        displayTitleTexts,
                      })
                    }
                    value={baseData.displayTitleTexts}
                  />
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Context.Provider>
          <div className="page__btn-container">
            <SpinnerBtn
              disabled={getIsEqual() || dataSaving}
              isLoading={dataSaving}
              onClick={submit}
              text={
                id && props.match?.params?.type !== undefined
                  ? "Update"
                  : "Save"
              }
            />
          </div>
        </div>
      </DndProvider>
    );
  }
);
