import converter from "../api-converters/track-release";

const patchData = (id: string, newReleaseMessageId: string, data: any) => ({
  method: "patch",
  url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/track_releases/${id}`,
  data: converter.getDataForUpdate(data),
});

const postData = (newReleaseMessageId: string, data: any) => ({
  method: "post",
  url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/track_releases`,
  data: converter.getDataForUpdate(data),
});

const getData = (id: string, newReleaseMessageId: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${newReleaseMessageId}/release_list/track_releases/${id}`,
});

export default {
  patchData,
  postData,
  getData,
};
