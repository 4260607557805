import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";

const UserDefinedInput = React.lazy(() =>
  import("../UserDefinedInput").then((module) => ({
    default: module.UserDefinedInput,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

export const PromotionalCodeTab = (props: Props) => {
  const [data, setData] = useState<any>({});

  const onUpdate = (changes: any) => {
    setData({ ...data, ...changes });
    props.onChange({ ...data, ...changes });
  };

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="promotional_code" className="page__tab-content">
      <React.Suspense fallback={null}>
        <br />
        <UserDefinedInput
          hideUserDefined
          id="promotional_code"
          label="Promotional Code"
          onChange={onUpdate}
          value={data}
        />
      </React.Suspense>
    </Tab.Pane>
  );
};
