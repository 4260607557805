import React from "react";
import { AddDeleteWrapper } from "./AddDeleteWrapper";
import { Input } from "./Input";

const options = [
  "ASCII",
  "AsciiOrIso8859nText",
  "EBU-TT",
  "EPUB",
  "HTML",
  "LRC",
  "MicrosoftWord",
  "OpenDocumentText",
  "OOXML",
  "PDF",
  "PostScript",
  "RTF",
  "SRT",
  "TTML",
  "Unknown",
  "UserDefined",
  "UTF8Text",
  "VTT",
  "WindowsText",
  "XHTML",
  "XML",
];

interface Props {
  value: any;
  onChange: (updates: any) => void;
}

const UserDefinedSelect = React.lazy(() =>
  import("./UserDefinedSelect").then((module) => ({
    default: module.UserDefinedSelect,
  }))
);

export const TextCodecType = (props: Props) => {
  const getIsShow = () =>
    !!Object.keys(props.value?.textCodecType || {}).map(
      (el: string) => props.value?.textCodecType?.[el]
    ).length;

  const onDelete = () => {
    props.onChange({ textCodecType: {} });
  };

  return (
    <AddDeleteWrapper isShow={getIsShow()} needCheck={true} onDelete={onDelete}>
      <React.Suspense fallback={null}>
        <UserDefinedSelect
          dataSelect={true}
          id="use_types"
          label="Use Type"
          onChange={(value) =>
            props.onChange({
              textCodecType: {
                ...(props.value?.textCodecType || {}),
                ...value,
              },
            })
          }
          value={props.value.textCodecType}
          options={options}
          valueKey="value"
          withNamespace
        />
      </React.Suspense>
      <Input
        id="sheet-music-codec-version"
        inputWidth={8}
        label="Version"
        labelWidth={4}
        onChange={(version) =>
          props.onChange({
            textCodecType: {
              ...(props.value?.textCodecType || {}),
              version,
            },
          })
        }
        value={props.value.textCodecType?.version}
      />
    </AddDeleteWrapper>
  );
};
