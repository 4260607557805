import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form, Alert, Spinner } from "react-bootstrap";
import "./auth-modal.scss";

export const AuthModal = (props: any) => {
  const timeout: any = useRef(null);

  const [needConfirm, setNeedConfirm] = useState(false);
  const [view, setView] = useState(props.view);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [isVisibleAlert, setIsVisibleAlert] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onHide = () => {
    setView("signIn");
    setNeedConfirm(false);
    props.toggle();
  };

  const setAlertData = () => {
    if (view === "resetPassword") {
      setView("signIn");
      setIsVisibleAlert("Password reset. Please login in");
    }

    timeout.current = setTimeout(() => {
      clearTimeout(timeout.current);
      timeout.current = null;
      setIsVisibleAlert("");
    }, 5000);
  };

  const parseErrors = (data: any) => {
    const dataErrors: {
      [key: string]: string;
    } = {};

    const types = [
      "email",
      "password",
      "password_confirmation",
      "confirmation_token",
      "reset_password_token",
    ];

    if (data?.length) {
      (data || []).forEach((error: string) => {
        if (types.indexOf(error.toLowerCase()) !== -1) {
          dataErrors[error] = data[error];
        } else {
          setIsVisibleAlert(error);
          dataErrors.global = error;
          timeout.current = setTimeout(() => {
            clearTimeout(timeout.current);
            timeout.current = null;
            setIsVisibleAlert("");
          }, 5000);
        }
      });
    } else {
      Object.keys(data || {}).forEach((error: string) => {
        if (types.indexOf(error.toLowerCase()) !== -1) {
          dataErrors[error] = data[error];
        } else {
          setIsVisibleAlert(data[error]);
          dataErrors.global = data[error];
          timeout.current = setTimeout(() => {
            clearTimeout(timeout.current);
            timeout.current = null;
            setIsVisibleAlert("");
          }, 5000);
        }
      });
    }

    setErrors(dataErrors);
    setIsLoading(false);
  };

  useEffect(() => {
    if (props.view) {
      setView(props.view);

      if (props.view === "signUpConfirmation") {
        props.onSignUpConfirm(
          (data: any) => {
            setEmail(data.data.email);
            setView("signUpConfirmationSuccess");
          },
          (errors: any) => {
            setErrors(errors.response.data);
            setView("signUpConfirmationError");
          }
        );
      } else if (props.view === "resetPassword") {
        props.onResetPasswordCheck((errors: any) => {
          setErrors(errors.response.data);
        });
      }
    }
  }, [props.view]);

  const getTitle = () => {
    switch (view) {
      case "signIn":
        return "Sign In";
      case "signUp":
        return "Create an Account";
      case "forgot":
        return "Forgot Password";
      case "resetPassword":
        return "Reset Password";
      case "signUpConfirmation":
      case "signUpConfirmationSuccess":
      case "signUpConfirmationError":
        return "Sign Up Confirmation";
      default:
        return "";
    }
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
    setErrors({
      ...errors,
      email: "",
    });
  };

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
    setErrors({
      ...errors,
      password: "",
    });
  };

  const onChangePasswordConfirmation = (e: any) => {
    setPasswordConfirmation(e.target.value);
    setErrors({
      ...errors,
      password_confirmation: "",
    });
  };

  const onChangeView = (newView: string) => {
    setView(newView);
    setPassword("");
    setPasswordConfirmation("");
  };

  const onSignIn = () => {
    props.onSignIn(
      email,
      password,
      () => {
        setIsLoading(false);
        props.toggle();
      },
      parseErrors
    );
  };

  const onSignUp = () => {
    props.onSignUp(
      email,
      password,
      passwordConfirmation,
      () => {
        setIsLoading(false);
        setNeedConfirm(true);
      },
      parseErrors
    );
  };

  const onForgotPassword = () => {
    props.onForgotPassword(
      email,
      () => {
        setIsLoading(false);
        setNeedConfirm(true);
      },
      parseErrors
    );
  };

  const resetPassword = () => {
    props.onResetPassword(
      password,
      passwordConfirmation,
      () => {
        setIsLoading(false);
        setPassword("");
        setAlertData();
      },
      parseErrors
    );
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    switch (view) {
      case "signIn":
      case "signUpConfirmationSuccess":
        onSignIn();
        break;
      case "signUp":
      case "signUpConfirmationError":
        onSignUp();
        break;
      case "forgot":
        onForgotPassword();
        break;
      case "resetPassword":
        resetPassword();
        break;
      default:
        return null;
    }
  };

  if (needConfirm) {
    return (
      <Modal
        className="auth-modal"
        show={props.isOpen}
        onHide={onHide}
        size="sm"
        centered
      >
        <Modal.Header closeButton>{getTitle()}</Modal.Header>
        <Modal.Body>
          We have sent a confirmation email to {email}. You must confirm your
          account to continue.
        </Modal.Body>
      </Modal>
    );
  }

  const getEmailInput = () => (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control
        isInvalid={errors.email?.length}
        onChange={onChangeEmail}
        type="email"
        placeholder="Enter email"
        value={email}
      />
      {errors.email?.length ? (
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );

  const getPasswordInput = () => (
    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Control
        isInvalid={errors.password?.length}
        onChange={onChangePassword}
        type="password"
        placeholder="Password"
        value={password}
      />
      {errors.password?.length ? (
        <Form.Control.Feedback type="invalid">
          {errors.password}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );

  const getPasswordConfirmInput = () => (
    <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
      <Form.Control
        isInvalid={
          errors.password_confirmation?.length ||
          errors.reset_password_token?.length
        }
        onChange={onChangePasswordConfirmation}
        type="password"
        placeholder="Password confirmation"
        value={passwordConfirmation}
      />
      {errors.password_confirmation?.length ? (
        <Form.Control.Feedback type="invalid">
          {errors.password_confirmation}
        </Form.Control.Feedback>
      ) : null}
      {errors.reset_password_token?.length ? (
        <Form.Control.Feedback type="invalid">
          {`Reset token ${errors.reset_password_token}`}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );

  const getView = () => {
    if (view === "signUpConfirmation") {
      return <>Checking your confirm token</>;
    }

    if (view === "signUpConfirmationSuccess") {
      return (
        <>
          Account confirmation successful. Sign in to continue.
          <div className="bottom-link-container">
            <Button variant="link" onClick={() => onChangeView("signIn")}>
              Sign In
            </Button>
          </div>
        </>
      );
    }

    if (view === "signUpConfirmationError") {
      return (
        <>
          {`Error: email ${errors.email.join(", ")}`}
          <div className="bottom-link-container">
            <Button variant="link" onClick={() => onChangeView("signUp")}>
              Sign Up
            </Button>
          </div>
        </>
      );
    }

    if (view === "signIn") {
      return (
        <>
          {getEmailInput()}
          {getPasswordInput()}
          <div className="auth-footer">
            <Button
              disabled={!email || !password || isLoading}
              className="wide-btn"
              variant="primary"
              type="submit"
            >
              {isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" />
                  {"  "}
                </>
              ) : null}
              Sign in
            </Button>
            <br />
            <div className="bottom-links-container">
              <Button variant="link" onClick={() => onChangeView("signUp")}>
                Create an account
              </Button>
              <Button variant="link" onClick={() => onChangeView("forgot")}>
                Forgot password
              </Button>
            </div>
          </div>
        </>
      );
    }

    if (view === "forgot") {
      return (
        <>
          {getEmailInput()}
          <div className="auth-footer">
            <Button
              disabled={!email || isLoading}
              className="wide-btn"
              variant="primary"
              type="submit"
            >
              {isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" />
                  {"  "}
                </>
              ) : null}
              Send request
            </Button>
            <br />
            <div className="bottom-link-container">
              <Button variant="link" onClick={() => onChangeView("signIn")}>
                Sign in
              </Button>
            </div>
          </div>
        </>
      );
    }

    if (view === "resetPassword") {
      return (
        <>
          {errors.reset_password_token ? (
            <>
              {`Error: reset token ${errors.reset_password_token.join(", ")}`}
              <br />
              <br />
            </>
          ) : null}
          {getPasswordInput()}
          {getPasswordConfirmInput()}
          <div className="auth-footer">
            <Button
              disabled={!password || !passwordConfirmation || isLoading}
              className="wide-btn"
              variant="primary"
              type="submit"
            >
              {isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" />
                  {"  "}
                </>
              ) : null}
              Reset Password
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        {getEmailInput()}
        {getPasswordInput()}
        {getPasswordConfirmInput()}
        <div className="auth-footer">
          <Button
            disabled={!email || !password || !passwordConfirmation || isLoading}
            className="wide-btn"
            variant="primary"
            type="submit"
          >
            {isLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" />
                {"  "}
              </>
            ) : null}
            Create
          </Button>
          <br />
          <div className="bottom-link-container">
            <Button variant="link" onClick={() => onChangeView("signIn")}>
              Sign in
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        className="auth-modal"
        show={props.isOpen}
        onHide={props.toggle}
        size="sm"
        centered
      >
        <Alert
          variant={errors.global ? "danger" : "success"}
          className="global-alert"
          dismissible={false}
          show={!!isVisibleAlert}
        >
          {isVisibleAlert}
        </Alert>
        <Modal.Header closeButton>{getTitle()}</Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>{getView()}</Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
