import converter from "../api-converters/cue-sheet";

const patchData = (id: string, newReleaseMessageId: string, data: any) => ({
  method: "patch",
  url: `/ern/new_release_messages/${newReleaseMessageId}/cue_sheet_lists/cue_sheets/${id}`,
  data: converter.getDataForUpdate(data),
});

const postData = (newReleaseMessageId: string, data: any) => ({
  method: "post",
  url: `/ern/new_release_messages/${newReleaseMessageId}/cue_sheet_lists/cue_sheets`,
  data: converter.getDataForUpdate(data),
});

const getData = (id: string, newReleaseMessageId: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${newReleaseMessageId}/cue_sheet_lists/cue_sheets/${id}`,
});

const patchCueData = (
  id: string,
  newReleaseMessageId: string,
  resourceData: any,
  data: any,
  key?: number
) => ({
  method: "patch",
  url: `/ern/new_release_messages/${newReleaseMessageId}/cue_sheet_lists/cue_sheets/${id}`,
  data: converter.getCueDataForUpdate(resourceData, data, key),
});

export default {
  patchCueData,
  patchData,
  postData,
  getData,
};
