import React, { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";
import { Input } from "../Input";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const PLine = React.lazy(() =>
  import("../PLine").then((module) => ({
    default: module.PLine,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
  withApplicableCode?: boolean;
  withDefault?: boolean;
}

export const PLineTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();
  const [fields, setFields] = useState([
    "Text",
    "Year",
    "Company",
    "Language And Script Code",
  ]);

  useEffect(() => {
    const updated = [...fields];

    if (props.withApplicableCode) {
      updated.push("Applicable Territory Code");
    }

    if (props.withDefault) {
      updated.push("Is Default");
    }

    setFields(updated);
  }, []);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="pline" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.pLineText || ""}</td>
            <td>{el.year || ""}</td>
            <td>{el.pLineCompany || ""}</td>
            <td>{el.languageAndScriptCode || ""}</td>
            {props.withApplicableCode ? (
              <td>{el.applicableTerritoryCode || ""}</td>
            ) : null}
            {props.withDefault ? <td>{`${el.default || false}`}</td> : null}
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <>
            <React.Suspense fallback={null}>
              <PLine
                onChange={onChange}
                value={value}
                withApplicableCode={props.withApplicableCode}
                withDefault={props.withDefault}
              />
            </React.Suspense>
            <Input
              id="pline-type"
              value={value.pLineType || ""}
              label="PLine Type"
              onChange={(pLineType: string) => onChange({ pLineType })}
            />
          </>
        )}
        modalTitle="PLine"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
