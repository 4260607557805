import React, { useEffect, useState } from "react";
import { Container, Button as LinkBtn } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Dot } from "../../components/Dot";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/cue-sheet-list";
import { getFormattedDate } from "../../utils/get-formatted-date";
import { getOption } from "../../utils/parse-options";
import { Spacer } from "../../components/Spacer";
import "../page.scss";

export const CueSheetList = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    match: {
      params: {
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: any) => void;
  }) => {
    const id = props.match.params.newReleaseMessageId;
    const history = useHistory();
    const [recordings, setRecordings] = useState<any>([]);

    const addResourceRedirect = () => {
      history.push(`/ern/${id}/cue-sheet`);
    };

    const getData = () => {
      props.api({
        ...pageApi.getData(id),
        skipAlert: true,
        successCb: ({ data }: any) => {
          if (data) setRecordings(data);
        },
      });
    };

    const deleteData = (deleteId: string) => {
      props.api({
        ...pageApi.deleteData(id, deleteId),
        successCb: getData,
      });
    };

    useEffect(() => {
      if (id) {
        getData();
      } else {
        props.setAlertData(["Please, create message_header_id"]);
      }
    }, []);

    return (
      <>
        <Container>
          {!recordings.length ? (
            <>
              <h2>Cue Sheet List</h2>
              <Spacer />
            </>
          ) : null}
          <div className="page__btn-container row">
            {!recordings.length ? <p>No Cue Sheets</p> : <h2>Cue Sheets</h2>}
            <button className="btn btn-primary" onClick={addResourceRedirect}>
              Add&nbsp;Cue Sheet
            </button>
          </div>
          {recordings.length ? (
            <>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>Cue Sheet Reference</th>
                    <th>Cue Sheet Type</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {recordings.map((el: any, i: number) => (
                    <tr key={`sr-${i}`}>
                      <td>{el.cue_sheet.cue_sheet_reference || "-"}</td>
                      <td>{getOption(el.cue_sheet.cue_sheet_type || "-")}</td>
                      <td>{getFormattedDate(el.created_at)}</td>
                      <td>{getFormattedDate(el.updated_at)}</td>
                      <td>
                        <LinkBtn
                          variant="link"
                          onClick={() => {
                            history.push(`/ern/${id}/cue-sheet/${el.id}/`);
                          }}
                        >
                          Edit
                        </LinkBtn>
                        <Dot />
                        <LinkBtn
                          variant="link"
                          onClick={() => {
                            deleteData(el.id);
                          }}
                        >
                          Delete
                        </LinkBtn>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : null}
        </Container>
      </>
    );
  }
);
