import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { Input } from "../Input";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="resourceReference"
          label="Resource Reference"
          invalidFeedback="Please provide a valid value."
          prependText="A"
          pattern="[\d\-_a-zA-Z]+$"
          value={generalData.resourceReference || ""}
          onChange={(resourceReference) =>
            onUpdate({ ...generalData, resourceReference })
          }
        />
        <Input
          id="language_of_lyrics"
          label="Language Of Lyrics"
          value={generalData.languageOfLyrics || ""}
          onChange={(languageOfLyrics) =>
            onUpdate({ ...generalData, languageOfLyrics })
          }
        />
        <Input
          id="general-type"
          label="Type"
          value={generalData.type?.value || ""}
          onChange={(value) =>
            onUpdate({
              ...generalData,
              type: {
                ...(generalData.type || {}),
                value,
              },
            })
          }
        />
        <Input
          id="general-namespace"
          label="Namespace"
          value={generalData.type?.namespace || ""}
          onChange={(namespace) =>
            onUpdate({
              ...generalData,
              type: {
                ...(generalData.type || {}),
                namespace,
              },
            })
          }
        />
        <Input
          id="general-user-defined"
          label="User Defined Value"
          value={generalData.type?.userDefinedValue || ""}
          onChange={(userDefinedValue) =>
            onUpdate({
              ...generalData,
              type: {
                ...(generalData.type || {}),
                userDefinedValue,
              },
            })
          }
        />
        <Form.Group>
          <div>
            <Form.Check
              inline
              checked={generalData.containsHiddenContent || false}
              id="containsHiddenContent"
              type="checkbox"
              label="Contains Hidden Content"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  containsHiddenContent: !generalData.containsHiddenContent,
                })
              }
            />
          </div>
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
