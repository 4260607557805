import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { Input } from "../Input";
import { InputDuration } from "../InputDuration";
import { LanguageScriptField } from "../LanguageField";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="release_reference"
          label="Release Reference"
          prependText="R"
          pattern="[-\d_a-zA-Z]+"
          value={generalData.releaseReference || ""}
          onChange={(releaseReference) =>
            onUpdate({ ...generalData, releaseReference })
          }
        />
        <Input
          id="release_visibility_reference"
          label="Release Visibility Reference"
          prependText="V"
          pattern="[-\d_a-zA-Z]+"
          value={generalData.releaseVisibilityReference || ""}
          onChange={(releaseVisibilityReference) =>
            onUpdate({ ...generalData, releaseVisibilityReference })
          }
        />
        <InputDuration
          id="duration"
          onChange={(duration) => onUpdate({ ...generalData, duration })}
          value={generalData.duration || ""}
        />
        <Input
          id="hi_res_music_description"
          label="Hi Res Music Description"
          value={generalData.hiResMusicDescription || ""}
          onChange={(hiResMusicDescription) =>
            onUpdate({ ...generalData, hiResMusicDescription })
          }
        />
        <LanguageScriptField
          idDiff="add-title-lang"
          onChange={(languageAndScriptCode: any) =>
            onUpdate({ ...generalData, languageAndScriptCode })
          }
          value={generalData.languageAndScriptCode}
        />
        <Input
          id="target_url"
          label="Target Url"
          value={generalData.targetUrl || ""}
          onChange={(targetUrl) => onUpdate({ ...generalData, targetUrl })}
        />
        <Form.Group>
          <Form.Check
            inline
            checked={generalData.hiResMusic || false}
            id="hiResMusic"
            type="checkbox"
            label="Hi Res Music"
            onChange={() =>
              onUpdate({
                ...generalData,
                hiResMusic: !generalData.hiResMusic,
              })
            }
          />
          <Form.Check
            inline
            checked={generalData.multiArtistCompilation || false}
            id="multiArtistCompilation"
            type="checkbox"
            label="Multi Artist Compilation"
            onChange={() =>
              onUpdate({
                ...generalData,
                multiArtistCompilation: !generalData.multiArtistCompilation,
              })
            }
          />
          <Form.Check
            inline
            checked={generalData.singleArtistCompilation || false}
            id="singleArtistCompilation"
            type="checkbox"
            label="Single Artist Compilation"
            onChange={() =>
              onUpdate({
                ...generalData,
                singleArtistCompilation: !generalData.singleArtistCompilation,
              })
            }
          />
          <Form.Check
            inline
            checked={generalData.soundtrack || false}
            id="soundtrack"
            type="checkbox"
            label="Soundtrack"
            onChange={() =>
              onUpdate({
                ...generalData,
                soundtrack: !generalData.soundtrack,
              })
            }
          />
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
