import React from "react";
import { Form } from "react-bootstrap";

const Input = React.lazy(() =>
  import("./Input").then((module) => ({
    default: module.Input,
  }))
);

const LanguageScriptField = React.lazy(() =>
  import("./LanguageField").then((module) => ({
    default: module.LanguageScriptField,
  }))
);

const TerritorySelect = React.lazy(() =>
  import("./TerritorySelect").then((module) => ({
    default: module.TerritorySelect,
  }))
);

interface Props {
  checkLabel?: string;
  id: string;
  isTerritory?: boolean;
  eventDate: any;
  keyName: string;
  label: string;
  updateEventDate: (event: any) => void;
}

export const EventDateWithoutFlags = ({
  checkLabel,
  id,
  eventDate,
  keyName,
  label,
  updateEventDate,
}: Props) => {
  return (
    <div className="form-group event-date">
      <React.Suspense fallback={null}>
        <Input
          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}|[0-9]{4}-[0-9]{2}|[0-9]{4}"
          invalidFeedback="Date must be valid in the form YYYY, YYYY-MM or YYYY-MM-DD"
          id={`${id}-eventDateDate`}
          label={label}
          value={eventDate?.value || ""}
          onChange={(value) =>
            updateEventDate(
              !value
                ? { value: "" }
                : {
                    ...(eventDate || {}),
                    value,
                  }
            )
          }
        />
      </React.Suspense>
      {eventDate?.value ? (
        <>
          <Form.Group>
            <Form.Check
              checked={eventDate?.[keyName] || false}
              id={`${id}-eventDateApproximate`}
              type="checkbox"
              label={checkLabel || "Is Approximate"}
              onChange={() =>
                updateEventDate({
                  ...(eventDate || {}),
                  [keyName]: !eventDate?.[keyName],
                })
              }
            />
          </Form.Group>
          <React.Suspense fallback={null}>
            <Input
              id={`${id}-eventDateLocation`}
              label="Location Description"
              onChange={(locationDescription) =>
                updateEventDate({
                  ...(eventDate || {}),
                  locationDescription,
                })
              }
              type="textarea"
              value={eventDate?.locationDescription || ""}
            />
          </React.Suspense>
          <React.Suspense fallback={null}>
            <LanguageScriptField
              idDiff={id}
              onChange={(languageAndScriptCode: any) =>
                updateEventDate({
                  ...(eventDate || {}),
                  languageAndScriptCode,
                })
              }
              value={eventDate?.languageAndScriptCode || ""}
            />
          </React.Suspense>
          <React.Suspense fallback={null}>
            <TerritorySelect
              id={id}
              label="Applicable Territory Code"
              value={{
                label: eventDate.applicableTerritoryCode,
                value: eventDate.applicableTerritoryCode,
              }}
              onChange={(change) =>
                updateEventDate({
                  ...(eventDate || {}),
                  applicableTerritoryCode: change.value,
                })
              }
            />
          </React.Suspense>
        </>
      ) : null}
    </div>
  );
};
