import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { AccountModal } from "../components/AccountModal";
import { AuthModal } from "../components/AuthModal";
import withApi from "../hocs/withApi";
import api from "../api/auth";
import "./with-api.scss";

export default (Component: any) =>
  withApi((props: any) => {
    const cb: any = useRef(null);
    const history = useHistory();

    const [isNeedAccount, setIsNeedAccount] = useState(false);
    const [isNeedAuth, setIsNeedAuth] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [defaultView, setDefaultView] = useState("signIn");
    const [token, setToken] = useState<string | undefined>(undefined);
    const [userEmail, setUserEmail] = useState("");

    const hideModal = () => {
      cb.current = null;
      setIsNeedAuth(false);
    };

    const showError = (errors: any) => {
      props.setAlertData(errors);
    };

    const toggleAuthWithView = (view: string, viewToken?: string) => {
      setDefaultView(view);
      setIsNeedAuth(true);
      setToken(viewToken);
    };

    const toggleAuth = (authCb?: any) => {
      const token = localStorage.getItem("token");
      const isTokenExist = !!token;

      setIsNeedAuth(!isTokenExist);
      setIsAuthorized(isTokenExist);

      if (!token && authCb) {
        cb.current = authCb;
      }
    };

    const toggleAccount = () => {
      setIsNeedAccount(!isNeedAccount);
    };

    const getProfile = () => {
      if (localStorage.getItem("token")) {
        props.api({
          ...api.getAccount(),
          skipAlert: true,
          skipErrorAlert: true,
          successCb: (response: any) => {
            setUserEmail(response.data.email);
          },
        });
      }
    };

    const updateAuth = () => {
      setIsAuthorized(!!localStorage.getItem("token"));
    };

    const onSignIn = (
      email: string,
      password: string,
      successCb: () => void,
      errorCb: () => void
    ) => {
      props.api({
        ...api.signIn(email, password),
        skipAlert: true,
        skipErrorAlert: true,
        successCb: (response: any) => {
          setUserEmail(response.data.email);

          localStorage.setItem("token", response.headers.authorization);
          document.dispatchEvent(new Event("token-changed"));

          if (cb.current) cb.current();
          successCb();
          getProfile();

          if (window.location.pathname !== "/ern/new") {
            history.push("/ern");
          }
        },
        errorCb,
      });
    };

    const onSignUp = (
      email: string,
      password: string,
      passwordConfirmation: string,
      successCb: () => void,
      errorCb: () => void
    ) => {
      props.api({
        ...api.signUp(email, password, passwordConfirmation),
        skipAlert: true,
        skipErrorAlert: true,
        successCb,
        errorCb,
      });
    };

    const onSignUpConfirm = (successCb: () => void, errorCb: () => void) => {
      if (token) {
        props.api({
          ...api.signUpConfirmation(token),
          skipAlert: true,
          skipErrorAlert: true,
          successCb,
          errorCb,
        });
      } else {
        errorCb();
      }
    };

    const onSignOut = () => {
      props.api({
        ...api.signOut(),
        skipAlert: true,
        skipErrorAlert: true,
        successCb: () => {
          localStorage.removeItem("token");
          document.dispatchEvent(new Event("token-changed"));
          history.push("/");
        },
      });
    };

    const onForgotPassword = (
      email: string,
      successCb: () => void,
      errorCb: () => void
    ) => {
      props.api({
        ...api.forgotPassword(email),
        skipAlert: true,
        skipErrorAlert: true,
        successCb,
        errorCb,
      });
    };

    const onResetPasswordCheck = (errorCb: () => void) => {
      if (token) {
        props.api({
          ...api.resetPasswordCheck(token),
          skipAlert: true,
          skipErrorAlert: true,
          errorCb,
        });
      } else {
        errorCb();
      }
    };

    const onResetPassword = (
      password: string,
      passwordConfirmation: string,
      successCb: () => void,
      errorCb: () => void
    ) => {
      if (token) {
        props.api({
          ...api.resetPassword(password, passwordConfirmation, token),
          skipAlert: true,
          skipErrorAlert: true,
          successCb,
          errorCb,
        });
      }
    };

    const onUpdateAccount = (
      successCb: () => void,
      errorCb: (errors: any) => void,
      email?: string,
      password?: string,
      password_confirmation?: string
    ) => {
      props.api({
        ...api.updateAccount({
          ...(email ? { email } : {}),
          ...(password ? { password } : {}),
          ...(password_confirmation ? { password_confirmation } : {}),
        }),
        skipAlert: true,
        skipErrorAlert: false,
        successCb: ({ data }: any) => {
          if (data.errors) {
            errorCb(data.errors);
          } else {
            if (email) setUserEmail(email);
            successCb();
            toggleAccount();
          }
        },
        errorCb,
      });
    };

    useEffect(() => {
      updateAuth();
      getProfile();
      document.addEventListener("token-changed", updateAuth);

      return () => {
        document.removeEventListener("token-changed", updateAuth);
      };
    }, []);

    return (
      <>
        <AuthModal
          isOpen={isNeedAuth}
          toggle={hideModal}
          onForgotPassword={onForgotPassword}
          onResetPassword={onResetPassword}
          onResetPasswordCheck={onResetPasswordCheck}
          onSignIn={onSignIn}
          onSignUp={onSignUp}
          onSignUpConfirm={onSignUpConfirm}
          onSignOut={onSignOut}
          onUpdateAccount={onUpdateAccount}
          view={defaultView}
        />
        <AccountModal
          isOpen={isNeedAccount}
          onUpdate={onUpdateAccount}
          toggle={toggleAccount}
          userEmail={userEmail}
        />
        <Component
          {...props}
          isAuthorized={isAuthorized}
          onSignOut={onSignOut}
          toggleAccount={toggleAccount}
          toggleAuth={toggleAuth}
          toggleAuthWithView={toggleAuthWithView}
          showError={showError}
        />
      </>
    );
  });
