import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "./auth-modal.scss";

export const AccountModal = (props: any) => {
  const [email, setEmail] = useState(props.userEmail);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
    setErrors({
      ...errors,
      email: [],
    });
  };

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
    setErrors({
      ...errors,
      password: [],
    });
  };

  const onChangePasswordConfirmation = (e: any) => {
    setPasswordConfirmation(e.target.value);
    setErrors({
      ...errors,
      password_confirmation: [],
    });
  };

  const getIsDisabled = () => {
    if (!email && !password && !passwordConfirmation) return true;
    if (isLoading) return true;
    if (password && !passwordConfirmation) return true;
    if (!password && passwordConfirmation) return true;

    return false;
  };

  const onSubmit = (e: any) => {
    if (e) e.preventDefault();

    setIsLoading(true);

    props.onUpdate(
      () => {
        setIsLoading(false);
      },
      (data: any) => {
        setErrors(data);
        setIsLoading(false);
      },
      email,
      password,
      passwordConfirmation
    );
  };

  useEffect(() => {
    if (props.userEmail) setEmail(props.userEmail);
  }, [props.userEmail]);

  useEffect(() => {
    if (!props.isOpen) {
      setEmail(props.userEmail);
      setPassword("");
      setPasswordConfirmation("");
      setErrors({});
    }
  }, [props.isOpen, props.userEmail]);

  return (
    <Modal
      className="auth-modal"
      show={props.isOpen}
      onHide={props.toggle}
      size="sm"
      centered
    >
      <Modal.Header closeButton>Account</Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              isInvalid={errors.email?.length}
              onChange={onChangeEmail}
              type="email"
              placeholder="New email"
              value={email}
            />
            {errors.email?.length ? (
              <Form.Control.Feedback type="invalid">
                {errors.email[0]}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              isInvalid={errors.password?.length}
              onChange={onChangePassword}
              type="password"
              placeholder="New password"
              value={password}
            />
            {errors.password?.length ? (
              <Form.Control.Feedback type="invalid">
                {errors.password[0]}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
            <Form.Control
              isInvalid={errors.password_confirmation?.length}
              onChange={onChangePasswordConfirmation}
              type="password"
              placeholder="Confirm new password"
              value={passwordConfirmation}
            />
            {errors.password_confirmation?.length ? (
              <Form.Control.Feedback type="invalid">
                {errors.password_confirmation[0]}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <div className="auth-footer">
            <div className="bottom-link-container">
              <Button disabled={getIsDisabled()} onClick={onSubmit}>
                Update
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
