import React from "react";
import { Input } from "./Input";

interface Props {
  idDiff?: string;
  inputWidth?: number;
  label?: string;
  labelWidth?: number;
  value: string;
  onChange: (value: string) => void;
}

export const LanguageScriptField = (props: Props) => {
  return (
    <Input
      id={`${props.idDiff || ""}-language-script-code`}
      inputWidth={props.inputWidth}
      invalidFeedback="Provided code is not valid."
      label={props.label || "Language & Script Code"}
      labelWidth={props.labelWidth}
      pattern="^\w{2}(?:-\w{2,3})(?:-\w+(?:-\w+)?)?$"
      onChange={props.onChange}
      value={props.value}
    />
  );
};
