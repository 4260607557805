import {
  transformToCamelCase,
  transformToSnakeCase,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    track_release: {
      ...converted.general_data,
      additional_titles: convertAddTitlesForApi(converted.add_titles || []),
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      genres: converted.genres,
      keywords: converted.keywords,
      linked_release_resource_references:
        converted.linked_release_resource_references,
      marketing_comments: converted.marketing_comments,
      related_releases: converted.related_releases || [],
      related_resources: converted.related_resources || [],
      release_id: converted.release_id || {},
      release_label_references: converted.release_label_references || [],
      synopsises: converted.synopsises || [],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: converted.trackRelease,
    addTitles: convertAddTitlesForTable(
      converted.trackRelease.additionalTitles || []
    ),
    displayTitles: convertAddTitlesForTable(
      converted.trackRelease.displayTitles || []
    ),
    displayTitleTexts: converted.trackRelease.displayTitleTexts,
    genres: converted.trackRelease.genres || [],
    keywords: converted.trackRelease.keywords || [],
    linkedReleaseResourceReferences:
      converted.trackRelease.linkedReleaseResourceReferences || [],
    marketingComments: converted.trackRelease.marketingComments || [],
    relatedReleases: converted.trackRelease.relatedReleases,
    relatedResources: converted.trackRelease.relatedResources || [],
    releaseId: converted.trackRelease.releaseId || {},
    releaseLabelReferences: converted.trackRelease.releaseLabelReferences || [],
    synopsises: converted.trackRelease.synopsises || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
