import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

interface Props {
  id?: string;
  newReleaseMessageId: string;
  onChange: (data: any) => void;
  value: any;
}

const fields = [
  "Chapter Reference",
  "Representative Image Reference",
  "Language And Script Code",
];

export const ChaptersTab = (props: Props) => {
  const history = useHistory();
  const [data, , remove, , , setDefault] = useArray<any>();

  const add = () => {
    if (!props.id) return;

    history.push(`/ern/${props.newReleaseMessageId}/chapter/${props.id}`);
  };

  const update = (data: any, index: number) => {
    if (!props.id) return;

    history.push(
      `/ern/${props.newReleaseMessageId}/chapter/${props.id}/${index}`
    );
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="chapters" className="page__tab-content">
      {!props.id ? (
        <h6>Please, save changes before adding Chapters</h6>
      ) : (
        <TableComponent
          add={add}
          data={data || []}
          emptyElement={{}}
          fields={fields}
          getCells={(el: any) => (
            <>
              <td>{el.chapterReference || ""}</td>
              <td>{el.representativeImageReference || ""}</td>
              <td>{el.languageAndScriptCode || ""}</td>
            </>
          )}
          onSortTable={setDefault}
          remove={remove}
          update={update}
          isSortable
        />
      )}
    </Tab.Pane>
  );
};
