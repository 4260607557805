import React, { useEffect, useRef, useState } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { GeneralTab } from "../components/tabs-message-header/GeneralTab";
import { MessageSenderTab } from "../components/tabs-message-header/MessageSenderTab";
import { MessageRecipientsTab } from "../components/tabs-message-header/MessageRecipientsTab";
import { SpinnerBtn } from "../components/SpinnerBtn";
import withApi from "../hocs/withApi";
import pageApi from "../api/message-header";
import pageConverter from "../api-converters/message-header";
import "./page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "message_sender", title: "Message Sender" },
  { id: "sent_on_behalf_of", title: "Sent On Behalf Of" },
  { id: "message_recipients", title: "Message Recipients" },
];

export const MessageHeader = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    match: {
      params: {
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
  }) => {
    const id = props.match.params.newReleaseMessageId;

    const maxTabWidth = 150;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [isExistData, setIsExistData] = useState(false);
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState<any>({});

    const timeout: any = useRef(null);
    const validStatuses: any = useRef({
      general: undefined,
      message_sender: undefined,
      sent_on_behalf_of: undefined,
    });

    const updateData = (data: any) => {
      setUpdatedData(data);
    };

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getDataForSet(data);

      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
      setIsExistData(!!data);
    };

    const saveData = (data: any) => {
      setDataSaving(true);

      if (isExistData) {
        props.api({
          ...pageApi.patchData(id, data),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      } else {
        props.api({
          ...pageApi.postData(id, data),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);
          },
          errorCb: () => {
            setDataSaving(false);
            setIsExistData(true);
          },
        });
      }
    };

    const submit = () => {
      setValidated(true);
      setIsValid({});

      timeout.current = setTimeout(() => {
        const allKeys = Object.keys(validStatuses.current);
        const validKeys = allKeys.filter(
          (el: any) => validStatuses.current[el]
        ).length;

        if (validKeys === allKeys.length) {
          setValidated(false);
          setDataSaving(true);
          saveData(updatedData);
        } else {
          props.setAlertData(
            ["Please correct the errors in the highlighted tabs and try again"],
            0
          );
          setDataSaving(false);
        }

        setIsValid(validStatuses.current);
      }, 1000);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(id),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }
    }, [props.match.params]);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <div className="page">
        <h2 className="page__title">Message Header</h2>
        <Tab.Container defaultActiveKey="general">
          <Col
            sm={12}
            className="page__tab-links-container"
            ref={tabsRef}
            style={{
              width: `${tabsWidth}px`,
              overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
            }}
          >
            <Nav
              fill
              variant="tabs"
              style={{
                width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
              }}
            >
              {tabs.map((el: any) => (
                <Nav.Item key={el.id}>
                  <Nav.Link
                    eventKey={el.id}
                    className={
                      isValid[el.id] === false && validated ? "error-tab" : ""
                    }
                  >
                    {el.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <GeneralTab
                onChange={(generalData: any) => {
                  updateData({
                    ...updatedData,
                    generalData: {
                      ...(updatedData.generalData || {}),
                      ...generalData,
                    },
                  });
                }}
                value={baseData.generalData}
                setValidStatus={(general: boolean) => {
                  validStatuses.current.general = general;
                }}
                validated={validated}
              />
              <MessageSenderTab
                id="message_sender"
                onChange={(messageSender: any) => {
                  updateData({
                    ...updatedData,
                    messageSender: {
                      ...(updatedData.messageSender || {}),
                      ...messageSender,
                    },
                  });
                }}
                tabName="message_sender"
                value={baseData.messageSender}
                setValidStatus={(message_sender: boolean) => {
                  validStatuses.current.message_sender = message_sender;
                }}
                validated={validated}
              />
              <MessageSenderTab
                id="sent_on_behalf_of"
                onChange={(sentOnBehalfOf: any) => {
                  updateData({
                    ...updatedData,
                    sentOnBehalfOf: {
                      ...(updatedData.sentOnBehalfOf || {}),
                      ...sentOnBehalfOf,
                    },
                  });
                }}
                tabName="sent_on_behalf_of"
                value={baseData.sentOnBehalfOf}
                setValidStatus={(sent_on_behalf_of: boolean) => {
                  validStatuses.current.sent_on_behalf_of = sent_on_behalf_of;
                }}
                validated={validated}
              />
            </Tab.Content>
            <Tab.Content>
              <MessageRecipientsTab
                onChange={(messageRecipients: any) => {
                  updateData({
                    ...updatedData,
                    messageRecipients,
                  });
                }}
                value={baseData.messageRecipients}
              />
            </Tab.Content>
          </Col>
        </Tab.Container>
        <div className="page__btn-container">
          <SpinnerBtn
            disabled={getIsEqual() || dataSaving}
            isLoading={dataSaving}
            onClick={submit}
            text={id !== undefined ? "Update" : "Save"}
          />
        </div>
      </div>
    );
  }
);
