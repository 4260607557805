import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const Input = React.lazy(() =>
  import("../Input").then((module) => ({
    default: module.Input,
  }))
);

const UserDefinedInput = React.lazy(() =>
  import("../UserDefinedInput").then((module) => ({
    default: module.UserDefinedInput,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Record Company Party Reference",
  "Namespace",
  "User Defined Value",
  "Role",
];

export const AdministratingRecordCompaniesTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value && !data.length) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane
      eventKey="administratingRecordCompanies"
      className="page__tab-content"
    >
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.recordCompanyPartyReference}</td>
            <td>{el.role?.namespace || ""}</td>
            <td>{el.role?.userDefinedValue || ""}</td>
            <td>{el.role?.value}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <>
            <React.Suspense fallback={null}>
              <Input
                id="recordCompanyPartyReference"
                label="Record Company Party Reference"
                prependText="P"
                pattern="[\d\-_a-zA-Z]+"
                onChange={(recordCompanyPartyReference: string) =>
                  onChange({ recordCompanyPartyReference })
                }
                value={value?.recordCompanyPartyReference || ""}
              />
            </React.Suspense>
            <React.Suspense fallback={null}>
              <UserDefinedInput
                id="g-cat"
                label="Role"
                onChange={(data: any) =>
                  onChange({
                    role: {
                      ...(value?.role || {}),
                      ...data,
                    },
                  })
                }
                value={value?.role || {}}
              />
            </React.Suspense>
          </>
        )}
        modalTitle="Administrating Record Company"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
