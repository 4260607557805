import React, { useState, useEffect, useRef } from "react";
import { Tab, Form } from "react-bootstrap";
import { parse, toSeconds } from "iso8601-duration";
import { InputDuration } from "../InputDuration";
import { Input } from "../Input";
import { UserDefinedSelect } from "../UserDefinedSelect";
import data from "../../data/cue-types.json";
import { Spacer } from "../Spacer";
import { calculate } from "../../utils/calculate-duration";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  const onUpdateTime = (type: string, value: string) => {
    try {
      if (type === "start") {
        if (generalData.endTime) {
          const startSeconds = toSeconds(parse(value));
          const endSeconds = toSeconds(parse(generalData.endTime));

          onUpdate({
            ...generalData,
            startTime: value,
            duration: calculate(endSeconds - startSeconds),
          });
        } else {
          onUpdate({
            ...generalData,
            startTime: value,
            duration: "",
          });
        }
      } else if (type === "end") {
        if (generalData.startTime) {
          const startSeconds = toSeconds(parse(generalData.startTime));
          const endSeconds = toSeconds(parse(value));

          console.log(calculate(endSeconds - startSeconds));

          onUpdate({
            ...generalData,
            endTime: value,
            duration: calculate(endSeconds - startSeconds),
          });
        } else {
          onUpdate({
            ...generalData,
            endTime: value,
            duration: "",
          });
        }
      }
    } catch (e) {
      if (type === "start") {
        onUpdate({
          ...generalData,
          startTime: value,
          duration: "",
        });
      } else {
        onUpdate({
          ...generalData,
          endTime: value,
          duration: "",
        });
      }
    }
  };

  const getDuration = () => {
    try {
      if (generalData.startTime && generalData.endTime) {
        const startSeconds = toSeconds(parse(generalData.startTime));
        const endSeconds = toSeconds(parse(generalData.endTime));

        return calculate(endSeconds - startSeconds);
      }

      return "";
    } catch (e) {
      return "";
    }
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <UserDefinedSelect
          dataSelect={true}
          id="cue_use_type"
          label="Cue Use Type"
          onChange={(value: any) =>
            onUpdate({
              ...generalData,
              cueUseType: {
                ...generalData.cueUseType,
                ...value,
              },
            })
          }
          options={data.cueUseType}
          value={generalData.cueUseType}
          valueKey="value"
          withNamespace
        />
        <Spacer />
        <UserDefinedSelect
          dataSelect={true}
          id="cue_theme_type"
          label="Cue Theme Type"
          onChange={(value: any) =>
            onUpdate({
              ...generalData,
              cueThemeType: {
                ...generalData.cueThemeType,
                ...value,
              },
            })
          }
          options={data.cueThemeType}
          value={generalData.cueThemeType}
          valueKey="value"
          withNamespace
        />
        <Spacer />
        <UserDefinedSelect
          dataSelect={true}
          id="cue_vocal_type"
          label="Cue Vocal Type"
          onChange={(value: any) =>
            onUpdate({
              ...generalData,
              cueVocalType: {
                ...generalData.cueVocalType,
                ...value,
              },
            })
          }
          options={data.cueVocalType}
          value={generalData.cueVocalType}
          valueKey="value"
          withNamespace
        />
        <Spacer />
        <UserDefinedSelect
          dataSelect={true}
          id="cue_visual_perception_type"
          label="Cue Visual Perception Type"
          onChange={(value: any) =>
            onUpdate({
              ...generalData,
              cueVisualPerceptionType: {
                ...generalData.cueVisualPerceptionType,
                ...value,
              },
            })
          }
          options={data.cueVisualPerceptionType}
          value={generalData.cueVisualPerceptionType}
          valueKey="value"
          withNamespace
        />
        <Spacer />
        <UserDefinedSelect
          dataSelect={true}
          id="cue_origin"
          label="Cue Origin"
          onChange={(value: any) =>
            onUpdate({
              ...generalData,
              cueOrigin: {
                ...generalData.cueOrigin,
                ...value,
              },
            })
          }
          options={data.cueOrigin}
          value={generalData.cueOrigin}
          valueKey="value"
          withNamespace
        />
        <Spacer />
        <InputDuration
          id="start-time"
          label="Start Time"
          onChange={(startTime: any) => onUpdateTime("start", startTime)}
          value={generalData?.startTime}
        />
        <InputDuration
          id="end-time"
          label="End Time"
          onChange={(endTime: any) => onUpdateTime("end", endTime)}
          value={generalData?.endTime}
        />
        <Input id="duration" label="Duration" value={getDuration()} readOnly />
        <Spacer />
        <Form.Group className="contributor-checkboxes">
          <Form.Check
            inline
            checked={generalData.dance || false}
            id="dance"
            type="checkbox"
            label="Is Dance?"
            onChange={() =>
              onUpdate({
                ...generalData,
                dance: !generalData.dance,
              })
            }
          />
          <Form.Check
            inline
            checked={generalData.hasMusicalContent || false}
            id="hasMusicalContent"
            type="checkbox"
            label="Has Musical Content?"
            onChange={() =>
              onUpdate({
                ...generalData,
                hasMusicalContent: !generalData.hasMusicalContent,
              })
            }
          />
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
