import React from "react";
import { Ern } from "./Ern";
import withAuth from "../../hocs/withAuth";

import "../page.scss";

export const ErnLayout = withAuth((props: any) => (
  <>
    <div className="content">
      <Ern {...props} />
    </div>
  </>
));
