import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const InputDateTime = React.lazy(() =>
  import("../InputDateTime").then((module) => ({
    default: module.InputDateTime,
  }))
);

const InputIsDefault = React.lazy(() =>
  import("../InputIsDefault").then((module) => ({
    default: module.InputIsDefault,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = ["Latest Date For Physical Returns", "Physical Returns Allowed"];

export const PhysicalReturnsTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Tab.Pane eventKey="physical_returns" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => (
          <React.Fragment key={index}>
            <td>{el?.latestDateForPhysicalReturns || ""}</td>
            <td>{`${el.physicalReturnsAllowed || false}`}</td>
          </React.Fragment>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <>
            <React.Suspense fallback={null}>
              <InputDateTime
                id="date"
                label="Latest Date For Physical Returns"
                onChangeDate={(latestDateForPhysicalReturns: any) =>
                  onChange({ latestDateForPhysicalReturns })
                }
                type="Date"
                valueDate={value?.latestDateForPhysicalReturns}
              />
            </React.Suspense>
            <React.Suspense fallback={null}>
              <InputIsDefault
                id="physicalReturnsAllowed"
                label="Is Physical Returns Allowed"
                value={value?.physicalReturnsAllowed || false}
                onChange={() =>
                  onChange({
                    physicalReturnsAllowed: !value.physicalReturnsAllowed,
                  })
                }
              />
            </React.Suspense>
          </>
        )}
        modalTitle="Physical Return"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
