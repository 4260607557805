import React from "react";
import { Select } from "./Select";
import parseOptions from "../utils/parse-options";

interface Props {
  data: string[];
  id: string;
  inputWidth?: number;
  isMulti?: boolean;
  label: string;
  labelWidth?: number;
  value?: {
    label: string;
    value: string;
  };
  onChoose: (value: any) => void;
}

export const SelectData = (props: Props) => (
  <Select options={parseOptions(props.data)} {...props} />
);
