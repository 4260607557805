import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const DistributionChannels = React.lazy(() =>
  import("../DistributionChannels").then((module) => ({
    default: module.DistributionChannels,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = ["Urls", "Party Names Count", "Trading Name"];

export const ExcludedDistributionChannelsTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Tab.Pane
      eventKey="excluded_distribution_channels"
      className="page__tab-content"
    >
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => (
          <React.Fragment key={index}>
            <td>{(el.urls || []).join(", ")}</td>
            <td>{(el.partyNames || []).length}</td>
            <td>{el.tradingName?.value || ""}</td>
          </React.Fragment>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <DistributionChannels
              nameKey="tradingName"
              onChange={onChange}
              value={value}
            />
          </React.Suspense>
        )}
        modalTitle="Excluded Distribution Channel"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
