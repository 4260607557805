import {
  transformToCamelCase,
  transformToSnakeCase,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getGeneralDataForSet = (data: any) => ({
  cueSheetReference: data.cueSheetReference,
  cueSheetType: data.cueSheetType,
});

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    cue_sheet: {
      ...converted.general_data,
      cue_sheet_ids: converted.cue_sheet_ids || [],
      cues: converted.cues,
    },
  };
};

const getCueDataForUpdate = (
  resourceData: any,
  data: any,
  releaseKey?: number
) => {
  const converted = transformToSnakeCase(data);

  if (releaseKey) {
    resourceData.cue_sheet.cues[releaseKey] = {
      ...converted.general_data,
      additional_titles: convertAddTitlesForApi(
        converted.additional_titles || []
      ),
      c_lines: converted.c_lines || [],
      contributors: converted.contributors || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      p_lines: converted.p_lines || [],
      resource_id: converted.resource_id,
      work_id: converted.work_id,
    };

    return resourceData;
  }

  return {
    cue_sheet: {
      ...resourceData.cue_sheet,
      cues: [
        ...(resourceData.cue_sheet.cues || []),
        {
          ...converted.general_data,
          additional_titles: convertAddTitlesForApi(
            converted.additional_titles || []
          ),
          c_lines: converted.c_lines || [],
          contributors: converted.contributors || [],
          display_titles: convertAddTitlesForApi(
            converted.display_titles || []
          ),
          display_title_texts: converted.display_title_texts || [],
          p_lines: converted.p_lines || [],
          resource_id: converted.resource_id,
          work_id: converted.work_id,
        },
      ],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: getGeneralDataForSet(converted.cueSheet),
    cueSheetIds: converted.cueSheet.cueSheetIds || [],
    cues: converted.cueSheet.cues || [],
  };
};

const getCueDataForSet = (data: any, releaseKey?: number) => {
  const converted = transformToCamelCase(data);

  if (releaseKey !== undefined) {
    const data = converted.cueSheet.cues[releaseKey];

    return {
      generalData: {
        cueUseType: data.cueUseType,
        cueThemeType: data.cueThemeType,
        cueVocalType: data.cueVocalType,
        cueVisualPerceptionType: data.cueVisualPerceptionType,
        cueOrigin: data.cueOrigin,
        dance: data.dance,
        hasMusicalContent: data.hasMusicalContent,
        startTime: data.startTime,
        endTime: data.endTime,
        duration: data.duration,
      },
      additionalTitles: convertAddTitlesForTable(data.additionalTitles || []),
      cLines: data.cLines || [],
      contributors: data.contributors || [],
      displayTitles: convertAddTitlesForTable(data.displayTitles || []),
      displayTitleTexts: data.displayTitleTexts || [],
      pLines: data.pLines || [],
      resourceId: data.resourceId || {},
      workId: data.workId || {},
    };
  }

  return {};
};

export default {
  getDataForUpdate,
  getCueDataForUpdate,
  getDataForSet,
  getCueDataForSet,
};
