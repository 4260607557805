import React from "react";
import { AddDeleteWrapper } from "./AddDeleteWrapper";
import { Input } from "./Input";
import { Select } from "./Select";

interface Props {
  value: any;
  onChange: (updates: any) => void;
}

const options = ["MacOS", "MsWindows", "Symbian", "Unknown"].map(
  (el: string) => ({
    label: el,
    value: el,
  })
);

export const OperatingSystemType = (props: Props) => {
  const getIsShow = () =>
    !!Object.keys(props.value?.operatingSystemType || {}).map(
      (el: string) => props.value?.operatingSystemType?.[el]
    ).length;

  const onDelete = () => {
    props.onChange({ operatingSystemType: {} });
  };

  return (
    <AddDeleteWrapper isShow={getIsShow()} needCheck={true} onDelete={onDelete}>
      <Select
        id="operating-type"
        label="Operating System Type"
        onChoose={(change: any) =>
          props.onChange({
            operatingSystemType: {
              ...(props.value?.operatingSystemType || {}),
              value: change.value,
            },
          })
        }
        options={options}
        value={{
          label: props.value?.operatingSystemType?.value,
          value: props.value?.operatingSystemType?.value,
        }}
      />
      <Input
        id="sheet-music-codec-user-defined"
        label="User Defined Value"
        onChange={(userDevinedValue) =>
          props.onChange({
            operatingSystemType: {
              ...(props.value?.operatingSystemType || {}),
              userDevinedValue,
            },
          })
        }
        value={props.value.operatingSystemType?.userDevinedValue}
      />
      <Input
        id="sheet-music-codec-namespace"
        label="Namespace"
        onChange={(namespace) =>
          props.onChange({
            operatingSystemType: {
              ...(props.value?.operatingSystemType || {}),
              namespace,
            },
          })
        }
        value={props.value.operatingSystemType?.namespace}
      />
      <Input
        id="sheet-music-codec-version"
        label="Version"
        onChange={(version) =>
          props.onChange({
            operatingSystemType: {
              ...(props.value?.operatingSystemType || {}),
              version,
            },
          })
        }
        value={props.value.operatingSystemType?.version}
      />
    </AddDeleteWrapper>
  );
};
