import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { TableComponent } from "../TableComponent";
import { Spacer } from "../Spacer";
import { FulfillmentDateWithTerritory } from "../FulfillmentDateWithTerritory";
import { useArray } from "../../hooks/useArray";

interface Props {
  onChangeOriginalReleaseDates: (value: any) => void;
  onChangeReleaseDates: (value: any) => void;
  valueOriginalReleaseDates: any;
  valueReleaseDates: any;
}

const baseFields = ["Territory Code", "Default", "Approximate"];
const fieldsRelease = ["Original Release Date", ...baseFields];
const fieldsOriginalRelease = ["Original Release Date", ...baseFields];

export const DatesTab = (props: Props) => {
  const [
    dataOriginal,
    addOriginal,
    removeOriginal,
    updateOriginal,
    ,
    setOriginalDefault,
  ] = useArray<any>();
  const [
    dataRelease,
    addRelease,
    removeRelease,
    updateRelease,
    ,
    setReleaseDefault,
  ] = useArray<any>();

  useEffect(() => {
    if (props.valueReleaseDates) {
      setReleaseDefault(props.valueReleaseDates);
    }
  }, [props.valueReleaseDates]);

  useEffect(() => {
    if (props.valueOriginalReleaseDates) {
      setOriginalDefault(props.valueOriginalReleaseDates);
    }
  }, [props.valueOriginalReleaseDates]);

  useEffect(() => {
    props.onChangeOriginalReleaseDates(dataOriginal);
  }, [dataOriginal]);

  useEffect(() => {
    props.onChangeReleaseDates(dataRelease);
  }, [dataRelease]);

  return (
    <Tab.Pane eventKey="dates" className="page__tab-content">
      <h5>Release Date</h5>
      <TableComponent
        add={addRelease}
        addTitle="Add Release Date"
        data={dataRelease || []}
        emptyElement={{}}
        fields={fieldsRelease}
        getCells={(el: any) => (
          <>
            <td>{el.value}</td>
            <td>{el.applicableTerritoryCode || ""}</td>
            <td>{`${el.default || false}`}</td>
            <td>{`${el.approximate || false}`}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <FulfillmentDateWithTerritory
            id="first-publication-date-input"
            keyName="default"
            eventDate={value || {}}
            label="Release Date"
            updateEventDate={onChange}
            withApproximate
          />
        )}
        modalTitle="Release Date"
        remove={removeRelease}
        update={updateRelease}
      />
      <Spacer />
      <h5>Original Release Date</h5>
      <TableComponent
        add={addOriginal}
        addTitle="Add Original Release Date"
        data={dataOriginal || []}
        emptyElement={{}}
        fields={fieldsOriginalRelease}
        getCells={(el: any) => (
          <>
            <td>{el.value}</td>
            <td>{el.applicableTerritoryCode || ""}</td>
            <td>{`${el.default || false}`}</td>
            <td>{`${el.approximate || false}`}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <FulfillmentDateWithTerritory
            id="first-publication-date-input"
            keyName="default"
            eventDate={value || {}}
            label="Original Release Date"
            updateEventDate={onChange}
            withApproximate
          />
        )}
        modalTitle="Original Release Date"
        remove={removeOriginal}
        update={updateOriginal}
      />
    </Tab.Pane>
  );
};
