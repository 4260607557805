import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { Input } from "../Input";
import { MultiInput } from "../MultiInput";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="party_reference"
          inputWidth={8}
          label="Party Reference"
          labelWidth={4}
          invalidFeedback="Please provide a valid value."
          prependText="P"
          pattern="^[\d\-_a-zA-Z]+$"
          value={generalData.partyReference || ""}
          onChange={(partyReference) =>
            onUpdate({ ...generalData, partyReference })
          }
        />
        <MultiInput
          data={generalData}
          dataKey="artistProfilePages"
          id="artistProfilePages"
          label="Artist Profile Pages"
          update={onUpdate}
        />
      </Form>
    </Tab.Pane>
  );
};
