import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import options from "../../data/use-types.json";
import { getOption } from "../../utils/parse-options";

const InputIsDefault = React.lazy(() =>
  import("../InputIsDefault").then((module) => ({
    default: module.InputIsDefault,
  }))
);

const UserDefinedSelect = React.lazy(() =>
  import("../UserDefinedSelect").then((module) => ({
    default: module.UserDefinedSelect,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Use Type",
  "Namespace",
  "User Defined Value",
  "Is Discoverable",
];

export const UseTypesTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Tab.Pane eventKey="use_types" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => (
          <React.Fragment key={index}>
            <td>{getOption(el.value || "")}</td>
            <td>{el.namespace || ""}</td>
            <td>{el.userDefinedValue || ""}</td>
            <td>{`${el.discoverable || false}`}</td>
          </React.Fragment>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <>
            <React.Suspense fallback={null}>
              <UserDefinedSelect
                dataSelect={true}
                id="use_types"
                label="Use Type"
                onChange={onChange}
                value={value}
                options={options}
                valueKey="value"
                withNamespace
              />
            </React.Suspense>
            <React.Suspense fallback={null}>
              <InputIsDefault
                id="is-discoverable"
                label="Is Discoverable"
                value={value?.discoverable}
                onChange={() =>
                  onChange({ discoverable: !value?.discoverable })
                }
              />
            </React.Suspense>
          </>
        )}
        modalTitle="Use Type"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
