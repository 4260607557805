import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { ProprietaryIdElement } from "../ProprietaryIdElement";
import { TableComponent } from "../TableComponent";

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = ["Cue Sheet ID", "Namespace"];

export const CueSheetIdsTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Tab.Pane eventKey="cue_sheet_ids" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.value || "-"}</td>
            <td>{el.namespace || "-"}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <ProprietaryIdElement
            label="Cue Sheet ID"
            value={value}
            onChange={onChange}
          />
        )}
        modalTitle="Cue Sheet ID"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
