import React from "react";
import { Button, Modal } from "react-bootstrap";

import "./Modal.scss";

interface Props {
  backdropClassName?: string;
  children: any;
  data: any;
  isAdd: boolean;
  isOpen: boolean;
  onChange: (value: any) => void;
  onSave: () => void;
  title: string;
  toggle: () => void;
}

export const ModalAddEdit = (props: Props) => (
  <Modal
    show={props.isOpen}
    onHide={props.toggle}
    size="lg"
    backdropClassName={props.backdropClassName}
  >
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.data ? props.children : null}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={props.toggle}>
        Cancel
      </Button>
      {props.isAdd ? (
        <Button variant="primary" onClick={() => props.onSave()}>
          Add
        </Button>
      ) : (
        <Button variant="primary" onClick={() => props.onSave()}>
          Update
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);
