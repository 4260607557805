import React, { useState } from "react";
import "./Input.scss";

const ClickableText = React.lazy(() =>
  import("./ClickableText").then((module) => ({
    default: module.ClickableText,
  }))
);

export const AddDeleteWrapper = (props: {
  addTitle?: string;
  children: any;
  isShow?: boolean;
  needCheck?: boolean;
  onDelete?: () => void;
  resetValidation?: () => void;
}) => {
  const [isShow, setIsShow] = useState(props.isShow);

  const removeElement = () => {
    setIsShow(false);
    if (props.onDelete) props.onDelete();
  };

  const addElement = () => {
    setIsShow(true);
    if (props.resetValidation) props.resetValidation();
  };

  if (!isShow && props.needCheck) {
    return (
      <React.Suspense fallback={null}>
        <ClickableText
          onClick={addElement}
          text={props.addTitle || "Add"}
          size="sm"
        />
      </React.Suspense>
    );
  }

  return (
    <>
      {props.children}
      {props.needCheck ? (
        <React.Suspense fallback={null}>
          <ClickableText
            onClick={removeElement}
            text="Delete"
            size="sm"
            variant="danger"
          />
        </React.Suspense>
      ) : null}
    </>
  );
};
