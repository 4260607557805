import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Input } from "./Input";

export const PreviewDetails = (props: {
  onChange: (val: any) => void;
  value: any;
}) => (
  <>
    <Input
      id="top-left-corner-preview"
      label="Top Left Corner"
      onChange={(topLeftCorner) => props.onChange({ topLeftCorner })}
      pattern="^[0-9]+,[0-9]+$"
      value={props.value?.topLeftCorner}
    />
    <Input
      id="bottom-right-corner-preview"
      label="Bottom Right Corner"
      onChange={(bottomRightCorner) => props.onChange({ bottomRightCorner })}
      pattern="^[0-9]+,[0-9]+$"
      value={props.value?.bottomRightCorner}
    />
    <Form.Group as={Row}>
      <Form.Label column sm={4} className="radio-label">
        Expression Type
      </Form.Label>
      <Col sm={8}>
        <Form.Check
          inline
          checked={props.value?.expressionType === "Informative"}
          id="preview-details-expression-informative"
          type="radio"
          label="Informative"
          onChange={() => props.onChange({ expressionType: "Informative" })}
        />
        <Form.Check
          inline
          checked={props.value?.expressionType === "Instructive"}
          id="preview-details-expression-instructive"
          type="radio"
          label="Instructive"
          onChange={() => props.onChange({ expressionType: "Instructive" })}
        />
      </Col>
    </Form.Group>
  </>
);
