import React from "react";
import { Spinner as BSpinner } from "react-bootstrap";

import "./spinner.scss";

export const Spinner = (props: { isShow: boolean }) => {
  if (!props.isShow) return null;

  return (
    <div className="page-loading relative">
      <BSpinner animation="border" variant="primary" />
    </div>
  );
};
