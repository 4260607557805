import {
  transformToCamelCase,
  transformToSnakeCase,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getElementDataForUpdate = (
  resourceData: any,
  data: any,
  releaseKey?: number
) => {
  const converted = transformToSnakeCase(data);

  if (releaseKey) {
    resourceData.chapter.chapters[releaseKey] = {
      ...converted.general_data,
      additional_titles: convertAddTitlesForApi(
        converted.additional_titles || []
      ),
      chapter_ids: converted.chapter_ids || [],
      characters: converted.characters || [],
      contributors: converted.contributors || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
    };

    return resourceData;
  }

  return {
    chapter: {
      ...resourceData.chapter,
      chapters: [
        ...(resourceData.chapter.chapters || []),
        {
          ...converted.general_data,
          additional_titles: convertAddTitlesForApi(
            converted.additional_titles || []
          ),
          chapter_ids: converted.chapter_ids || [],
          characters: converted.characters || [],
          contributors: converted.contributors || [],
          display_titles: convertAddTitlesForApi(
            converted.display_titles || []
          ),
          display_title_texts: converted.display_title_texts || [],
        },
      ],
    },
  };
};

const getElementDataForSet = (data: any, releaseKey?: number) => {
  const converted = transformToCamelCase(data);

  if (releaseKey !== undefined) {
    const chapterData = converted.chapter.chapters[releaseKey];

    return {
      generalData: {
        chapterReference: chapterData.chapterReference,
        representativeImageReference: chapterData.representativeImageReference,
        duration: chapterData.duration,
        startTime: chapterData.startTime,
        endTime: chapterData.endTime,
        languageAndScriptCode: chapterData.languageAndScriptCode,
      },
      additionalTitles: convertAddTitlesForTable(
        chapterData.additionalTitles || []
      ),
      chapter_ids: chapterData.chapter_ids || [],
      characters: chapterData.characters || [],
      contributors: chapterData.contributors || [],
      displayTitles: convertAddTitlesForTable(chapterData.displayTitles || []),
      displayTitleTexts: chapterData.displayTitleTexts || [],
    };
  }

  return {};
};

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    chapter: {
      ...converted.general_data,
      chapters: (converted.chapters || []).map((el: any) => {
        delete el.id;

        return el;
      }),
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: {
      languageAndScriptCode: converted.chapter.languageAndScriptCode,
    },
    chapters: (converted.chapter.chapters || []).map((el: any, i: number) => ({
      ...el,
      id: i,
    })),
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
  getElementDataForUpdate,
  getElementDataForSet,
};
