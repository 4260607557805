import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useArray } from "../../hooks/useArray";
import { getOption } from "../../utils/parse-options";
import { TableComponent } from "../TableComponent";

interface Props {
  id?: string;
  newReleaseMessageId: string;
  onChange: (data: any) => void;
  value: any;
}

const fields = [
  "Cue Use Type",
  "Cue Origin",
  "Start Time",
  "End Time",
  "Duration",
];

export const CuesTab = (props: Props) => {
  const history = useHistory();
  const [data, , remove, , , setDefault] = useArray<any>();

  const add = () => {
    if (!props.id) return;

    history.push(
      `/ern/${props.newReleaseMessageId}/cue-sheet/${props.id}/cue/`
    );
  };

  const update = (data: any, index: number) => {
    if (!props.id) return;

    history.push(
      `/ern/${props.newReleaseMessageId}/cue-sheet/${props.id}/cue/${index}`
    );
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="cues" className="page__tab-content">
      {!props.id ? (
        <h6>Please, save changes before adding Cue</h6>
      ) : (
        <TableComponent
          add={add}
          data={data || []}
          emptyElement={{}}
          fields={fields}
          getCells={(el: any) => (
            <>
              <td>{getOption(el.cueUseType?.value || "-")}</td>
              <td>{getOption(el.cueOrigin?.value || "-")}</td>
              <td>{el.startTime || "-"}</td>
              <td>{el.endTime || "-"}</td>
              <td>{el.duration || "-"}</td>
            </>
          )}
          remove={remove}
          update={update}
        />
      )}
    </Tab.Pane>
  );
};
