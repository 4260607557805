import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { getOption } from "../../utils/parse-options";

const RelatedResource = React.lazy(() =>
  import("../RelatedResource_v.4.2").then((module) => ({
    default: module.RelatedResource,
  }))
);

interface Props {
  resourceKey?: string;
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Resource Relationship Type",
  "Resource Related Resource Reference",
  "Release Id",
];

const getRow = (el: any, key: string) => {
  const els = [];

  if (el[key]?.gRid) els.push(`GRid: ${el[key].gRid}`);
  if (el[key]?.icpn) els.push(`ICPN: ${el[key].icpn}`);
  if (el[key]?.catalogNumber?.value) {
    const namespace = el[key].catalogNumber?.namespace
      ? `(${el[key].catalogNumber.namespace})`
      : "";

    els.push(`Catalog Number: ${el[key].catalogNumber.value} ${namespace}`);
  }
  if (el[key]?.proprietaryIds?.length) {
    const ids: any[] = [];

    el[key].proprietaryIds.forEach((id: any) => {
      const n = id.namespace ? `(${id.namespace})` : "";

      ids.push(`${id.value} ${n}`);
    });

    els.push(`Proprietary IDs: ${ids.join(", ")}`);
  }

  return els.join(", ");
};

export const RelatedResourceTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();
  const key = props.resourceKey || "releaseId";

  useEffect(() => {
    if (props.resourceKey === "resourceId") fields[2] = "Resource ID";
  }, []);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.resourceKey === "resourceId") fields[2] = "Resource ID";
  }, [props.value]);

  return (
    <Tab.Pane eventKey="relatedResource" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{getOption(el.resourceRelationshipType || "")}</td>
            <td>{el.resourceRelatedResourceReference}</td>
            <td>{getRow(el, key)}</td>
          </>
        )}
        getModalContent={(
          value: any,
          onChange: (data: any) => void,
          resetValidation: () => void
        ) => (
          <React.Suspense fallback={null}>
            <RelatedResource
              value={value}
              onChange={onChange}
              resetValidation={resetValidation}
              resourceKey={key}
            />
          </React.Suspense>
        )}
        modalTitle="Related Resource"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
