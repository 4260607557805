import React from "react";
import { Button } from "react-bootstrap";
import withAuth from "../hocs/withAuth";

export const ExportBtn = withAuth(
  (props: {
    id: string;
    isAuthorized: boolean;
    toggleAuth: (cb: any) => void;
    variant: "link" | "success";
  }) => {
    const href = `${process.env.REACT_APP_API_SERVER_URL}/ern/new_release_messages/${props.id}/export`;

    const onExport = () => {
      window.open(href, "_blank");
    };

    const onClick = () => {
      if (props.isAuthorized) {
        onExport();
      } else {
        props.toggleAuth(onExport);
      }
    };

    if (props.variant === "link") {
      return (
        <Button variant="link" onClick={onClick} target="_blank">
          Export
        </Button>
      );
    }

    return (
      <Button variant={props.variant} onClick={onClick} size="sm">
        Export
      </Button>
    );
  }
);
