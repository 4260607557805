import React from "react";
import { Form } from "react-bootstrap";
import { Input } from "./Input";
import { MultiInput } from "./MultiInput";
import { TerritorySelect } from "./TerritorySelect";

interface Props {
  checkLabel?: string;
  id: string;
  isTerritory?: boolean;
  eventDate: any;
  keyName: string;
  label: string;
  updateEventDate: (event: any) => void;
}

export const FulfillmentDateWithFulfillment = ({
  checkLabel,
  id,
  eventDate,
  keyName,
  label,
  updateEventDate,
}: Props) => {
  return (
    <div className="form-group event-date">
      <Input
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}|[0-9]{4}-[0-9]{2}|[0-9]{4}"
        invalidFeedback="Date must be valid in the form YYYY, YYYY-MM or YYYY-MM-DD"
        id={`${id}-eventDateDate`}
        label={label}
        value={eventDate.fulfillmentDate}
        onChange={(update: string) =>
          updateEventDate({ fulfillmentDate: update })
        }
      />
      <Form.Group>
        <Form.Check
          checked={eventDate[keyName] || false}
          id={`${id}-eventDateApproximate`}
          type="checkbox"
          label={checkLabel || "Is Default?"}
          onChange={() => updateEventDate({ [keyName]: !eventDate[keyName] })}
        />
      </Form.Group>
      <MultiInput
        data={eventDate || {}}
        dataKey="resource_release_references"
        id="resource_release_references"
        label="Resource Release References"
        update={updateEventDate}
      />
      <TerritorySelect
        id="event-date-territory-select"
        value={{
          label: eventDate.applicableTerritoryCode || "",
          value: eventDate.applicableTerritoryCode || "",
        }}
        label="Applicable Territory Code"
        onChange={(val) =>
          updateEventDate({
            applicableTerritoryCode: val.value,
          })
        }
      />
    </div>
  );
};
