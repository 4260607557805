import React from "react";
import { Input } from "./Input";

export const IntegerInput = (props: {
  id: string;
  label: string;
  onChange: (val: string) => void;
  value?: number;
}) => (
  <Input
    id={props.id}
    label={props.label}
    onChange={props.onChange}
    step="1"
    type="number"
    value={props.value || ""}
  />
);
