import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { CompositeMusicalWorkType } from "../CompositeMusicalWorkType";
import { Input } from "../Input";
import { InputDuration } from "../InputDuration";
import { LanguageCodes } from "../LanguageCodes";
import { LanguageScriptField } from "../LanguageField";
import { RecordingMode } from "../RecordingMode";
import { UserDefinedSelect } from "../UserDefinedSelect";
import { getOption } from "../../utils/parse-options";
import types from "../../data/general-music-type.json";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="resourceReference"
          label="Resource Reference"
          invalidFeedback="Please provide a valid value."
          prependText="A"
          pattern="[\d\-_a-zA-Z]+$"
          value={generalData.resourceReference || ""}
          onChange={(resourceReference) =>
            onUpdate({ ...generalData, resourceReference })
          }
        />
        <RecordingMode
          value={{
            label: getOption(generalData.audioChannelConfiguration || ""),
            value: generalData.audioChannelConfiguration || "",
          }}
          onChange={({ value }: any) =>
            onUpdate({
              ...generalData,
              audioChannelConfiguration: value,
            })
          }
        />
        <CompositeMusicalWorkType
          value={generalData.compositeMusicalWorkType}
          onChange={(value: string) =>
            onUpdate({
              ...generalData,
              compositeMusicalWorkType: value,
            })
          }
        />
        <LanguageScriptField
          idDiff="general-language-field"
          value={generalData.languageAndScriptCode || ""}
          onChange={(languageAndScriptCode) =>
            onUpdate({ ...generalData, languageAndScriptCode })
          }
        />
        <LanguageCodes
          id="sound-recording"
          onChange={(languageOfPerformances) =>
            onUpdate({ ...generalData, languageOfPerformances })
          }
          value={generalData.languageOfPerformances}
        />
        <UserDefinedSelect
          dataSelect={true}
          id="general-type-select"
          label="Type"
          onChange={(data: any) => onUpdate({ ...generalData, ...data })}
          options={types}
          value={generalData}
          valueKey="type"
        />
        <InputDuration
          value={generalData.duration || ""}
          onChange={(duration) => onUpdate({ ...generalData, duration })}
        />
        <Form.Group>
          <div>
            <Form.Check
              inline
              checked={generalData.hiResMusic || false}
              id="isHiResMusic"
              type="checkbox"
              label="Is HiRes Music"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  hiResMusic: !generalData.hiResMusic,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.instrumental || false}
              id="isInstrumental"
              type="checkbox"
              label="Is Instrumental"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  instrumental: !generalData.instrumental,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.remastered || false}
              id="isRemastered"
              type="checkbox"
              label="Is Remastered"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  remastered: !generalData.remastered,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.supplemental || false}
              id="isSupplemental"
              type="checkbox"
              label="Is Supplemental"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  supplemental: !generalData.supplemental,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.containsHiddenContent || false}
              id="containsHiddenContent"
              type="checkbox"
              label="Contains Hidden Content"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  containsHiddenContent: !generalData.containsHiddenContent,
                })
              }
            />
          </div>
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
