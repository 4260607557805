import React, { useState, useEffect, useRef } from "react";
import { Tab, Form } from "react-bootstrap";
import { Input } from "../Input";
import { LanguageScriptField } from "../LanguageField";
import { UserDefinedSelect } from "../UserDefinedSelect";
import data from "../../data/images-type.json";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="resourceReference"
          label="Resource Reference"
          invalidFeedback="Please provide a valid value."
          prependText="A"
          pattern="[\d\-_a-zA-Z]+$"
          value={generalData.resourceReference || ""}
          onChange={(resourceReference) =>
            onUpdate({ ...generalData, resourceReference })
          }
        />
        <UserDefinedSelect
          dataSelect={true}
          id="general-image-type"
          label="Type"
          onChange={(value: any) =>
            onUpdate({
              ...generalData,
              type: {
                ...(generalData.type || {}),
                ...value,
              },
            })
          }
          options={data}
          value={generalData.type || {}}
          valueKey="value"
          withNamespace
        />
        <LanguageScriptField
          idDiff="general-language-field"
          value={generalData.languageAndScriptCode || ""}
          onChange={(languageAndScriptCode) =>
            onUpdate({ ...generalData, languageAndScriptCode })
          }
        />
        <Form.Group>
          <div>
            <Form.Check
              inline
              checked={generalData.supplemental || false}
              id="supplemental"
              type="checkbox"
              label="Is Supplemental"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  supplemental: !generalData.supplemental,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.containsHiddenContent || false}
              id="containsHiddenContent"
              type="checkbox"
              label="Contains Hidden Content"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  containsHiddenContent: !generalData.containsHiddenContent,
                })
              }
            />
          </div>
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
