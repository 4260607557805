import {
  transformToCamelCase,
  transformToSnakeCase,
  convertDisplayArtistsForTable,
  convertDisplayArtistsForApi,
  convertBaseIdForTable,
  convertBaseIdForApi,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getGeneralDataForSet = (data: any) => ({
  compositeMusicalWorkType: data.compositeMusicalWorkType,
  containsHiddenContent: data.containsHiddenContent,
  cover: data.cover,
  duration: data.duration,
  instrumental: data.instrumental,
  languageAndScriptCode: data.languageAndScriptCode,
  languageOfDubbings: data.languageOfDubbings,
  languageOfPerformances: data.languageOfPerformances,
  remastered: data.remastered,
  resourceReference: data.resourceReference,
  subTitleLanguages: data.subTitleLanguages,
  supplemental: data.supplemental,
  type: data.type,
});

const getCharactersForUpdate = (data: any) =>
  data.map((el: any) => {
    const performer = Object.assign({}, el);

    delete performer.character_party_reference;
    delete performer.key;
    delete performer.id;

    return {
      character_party_reference: el.character_party_reference,
      performer,
    };
  });

const getCharactersForSet = (data: any) =>
  data.map((el: any) => ({
    characterPartyReference: el.characterPartyReference || "",
    ...(el.performer || {}),
  }));

const getVideoChapterReferenceForUpdate = (data: any) =>
  (data || []).map((el: any) => el.reference);

const getVideoChapterReferenceForSet = (data: any) =>
  (data || []).map((el: any) => ({ reference: el }));

const getVideoCueSheetReferenceForUpdate = (data: any) =>
  (data || []).map((el: any) => el.reference);

const getVideoCueSheetReferenceForSet = (data: any) =>
  (data || []).map((el: any) => ({ reference: el }));

const getDatesForUpdate = (data: any) => ({
  creation_date: data.creation_date?.value ? data.creation_date : undefined,
  mastered_date: data.mastered_date?.value ? data.mastered_date : undefined,
  remastered_date: data.remastered_date?.value
    ? data.remastered_date
    : undefined,
  first_publication_dates: (data.first_publication_dates || []).length
    ? data.first_publication_dates
    : undefined,
});

const getDatesForSet = (data: any) => ({
  creationDate: data.creationDate || {},
  masteredDate: data.masteredDate || {},
  remasteredDate: data.remasteredDate || {},
  firstPublicationDates: data.firstPublicationDates || [],
});

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    video: {
      ...converted.general_data,
      ...getDatesForUpdate(converted.dates || {}),
      additional_titles: convertAddTitlesForApi(converted.add_titles || []),
      av_ratings: converted.av_ratings,
      c_lines: converted.c_lines,
      characters: convertBaseIdForApi(
        getCharactersForUpdate(converted.characters || [])
      ),
      contributors: convertBaseIdForApi(converted.contributors || []),
      courtesy_lines: converted.courtesy_lines || [],
      display_artist_names: converted.display_artist_names || [],
      display_artists: convertDisplayArtistsForApi(
        converted.display_artists || []
      ),
      display_credits: converted.display_credits || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      parental_warning_types: converted.parental_warning_types,
      p_lines: converted.p_lines,
      ragas: converted.ragas,
      reason_for_cue_sheet_absence:
        converted.reason_for_cue_sheet_absence || {},
      related_releases: converted.related_releases || [],
      related_resources: converted.related_resources || [],
      resource_contained_resource_references:
        converted.resource_contained_resource_references || [],
      resource_ids: converted.resource_ids || [],
      resource_rights_controllers: converted.resource_rights_controllers || [],
      technical_details: converted.technical_details || [],
      version_types: converted.version_types || [],
      video_chapter_references: getVideoChapterReferenceForUpdate(
        converted.video_chapter_references
      ),
      video_cue_sheet_references: getVideoCueSheetReferenceForUpdate(
        converted.video_cue_sheet_references
      ),
      work_ids: converted.work_ids,
      work_rights_controllers: converted.work_rights_controllers,
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: getGeneralDataForSet(converted.video),
    addTitles: convertAddTitlesForTable(converted.video.additionalTitles || []),
    avRatings: converted.video.avRatings || [],
    cLines: converted.video.cLines || [],
    characters: convertBaseIdForTable(
      getCharactersForSet(converted.video.characters || [])
    ),
    contributors: convertBaseIdForTable(converted.video.contributors || []),
    courtesyLines: converted.video.courtesyLines || [],
    dates: getDatesForSet(converted.video),
    displayArtistNames: converted.video.displayArtistNames,
    displayArtists: convertDisplayArtistsForTable(
      converted.video.displayArtists || []
    ),
    displayCredits: converted.video.displayCredits,
    displayTitles: convertAddTitlesForTable(
      converted.video.displayTitles || []
    ),
    displayTitleTexts: converted.video.displayTitleTexts,
    parentalWarningTypes: converted.video.parentalWarningTypes,
    pLines: converted.video.pLines,
    ragas: converted.video.ragas,
    reasonForCueSheetAbsence: converted.video.reasonForCueSheetAbsence,
    relatedReleases: converted.video.relatedReleases,
    relatedResources: converted.video.relatedResources || [],
    resourceContainedResourceReferences:
      converted.video.resourceContainedResourceReferences,
    resourceIds: converted.video.resourceIds || [],
    resourceRightsControllers: converted.video.resourceRightsControllers || [],
    technicalDetails: converted.video.technicalDetails || [],
    versionTypes: converted.video.versionTypes || [],
    videoChapterReferences: getVideoChapterReferenceForSet(
      converted.video.videoChapterReferences
    ),
    videoCueSheetReferences: getVideoCueSheetReferenceForSet(
      converted.video.videoCueSheetReferences
    ),
    workIds: converted.video.workIds || [],
    workRightsControllers: converted.video.workRightsControllers || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
