const getData = () => ({
  method: "get",
  url: `/ern/new_release_messages`,
});

const deleteData = (newReleaseMessageId: string) => ({
  method: "delete",
  url: `/ern/new_release_messages/${newReleaseMessageId}`,
});

const getExportData = (id: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${id}/export`,
});

export default {
  getData,
  getExportData,
  deleteData,
};
