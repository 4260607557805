import React, { useState, useEffect, useRef } from "react";
import { Tab, Form } from "react-bootstrap";
import { CompositeMusicalWorkType } from "../CompositeMusicalWorkType";
import { Input } from "../Input";
import { InputDuration } from "../InputDuration";
import { LanguageCodes } from "../LanguageCodes";
import { LanguageScriptField } from "../LanguageField";
import { UserDefinedSelect } from "../UserDefinedSelect";
import data from "../../data/general-video-type.json";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="resourceReference"
          label="Resource Reference"
          invalidFeedback="Please provide a valid value."
          prependText="A"
          pattern="[\d\-_a-zA-Z]+$"
          value={generalData.resourceReference || ""}
          onChange={(resourceReference) =>
            onUpdate({ ...generalData, resourceReference })
          }
        />
        <UserDefinedSelect
          dataSelect={true}
          id="general-type-select"
          label="Type"
          onChange={(data: any) =>
            onUpdate({
              ...generalData,
              type: {
                ...(generalData.type || {}),
                ...data,
              },
            })
          }
          options={data}
          value={generalData.type}
          valueKey="value"
          withNamespace
        />
        <CompositeMusicalWorkType
          value={generalData.compositeMusicalWorkType}
          onChange={(compositeMusicalWorkType: any) =>
            onUpdate({ ...generalData, compositeMusicalWorkType })
          }
        />
        <InputDuration
          id="general-video-durarion"
          value={generalData.duration || ""}
          onChange={(duration) => onUpdate({ ...generalData, duration })}
        />
        <LanguageCodes
          id="video-performance"
          onChange={(languageOfPerformances) =>
            onUpdate({ ...generalData, languageOfPerformances })
          }
          value={generalData.languageOfPerformances}
        />
        <LanguageCodes
          id="video-dubbing"
          label="Language of Dubbing"
          onChange={(languageOfDubbings) =>
            onUpdate({ ...generalData, languageOfDubbings })
          }
          value={generalData.languageOfDubbings}
        />
        <LanguageCodes
          id="video-subtitle"
          label="Sub Title Language"
          onChange={(subTitleLanguages) =>
            onUpdate({ ...generalData, subTitleLanguages })
          }
          value={generalData.subTitleLanguages}
        />
        <LanguageScriptField
          idDiff="general-language-field"
          value={generalData.languageAndScriptCode || ""}
          onChange={(languageAndScriptCode) =>
            onUpdate({ ...generalData, languageAndScriptCode })
          }
        />
        <Form.Group>
          <div>
            <Form.Check
              inline
              checked={generalData.containsHiddenContent || false}
              id="containsHiddenContent"
              type="checkbox"
              label="Contains Hidden Content"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  containsHiddenContent: !generalData.containsHiddenContent,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.cover || false}
              id="cover"
              type="checkbox"
              label="Is Cover"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  cover: !generalData.cover,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.instrumental || false}
              id="instrumental"
              type="checkbox"
              label="Is Instrumental"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  instrumental: !generalData.instrumental,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.remastered || false}
              id="remastered"
              type="checkbox"
              label="Is Remastered"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  remastered: !generalData.remastered,
                })
              }
            />
            <Form.Check
              inline
              checked={generalData.supplemental || false}
              id="supplemental"
              type="checkbox"
              label="Is Supplemental"
              onChange={() =>
                onUpdate({
                  ...generalData,
                  supplemental: !generalData.supplemental,
                })
              }
            />
          </div>
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
