import pageConverter from "../api-converters/message-header";

const patchData = (id: string, data: any) => ({
  method: "patch",
  url: `/ern/new_release_messages/${id}/message_headers`,
  data: pageConverter.getDataForUpdate(data),
});

const postData = (id: string, data: any) => ({
  method: "post",
  url: `/ern/new_release_messages/${id}/message_headers`,
  data: pageConverter.getDataForUpdate(data),
});

const getData = (id: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${id}/message_headers`,
});

const getId = (id: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${id}`,
});

const createId = () => ({
  method: "post",
  url: "/ern/new_release_messages",
});

const updateId = (id: string) => ({
  method: "patch",
  url: `/ern/new_release_messages/${id}`,
});

export default {
  patchData,
  postData,
  getData,
  getId,
  createId,
  updateId,
};
