import {
  transformToCamelCase,
  transformToSnakeCase,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getKeyForUpdate = (type: string) => {
  switch (type) {
    case "sound-recording":
      return "sound_recording";
    case "sheet-music":
      return "sheet_music";
    case "track-release":
      return "track_release";
    default:
      return type;
  }
};

const getKeyForSet = (type: string) => {
  switch (type) {
    case "sound-recording":
      return "sound_recording";
    case "sheet-music":
      return "sheetMusic";
    case "track-release":
      return "trackRelease";
    default:
      return type;
  }
};

const getDataForUpdate = (
  resourceData: any,
  data: any,
  type: string,
  releaseKey?: number
) => {
  const key = getKeyForUpdate(type);
  const converted = transformToSnakeCase(data);

  if (releaseKey) {
    resourceData[key].related_releases[releaseKey] = {
      ...converted.general_data,
      additional_titles: convertAddTitlesForApi(
        converted.additional_titles || []
      ),
      display_artist_names: converted.display_artist_names || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      release_id: converted.release_id,
      release_label_references: converted.release_label_references || [],
    };

    return resourceData;
  }

  return {
    [key]: {
      ...resourceData[key],
      related_releases: [
        ...(resourceData[key].related_releases || []),
        {
          ...converted.general_data,
          additional_titles: convertAddTitlesForApi(
            converted.additional_titles || []
          ),
          display_artist_names: converted.display_artist_names || [],
          display_titles: convertAddTitlesForApi(
            converted.display_titles || []
          ),
          display_title_texts: converted.display_title_texts || [],
          release_id: converted.release_id,
          release_label_references: converted.release_label_references || [],
        },
      ],
    },
  };
};

const getDataForSet = (data: any, type: string, releaseKey?: number) => {
  const key = getKeyForSet(type);
  const converted = transformToCamelCase(data);

  if (releaseKey !== undefined) {
    const releaseData = converted[key].relatedReleases[releaseKey];

    return {
      generalData: {
        originalReleaseDate: releaseData.originalReleaseDate,
        releaseDate: releaseData.releaseDate,
        releaseRelationshipType: releaseData.releaseRelationshipType,
      },
      additionalTitles: convertAddTitlesForTable(releaseData.additionalTitles),
      displayArtistNames: releaseData.displayArtistNames || [],
      displayTitles: convertAddTitlesForTable(releaseData.displayTitles || []),
      displayTitleTexts: releaseData.displayTitleTexts || [],
      releaseId: releaseData.releaseId,
      releaseLabelReferences: releaseData.releaseLabelReferences || [],
    };
  }

  return {};
};

export default {
  getDataForUpdate,
  getDataForSet,
  getKeyForSet,
  getKeyForUpdate,
};
