import React from "react";
import { AddDeleteWrapper } from "./AddDeleteWrapper";

const Input = React.lazy(() =>
  import("./Input").then((module) => ({
    default: module.Input,
  }))
);

const HashSum = React.lazy(() =>
  import("./HashSum").then((module) => ({
    default: module.HashSum,
  }))
);

export const File = (props: {
  id: string;
  isShow?: boolean;
  needCheck: boolean;
  onChange: (val: any) => void;
  onDelete?: () => void;
  resetValidation?: () => void;
  skipDataType?: boolean;
  value: any;
}) => (
  <AddDeleteWrapper
    isShow={props.isShow}
    needCheck={props.needCheck}
    onDelete={props.onDelete}
    resetValidation={props.resetValidation}
  >
    <React.Suspense fallback={null}>
      <Input
        id={`${props.id}-file`}
        label="URI"
        invalidFeedback="Please provide a valid value."
        onChange={(uri) => props.onChange({ uri })}
        value={props.value.uri}
      />
    </React.Suspense>
    <React.Suspense fallback={null}>
      <HashSum
        id={props.id}
        skipDataType={props.skipDataType}
        value={props.value.hashSum || {}}
        onChange={(val) =>
          props.onChange({
            hashSum: {
              ...props.value.hashSum,
              ...val,
            },
          })
        }
      />
    </React.Suspense>
    <React.Suspense fallback={null}>
      <Input
        id={`${props.id}-file-size`}
        label="File Size"
        min={0}
        onChange={(fileSize) => props.onChange({ fileSize })}
        type="number"
        value={props.value.fileSize}
      />
    </React.Suspense>
  </AddDeleteWrapper>
);
