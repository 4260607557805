import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import "./FixRequiredSelect.scss";

const noop = () => {
  // no operation (do nothing real quick)
};

/*
 * This is used to add required to react-select. @see https://github.com/JedWatson/react-select/issues/3140,
 * https://github.com/JedWatson/react-select/issues/4327 and
 * https://codesandbox.io/s/react-select-v2-required-input-3xvvb?file=/src/App.js
 */
class FixRequiredSelect extends React.Component {
  state = {
    isOpen: false,
    value: this.props.value || "",
  };

  selectRef = null;
  setSelectRef = (ref) => {
    this.selectRef = ref;
  };

  onChange = (value, actionMeta) => {
    const isOpen = this.props.isMulti && actionMeta?.action !== "clear";

    this.props.onChange(value, actionMeta);
    this.setState({ value, isOpen });
  };

  onBlur = () => {
    this.setState({ isOpen: false });
  };

  onFocus = () => {
    this.setState({ isOpen: true });
  };

  getValue = () => {
    if (this.props.value != undefined) return this.props.value;
    return this.state.value || "";
  };

  render() {
    const { SelectComponent, ...props } = this.props;

    return (
      <div style={{ position: "relative" }}>
        {!this.props.isDisabled && (
          <Form.Control
            id={props.id}
            tabIndex={-1}
            autoComplete="off"
            className="input-element"
            style={{
              width: "100%",
              height: "100%",
              zIndex: 1,
              background: "transparent",
              position: "absolute",
              top: 0,
              borderRadius: "4px",
              paddingBottom: "9px",
              color: "transparent",
            }}
            value={this.getValue()}
            onChange={noop}
            onFocus={() => this.selectRef.focus()}
          />
        )}
        <SelectComponent
          {...props}
          styles={{
            indicatorsContainer: (provided) => ({
              ...provided,
              position: "relative",
              zIndex: 3,
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: 5,
            }),
            valueContainer: (provided) => ({
              ...provided,
              zIndex: 4,
            }),
          }}
          menuIsOpen={this.state.isOpen}
          ref={this.setSelectRef}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          isClearable={true}
        />
      </div>
    );
  }
}

FixRequiredSelect.defaultProps = {
  onChange: noop,
};

FixRequiredSelect.protoTypes = {
  selectComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default FixRequiredSelect;
