import React from "react";
import { useLocation } from "react-router-dom";

export const NotFound = () => {
  const location = useLocation();

  return (
    <>
      <h2>Not Found</h2>
      <br />
      <h5>
        The page at
        <code> {location.pathname} </code>
        cannot be found
      </h5>
    </>
  );
};
