import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { getOption } from "../../utils/parse-options";

const ResourceContainedResourceReference = React.lazy(() =>
  import("../ResourceContainedResourceReference").then((module) => ({
    default: module.ResourceContainedResourceReference,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Resource Contained Resource",
  "Duration Used",
  "Start Point",
  "Purpose",
  "User Defined Value",
  "Namespace",
];

export const ResourceContainedResourceReferenceTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="resourceContained" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.resourceContainedResourceReference}</td>
            <td>{el.durationUsed || ""}</td>
            <td>{el.startPoint || ""}</td>
            <td>{getOption(el.purpose?.value || "")}</td>
            <td>{el.purpose?.userDefinedValue || ""}</td>
            <td>{el.purpose?.namespace || ""}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <ResourceContainedResourceReference
              onChange={onChange}
              value={value}
            />
          </React.Suspense>
        )}
        modalTitle="Resource Contained Resource Reference"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
