import React from "react";
import { Col, Row } from "react-bootstrap";
import { Input } from "./Input";
import { IntegerInput } from "./IntegerInput";
import { InputIsDefault } from "./InputIsDefault";
import { File } from "./File";
import { Fingerprint } from "./Fingerprint";
import { LanguageScriptField } from "./LanguageField";
import { PreviewDetails } from "./PreviewDetails";
import { SheetMusicCodecType } from "./SheetMusicCodecType";
import { Spacer } from "./Spacer";
import { TerritorySelect } from "./TerritorySelect";
import { TableComponent } from "./TableComponent";

interface Props {
  id: string;
  value: any;
  onChange: (updates: any) => void;
  resetValidation: () => void;
}

export const TechnicalDetailsSheetMusic = (props: Props) => {
  const addElement = (t: any) => {
    const copy = [...(props.value?.fingerprints || [])];
    copy.push(t);
    props.onChange({ fingerprints: copy });
  };

  const updateElement = (t: any, index: number) => {
    const copy = [...props.value.fingerprints];
    copy[index] = t;
    props.onChange({ fingerprints: copy });
  };

  const removeElement = (index: number) => {
    const copy = [...props.value.fingerprints];
    copy.splice(index, 1);
    props.onChange({ fingerprints: copy });
  };

  return (
    <>
      <Input
        id="tech-res-reference"
        label="Technical Resource Details Reference"
        onChange={(val) =>
          props.onChange({ technicalResourceDetailsReference: val })
        }
        pattern="[-\d_a-zA-Z]+"
        prependText="T"
        value={props.value.technicalResourceDetailsReference}
      />
      <Spacer />
      <h5>Sheet Music Codec Type</h5>
      <br />
      <SheetMusicCodecType onChange={props.onChange} value={props.value} />
      <Spacer />
      <IntegerInput
        id="bits-depth"
        label="Bit Depth"
        onChange={(bitDeps) => props.onChange({ bitDeps })}
        value={props.value.bitDeps}
      />
      <LanguageScriptField
        idDiff="language-and-script-code"
        label="Language And Script Code"
        onChange={(languageAndScriptCode: any) =>
          props.onChange({ languageAndScriptCode })
        }
        value={props.value.languageAndScriptCode}
      />
      <TerritorySelect
        id="territory-select"
        label="Applicable Territory Code"
        onChange={(change) =>
          props.onChange({
            applicableTerritoryCode: change.value,
          })
        }
        value={{
          label: props.value.applicableTerritoryCode,
          value: props.value.applicableTerritoryCode,
        }}
      />
      <Row>
        <Col sm={2}>
          <InputIsDefault
            id={`${props.id}-is-preview`}
            label="Is Preview"
            value={props.value.preview}
            onChange={() => props.onChange({ preview: !props.value.preview })}
          />
        </Col>
        <Col sm={2}>
          <InputIsDefault
            id={`${props.id}-is-default`}
            label="Is Default"
            value={props.value.default}
            onChange={() => props.onChange({ default: !props.value.default })}
          />
        </Col>
        <Col sm={4}>
          <InputIsDefault
            id={`${props.id}-is-provided`}
            label="Is Provided In Delivery"
            value={props.value.providedInDelivery}
            onChange={() =>
              props.onChange({
                providedInDelivery: !props.value.providedInDelivery,
              })
            }
          />
        </Col>
      </Row>
      <Spacer />
      <h5>Preview Details</h5>
      <br />
      <PreviewDetails
        onChange={(val) =>
          props.onChange({
            previewDetails: {
              ...props.value.previewDetails,
              ...val,
            },
          })
        }
        value={props.value.previewDetails}
      />
      <Spacer />
      <h5>File</h5>
      <File
        id={`${props.id}-file`}
        isShow={!!props.value?.file?.hashSum?.algorithm}
        needCheck={true}
        value={props.value.file || {}}
        onChange={(val) =>
          props.onChange({
            file: {
              ...props.value.file,
              ...val,
            },
          })
        }
        onDelete={() => props.onChange({ file: {} })}
        resetValidation={props.resetValidation}
      />
      <Spacer />
      <h5>Fingerprint</h5>
      <TableComponent
        add={addElement}
        addTitle="Add"
        backdropClassName="top-overlay"
        data={props.value.fingerprints || []}
        emptyElement={{}}
        fields={["Algorithm", "Version"]}
        getCells={(el: any) => (
          <>
            <td>{el.algorithm?.value || ""}</td>
            <td>{el.version}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <Fingerprint
            value={value}
            onChange={onChange}
            resetValidation={props.resetValidation}
          />
        )}
        modalTitle="Fingerprint"
        remove={removeElement}
        update={updateElement}
      />
    </>
  );
};
