import React, { useEffect, useState, useRef } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import { GeneralTab } from "../../components/tabs-track-release/GeneralTab";
import { AdditionalTitleTab } from "../../components/tables/AdditionalTitleTab";
import { DisplayTitleTab } from "../../components/tables/DisplayTitleTab";
import { DisplayTitleTextTab } from "../../components/tables/DisplayTitleTextTab";
import { GenreTab } from "../../components/tabs-track-release/GenreTab";
import { KeywordsTab } from "../../components/tables/KeywordsTab";
import { LinkedReleaseResourceReferenceTab } from "../../components/tabs-track-release/LinkedReleaseResourceReferenceTab";
import { MarketingCommentsTab } from "../../components/tabs-release/MarketingCommentsTab";
import { RelatedReleaseTab } from "../../components/tables/RelatedReleaseTab";
import { RelatedResourceTab } from "../../components/tables/RelatedResourceTab";
import { ReleaseIdTab } from "../../components/tables/ReleaseIdTab";
import { ReleaseLabelReferenceTab } from "../../components/tables/ReleaseLabelReferenceTab";
import { SynopsisTab } from "../../components/tables/SynopsisTab";
import { Resources as Context, Value_Release } from "../../contexts/resource";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/track-release";
import pageConverter from "../../api-converters/track-release";

import "../page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "additionalTitle", title: "Additional Title" },
  { id: "displayTitle", title: "Display Title" },
  { id: "displayTitleText", title: "Display Title Text" },
  { id: "genres", title: "Genres" },
  { id: "keywords", title: "Keywords" },
  {
    id: "linkedReference",
    title: "Linked Release Resource References",
  },
  { id: "marketing_comments", title: "Marketing Comments" },
  { id: "relatedRelease", title: "Related Release" },
  { id: "relatedResource", title: "Related Resource" },
  { id: "releaseId", title: "Release ID" },
  { id: "releaseLabelReference", title: "Release Label References" },
  { id: "synopsis", title: "Synopsises" },
];

export const TrackRelease = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        id?: string;
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
    setSuccessAlertData: (method: string) => void;
  }) => {
    const id = props.match?.params?.id;
    const history = useHistory();
    const timeout: any = useRef(null);
    const validStatuses: any = useRef({
      general: undefined,
    });

    const maxTabWidth = 155;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState<any>({});

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const updateData = (data: any) => {
      setUpdatedData(data);
      setValidated(false);
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getDataForSet(data);

      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchData(
            id,
            props.match.params.newReleaseMessageId,
            data
          ),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      } else {
        props.api({
          ...pageApi.postData(props.match.params.newReleaseMessageId, data),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);

            history.push(
              `/ern/${props.match.params.newReleaseMessageId}/release-list/track-release/${data.id}`,
              { showSuccessAlert: true }
            );
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      }
    };

    const submit = () => {
      setValidated(true);
      setIsValid({});

      timeout.current = setTimeout(() => {
        const allKeys = Object.keys(validStatuses.current);
        const validKeys = allKeys.filter(
          (el: any) => validStatuses.current[el]
        ).length;

        if (validKeys === allKeys.length) {
          setValidated(false);
          setDataSaving(true);
          saveData(updatedData);
        } else {
          props.setAlertData(
            ["Please correct the errors in the highlighted tabs and try again"],
            0
          );
          setDataSaving(false);
        }

        setIsValid(validStatuses.current);
      }, 1000);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(id, props.match?.params?.newReleaseMessageId),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }

      if (props.history?.location?.state?.showSuccessAlert) {
        props.setSuccessAlertData("post");
      }

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, []);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="page">
          <h2 className="page__title">{id ? "Edit" : "Add"} Track Release</h2>
          <Context.Provider value={Value_Release}>
            <Tab.Container defaultActiveKey="general">
              <Col
                sm={12}
                className="page__tab-links-container"
                ref={tabsRef}
                style={{
                  width: `${tabsWidth}px`,
                  overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
                }}
              >
                <Nav
                  fill
                  variant="tabs"
                  style={{
                    width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
                  }}
                >
                  {tabs.map((el: any) => (
                    <Nav.Item key={el.id}>
                      <Nav.Link
                        eventKey={el.id}
                        className={
                          isValid[el.id] === false && validated
                            ? "error-tab"
                            : ""
                        }
                      >
                        {el.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <GeneralTab
                    onChange={(generalData: any) => {
                      updateData({
                        ...updatedData,
                        generalData: {
                          ...(updatedData.generalData || {}),
                          ...generalData,
                        },
                      });
                    }}
                    value={baseData.generalData}
                    setValidStatus={(general: boolean) => {
                      validStatuses.current.general = general;
                    }}
                    validated={validated}
                  />
                  <AdditionalTitleTab
                    onChange={(addTitles: any) => {
                      updateData({
                        ...updatedData,
                        addTitles,
                      });
                    }}
                    value={baseData.addTitles}
                  />
                  <DisplayTitleTab
                    onChange={(displayTitles: any) => {
                      updateData({
                        ...updatedData,
                        displayTitles,
                      });
                    }}
                    value={baseData.displayTitles}
                  />
                  <DisplayTitleTextTab
                    onChange={(displayTitleTexts: any) => {
                      updateData({
                        ...updatedData,
                        displayTitleTexts,
                      });
                    }}
                    value={baseData.displayTitleTexts}
                  />
                  <GenreTab
                    onChange={(genres: any) => {
                      updateData({
                        ...updatedData,
                        genres,
                      });
                    }}
                    value={baseData.genres}
                  />
                  <KeywordsTab
                    onChange={(keywords: any) => {
                      updateData({
                        ...updatedData,
                        keywords,
                      });
                    }}
                    value={baseData.keywords}
                  />
                  <LinkedReleaseResourceReferenceTab
                    onChange={(linkedReleaseResourceReferences: any) => {
                      updateData({
                        ...updatedData,
                        linkedReleaseResourceReferences,
                      });
                    }}
                    value={baseData.linkedReleaseResourceReferences}
                  />
                  <MarketingCommentsTab
                    onChange={(marketingComments: any) => {
                      updateData({
                        ...updatedData,
                        marketingComments,
                      });
                    }}
                    value={baseData.marketingComments}
                  />
                  <RelatedReleaseTab
                    id={id}
                    newReleaseMessageId={
                      props.match?.params?.newReleaseMessageId
                    }
                    onChange={(relatedReleases: any) =>
                      updateData({
                        ...updatedData,
                        relatedReleases,
                      })
                    }
                    type="track-release"
                    value={baseData.relatedReleases}
                  />
                  <RelatedResourceTab
                    resourceKey="resourceId"
                    onChange={(relatedResources: any) => {
                      updateData({
                        ...updatedData,
                        relatedResources,
                      });
                    }}
                    value={baseData.relatedResources}
                  />
                  <ReleaseIdTab
                    onChange={(releaseId: any) => {
                      updateData({
                        ...updatedData,
                        releaseId,
                      });
                    }}
                    setValidStatus={(releaseId: boolean) => {
                      validStatuses.current.releaseId = releaseId;
                    }}
                    type="release"
                    validated={validated}
                    value={baseData.releaseId}
                  />
                  <ReleaseLabelReferenceTab
                    onChange={(releaseLabelReferences: any) =>
                      updateData({
                        ...updatedData,
                        releaseLabelReferences,
                      })
                    }
                    value={baseData.releaseLabelReferences}
                  />
                  <SynopsisTab
                    onChange={(synopsises: any) =>
                      updateData({
                        ...updatedData,
                        synopsises,
                      })
                    }
                    value={baseData.synopsises}
                  />
                </Tab.Content>
              </Col>
            </Tab.Container>
            <div className="page__btn-container">
              <SpinnerBtn
                disabled={getIsEqual() || dataSaving}
                isLoading={dataSaving}
                onClick={submit}
                text={id ? "Update" : "Save"}
              />
            </div>
          </Context.Provider>
        </div>
      </DndProvider>
    );
  }
);
