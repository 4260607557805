import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

export const Home = () => {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) history.push("/ern");
  }, []);

  return (
    <>
      <h3>Create and Export DDEX XML Metadata</h3>
      <br />
      <Row>
        <Col sm={9}>
          <Button
            variant="outline-primary"
            size="sm"
            href="/ern/new"
            onClick={(e) => {
              e.preventDefault();
              history.push("/ern/new");
            }}
          >
            Electronic Release Notification (ERN)
          </Button>
        </Col>
      </Row>
    </>
  );
};
