import React, { useState } from "react";
import FixRequiredSelect from "./FixRequiredSelect";
import BaseSelect from "react-select";
import { Col, Form, Row } from "react-bootstrap";

export interface Option {
  value: string;
  label: string;
  options?: Option[];
}

interface Props {
  id: string;
  inputWidth?: number;
  isMulti?: boolean;
  label?: string;
  labelWidth?: number;
  options: Option[];
  onChoose: (option: Option | null) => void;
  includeGroupNameInSearch?: boolean;
  value?: Option | null;
}

const buildMapFromOptionToGroup = (groups: Option[]) => {
  const map = new Map();
  groups.forEach((group) => {
    if (group.options) {
      group.options.forEach((option) => {
        map.set(option.value, group);
      });
    }
  });
  return map;
};

export const Select = (props: Props) => {
  const [myMap] = useState(buildMapFromOptionToGroup(props.options));

  const filterOption = (option: Option, q: string) => {
    if (!q) return true;
    const query = q.toLowerCase();

    if (option.label.includes(query) || option.value.includes(query)) {
      return true;
    }

    if (
      myMap.has(option.label) &&
      myMap.get(option.label).label.toLowerCase().includes(query)
    ) {
      return true;
    }

    return false;
  };

  const getSelect = () => (
    <FixRequiredSelect
      SelectComponent={BaseSelect}
      id={props.id}
      options={props.options}
      onChange={(option: Option) => {
        props.onChoose(option);
      }}
      filterOption={props.includeGroupNameInSearch ? filterOption : undefined}
      isMulti={props.isMulti}
      value={props.value}
    />
  );

  if (!props.label) return getSelect();

  return (
    <Form.Group as={Row}>
      <Form.Label column sm={props.labelWidth || 4} htmlFor={props.id}>
        {props.label}
      </Form.Label>
      <Col sm={props.inputWidth || 8}>{getSelect()}</Col>
    </Form.Group>
  );
};
