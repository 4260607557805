import React, { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";

const TrackReleaseVisibility = React.lazy(() =>
  import("../TrackReleaseVisibility").then((module) => ({
    default: module.TrackReleaseVisibility,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

export const TrackReleaseVisibilityTab = (props: Props) => {
  const [data, setData] = useState<any>({});

  const onUpdate = (data: any) => {
    setData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane
      eventKey="track_release_visibilities"
      className="page__tab-content"
    >
      <React.Suspense fallback={null}>
        <TrackReleaseVisibility onChange={onUpdate} value={data} />
      </React.Suspense>
    </Tab.Pane>
  );
};
