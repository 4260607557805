export const getOption = (el: string) => {
  if (typeof el !== "string") return "";

  return el
    .replace(/([a-z])([A-Z0-9])/g, "$1 $2")
    .replace(/([A-Z])([0-9])/g, "$1 $2");
};

export default (data: string[]) =>
  data.map((el: string) => ({
    label: getOption(el),
    value: el,
  }));
