import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { EventDateWithoutFlags } from "../EventDateWithoutFlags";
import { Spacer } from "../Spacer";
import { UserDefinedSelect } from "../UserDefinedSelect";
import options from "../../data/release-releationship-type.json";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (isValid: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [data, setData] = useState<any>({});

  const onUpdate = (data: any) => {
    setData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated && formRef.current) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, props.value]);

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <UserDefinedSelect
          dataSelect={true}
          id="release-relationship-type"
          label="Release Relationship Type"
          onChange={(value: any) =>
            onUpdate({
              ...data,
              releaseRelationshipType: {
                ...data.releaseRelationshipType,
                ...value,
              },
            })
          }
          options={options}
          value={data.releaseRelationshipType}
          valueKey="value"
          withNamespace
        />
        <Spacer />
        <EventDateWithoutFlags
          id="release-date"
          eventDate={data.releaseDate || {}}
          keyName="approximate"
          label="Release Date"
          updateEventDate={(val: any) =>
            onUpdate({
              ...(data || {}),
              releaseDate: val,
            })
          }
        />
        <Spacer />
        <EventDateWithoutFlags
          id="original-release-date"
          eventDate={data.originalReleaseDate || {}}
          keyName="approximate"
          label="Original Release Date"
          updateEventDate={(val: any) =>
            onUpdate({
              ...(data || {}),
              originalReleaseDate: val,
            })
          }
        />
      </Form>
    </Tab.Pane>
  );
};
