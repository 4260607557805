import React from "react";
import { Select } from "./Select";
import data from "../data/language-codes-iso-639.json";
import _ from "lodash";

interface Props {
  id: string;
  label?: string;
  value?: any;
  onChange: (value: any) => void;
}

const options = data.map((el: any) => ({ label: el.name, value: el.code }));

export const LanguageCodes = (props: Props) => (
  <Select
    id={`language-codes-${props.id}`}
    options={options}
    isMulti
    label={props.label || "Language of Performance"}
    value={(props.value || []).map((code: string) => ({
      label: _.find(data, (el: any) => el.code === code)?.name,
      value: code,
    }))}
    onChoose={(data: any) =>
      props.onChange((data || []).map((el: any) => el.value))
    }
  />
);
