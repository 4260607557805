import {
  transformToCamelCase,
  transformToSnakeCase,
  convertDisplayArtistsForTable,
  convertDisplayArtistsForApi,
  convertBaseIdForTable,
  convertBaseIdForApi,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getGeneralDataForUpdate = (data: any) => ({
  ...(data || {}),
  ...(data?.type ? { type: { value: data.type } } : {}),
});

const getGeneralDataForSet = (data: any) => ({
  ...(data || {}),
  ...(data?.type?.value ? { type: data.type.value } : {}),
});

const getAudioChapterDataForUpdate = (data: any) =>
  data.map((el: any) => el.audio_chapter_reference);

const getAudioChapterDataForSet = (data: any) =>
  data.map((el: string) => ({ audioChapterReference: el }));

const getCharactersForUpdate = (data: any) =>
  data.map((el: any) => {
    const performer = Object.assign({}, el);

    delete performer.character_party_reference;
    delete performer.key;
    delete performer.id;

    return {
      character_party_reference: el.character_party_reference,
      performer,
    };
  });

const getCharactersForSet = (data: any) =>
  data.map((el: any) => ({
    characterPartyReference: el.characterPartyReference || "",
    ...(el.performer || {}),
  }));

const getDatesForUpdate = (data: any) => ({
  creation_date: data.creation_date?.value ? data.creation_date : undefined,
  mastered_date: data.mastered_date?.value ? data.mastered_date : undefined,
  remastered_date: data.remastered_date?.value
    ? data.remastered_date
    : undefined,
  first_publication_dates: (data.first_publication_dates || []).length
    ? data.first_publication_dates
    : undefined,
});

const getDatesForSet = (data: any) => ({
  creationDate: data.creationDate || {},
  masteredDate: data.masteredDate || {},
  remasteredDate: data.remasteredDate || {},
  firstPublicationDates: data.firstPublicationDates || [],
});

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    sound_recording: {
      ...getGeneralDataForUpdate(converted.general_data),
      ...getDatesForUpdate(converted.dates || {}),
      additional_titles: convertAddTitlesForApi(converted.add_titles || []),
      audio_chapter_references: getAudioChapterDataForUpdate(
        converted.audio_chapter_reference || []
      ),
      characters: convertBaseIdForApi(
        getCharactersForUpdate(converted.characters || [])
      ),
      contributors: convertBaseIdForApi(converted.contributors || []),
      courtesy_lines: converted.courtesy_lines || [],
      display_artists: convertDisplayArtistsForApi(
        converted.display_artists || []
      ),
      display_artist_names: converted.display_artist_names || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      parental_warning_types: converted.parental_warning_types,
      p_lines: converted.p_lines,
      ragas: converted.ragas,
      related_releases: converted.related_releases || [],
      related_resources: converted.related_resources || [],
      resource_ids: converted.resource_ids || [],
      resource_rights_controllers: converted.resource_rights_controllers || [],
      technical_details: converted.technical_details || [],
      version_types: converted.version_types || [],
      work_ids: converted.work_ids || [],
      work_rights_controllers: converted.work_rights_controllers || [],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: getGeneralDataForSet(converted.soundRecording),
    addTitles: convertAddTitlesForTable(
      converted.soundRecording.additionalTitles || []
    ),
    audioChapterReference: getAudioChapterDataForSet(
      converted.soundRecording.audioChapterReferences || []
    ),
    characters: convertBaseIdForTable(
      getCharactersForSet(converted.soundRecording.characters || [])
    ),
    contributors: convertBaseIdForTable(
      converted.soundRecording.contributors || []
    ),
    courtesyLines: converted.soundRecording.courtesyLines || [],
    dates: getDatesForSet(converted.soundRecording),
    displayArtists: convertDisplayArtistsForTable(
      converted.soundRecording.displayArtists || []
    ),
    displayArtistNames: converted.soundRecording.displayArtistNames,
    displayTitles: convertAddTitlesForTable(
      converted.soundRecording.displayTitles || []
    ),
    displayTitleTexts: converted.soundRecording.displayTitleTexts,
    parentalWarningTypes: converted.soundRecording.parentalWarningTypes,
    pLines: converted.soundRecording.pLines,
    ragas: converted.soundRecording.ragas,
    relatedReleases: converted.soundRecording.relatedReleases,
    relatedResources: converted.soundRecording.relatedResources || [],
    resourceIds: converted.soundRecording.resourceIds || [],
    resourceRightsControllers:
      converted.soundRecording.resourceRightsControllers || [],
    technicalDetails: converted.soundRecording.technicalDetails || [],
    versionTypes: converted.soundRecording.versionTypes || [],
    workIds: converted.soundRecording.workIds || [],
    workRightsControllers: converted.soundRecording.workRightsControllers || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
