import React, { useCallback, useEffect, useRef } from "react";
import { InputGroup, Form, Row, Col } from "react-bootstrap";

import "./MultiInput.scss";

interface Props {
  data: any;
  dataKey: string;
  id: string;
  inputWidth?: number;
  label: string;
  labelWidth?: number;
  prependText?: string;
  update: (value: string[]) => void;
}

export const MultiInput = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback(() => {
    const value = inputRef.current?.value || "";
    const trimmed = value.trim();

    if (trimmed) {
      if (inputRef.current) inputRef.current.value = "";

      const data = [...(props.data[props.dataKey] || [])];

      data.push(`${props.prependText || ""}${trimmed}`);
      props.update({
        ...props.data,
        [props.dataKey]: data,
      });
    }
  }, [props.data]);

  const onRemove = useCallback(
    (index?: number) => {
      const data = [...props.data[props.dataKey]];
      const removeIndex = index !== undefined ? index : data.length - 1;

      data.splice(removeIndex, 1);
      props.update({
        ...props.data,
        [props.dataKey]: data,
      });
    },
    [props.data]
  );

  const onEnter = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onChange();
      }
    },
    [props.data]
  );

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.addEventListener("focusout", onChange);
    }

    return () => {
      if (inputRef.current !== null) {
        inputRef.current.removeEventListener("focusout", onChange);
      }
    };
  }, [props.data, inputRef.current]);

  return (
    <React.Fragment key={props.id}>
      <Form.Group as={Row}>
        <Form.Label column sm={props.labelWidth || 4} htmlFor={props.id}>
          {props.label}
        </Form.Label>
        <Col sm={props.inputWidth || 8} className="multi-input-container">
          <div className="multi-input">
            {(props.data[props.dataKey] || []).map(
              (el: string, index: number) => (
                <div className="multi-element" key={index}>
                  <span className="multi-element-text">{el}</span>
                  <span
                    className="multi-element-remove"
                    onClick={() => onRemove(index)}
                  >
                    &#x2715;
                  </span>
                </div>
              )
            )}
          </div>
          <InputGroup>
            {props.prependText ? (
              <InputGroup.Prepend>
                <InputGroup.Text>{props.prependText}</InputGroup.Text>
              </InputGroup.Prepend>
            ) : null}
            <Form.Control
              id={props.id}
              ref={inputRef}
              type="text"
              onKeyPress={onEnter}
            />
          </InputGroup>
          <Form.Text className="text-muted">Press enter to add</Form.Text>
        </Col>
      </Form.Group>
    </React.Fragment>
  );
};
