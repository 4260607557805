import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TechnicalDetailsSoftware } from "../TechnicalDetailsSoftware";
import { TableComponent } from "../TableComponent";
import { getHashSum, getAlgorithmLabel } from "../../utils/hash-sum-value";
import { getOption } from "../../utils/parse-options";

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = ["Technical Video Reference", "File", "Hash Sum"];

export const TechnicalDetailsTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Tab.Pane eventKey="technicalDetails" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.technicalResourceDetailsReference}</td>
            <td>{el.file?.uri || ""}</td>
            <td>
              {getHashSum(el.file?.hashSum?.hashSumValue)}
              <br />
              {getAlgorithmLabel(getOption(el.file?.hashSum?.algorithm || ""))}
            </td>
          </>
        )}
        getModalContent={(
          value: any,
          onChange: (data: any) => void,
          resetValidation: () => void
        ) => (
          <TechnicalDetailsSoftware
            id="technical-detail-modal"
            onChange={onChange}
            resetValidation={resetValidation}
            value={value}
          />
        )}
        modalTitle="Technical Details"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
