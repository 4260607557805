import React, { useEffect, useState, useRef } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useHistory } from "react-router-dom";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import { GeneralTab } from "../../components/tabs-cue-sheet/CueGeneralTab";
import { AdditionalTitleTab } from "../../components/tables/AdditionalTitleTab";
import { CLineTab } from "../../components/tabs-cue-sheet/CLineTab";
import { ContributorTab } from "../../components/tables/ContributorTab";
import { DisplayTitleTab } from "../../components/tables/DisplayTitleTab";
import { DisplayTitleTextTab } from "../../components/tables/DisplayTitleTextTab";
import { PLineTab } from "../../components/tables/PLineTab";
import { ResourceIdTab } from "../../components/tabs-cue-sheet/ResourceIdTab";
import { WorkIdTab } from "../../components/tabs-cue-sheet/WorkIdTab";
import { Resources as Context, Value_Cue } from "../../contexts/resource";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/cue-sheet";
import pageConverter from "../../api-converters/cue-sheet";

import "../page.scss";

const tabs = [
  { id: "general", title: "General" },
  { id: "additionalTitle", title: "Additional Title" },
  { id: "cline", title: "CLine" },
  { id: "contributors", title: "Contributor" },
  { id: "displayTitle", title: "Display Title" },
  { id: "displayTitleText", title: "Display Title Text" },
  { id: "pline", title: "PLine" },
  { id: "resourceid", title: "Resource ID" },
  { id: "workid", title: "Work ID" },
];

export const Cue = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        id?: string;
        key?: number;
        newReleaseMessageId: string;
        type: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
  }) => {
    const id = props.match?.params?.id;
    const timeout: any = useRef(null);
    const history = useHistory();
    const validStatuses: any = useRef({
      general: undefined,
      resourceId: undefined,
      workId: undefined,
    });

    const maxTabWidth = 150;
    const linksWidth = tabs.length * maxTabWidth;
    const tabsRef = useRef(null);
    const [tabsWidth, setTabsWidth] = useState(0);
    const [resourceData, setResourceData] = useState<any>({});
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState<any>({});

    const updateData = (data: any) => {
      setUpdatedData(data);
      setValidated(false);
    };

    const setTabsContainerWidth = () => {
      if (tabsRef.current) {
        setTabsWidth(document.body.clientWidth - 340);
      }
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getCueDataForSet(
        data,
        props.match?.params?.key
      );

      setResourceData(data);
      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchCueData(
            id,
            props.match.params.newReleaseMessageId,
            resourceData,
            data,
            props.match?.params?.key
          ),
          successCb: () => {
            if (!props.match?.params?.key) {
              const data = resourceData.cue_sheet.cues || [];

              history.replace(
                `/ern/${props.match.params.newReleaseMessageId}/cue-sheet/${id}/cue/${data.length}`
              );
            }

            setDataSaving(false);
          },
        });
      }
    };

    const submit = () => {
      setValidated(true);
      setIsValid({});

      timeout.current = setTimeout(() => {
        const allKeys = Object.keys(validStatuses.current);
        const validKeys = allKeys.filter(
          (el: any) => validStatuses.current[el]
        ).length;

        if (validKeys === allKeys.length) {
          setValidated(false);
          setDataSaving(true);
          saveData(updatedData);
        } else {
          props.setAlertData(
            ["Please correct the errors in the highlighted tabs and try again"],
            0
          );
          setDataSaving(false);
        }

        setIsValid(validStatuses.current);
      }, 1000);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(id, props.match?.params?.newReleaseMessageId),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });
      }

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, [props.match.params]);

    useEffect(() => {
      setTabsContainerWidth();
    }, [tabsRef]);

    useEffect(() => {
      window.addEventListener("resize", setTabsContainerWidth);

      return () => {
        window.removeEventListener("resize", setTabsContainerWidth);
      };
    }, []);

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="page">
          <h2 className="page__title">
            {props.match?.params?.key !== undefined ? "Edit" : "Add"}
            {` Related Release`}
          </h2>
          <Context.Provider value={Value_Cue}>
            <Tab.Container defaultActiveKey="general">
              <Col
                sm={12}
                className="page__tab-links-container"
                ref={tabsRef}
                style={{
                  width: `${tabsWidth}px`,
                  overflowX: linksWidth < tabsWidth ? "visible" : "scroll",
                }}
              >
                <Nav
                  fill
                  variant="tabs"
                  style={{
                    width: linksWidth < tabsWidth ? "100%" : `${linksWidth}px`,
                  }}
                >
                  {tabs.map((el: any) => (
                    <Nav.Item key={el.id}>
                      <Nav.Link
                        eventKey={el.id}
                        className={
                          isValid[el.id] === false && validated
                            ? "error-tab"
                            : ""
                        }
                      >
                        {el.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <GeneralTab
                    onChange={(generalData: any) =>
                      updateData({
                        ...updatedData,
                        generalData,
                      })
                    }
                    setValidStatus={(general: boolean) => {
                      validStatuses.current.general = general;
                    }}
                    validated={validated}
                    value={baseData.generalData}
                  />
                </Tab.Content>
                <Tab.Content>
                  <AdditionalTitleTab
                    onChange={(additionalTitles: any) =>
                      updateData({
                        ...updatedData,
                        additionalTitles,
                      })
                    }
                    value={baseData.additionalTitles}
                  />
                </Tab.Content>
                <Tab.Content>
                  <CLineTab
                    onChange={(cLines: any) =>
                      updateData({
                        ...updatedData,
                        cLines,
                      })
                    }
                    value={baseData.cLines}
                  />
                </Tab.Content>
                <Tab.Content>
                  <ContributorTab
                    onChange={(contributors: any) =>
                      updateData({
                        ...updatedData,
                        contributors,
                      })
                    }
                    value={baseData.contributors}
                  />
                </Tab.Content>
                <Tab.Content>
                  <DisplayTitleTab
                    onChange={(displayTitles: any) =>
                      updateData({
                        ...updatedData,
                        displayTitles,
                      })
                    }
                    value={baseData.displayTitles}
                  />
                </Tab.Content>
                <Tab.Content>
                  <DisplayTitleTextTab
                    onChange={(displayTitleTexts: any) =>
                      updateData({
                        ...updatedData,
                        displayTitleTexts,
                      })
                    }
                    value={baseData.displayTitleTexts}
                  />
                </Tab.Content>
                <Tab.Content>
                  <PLineTab
                    onChange={(pLines: any) =>
                      updateData({
                        ...updatedData,
                        pLines,
                      })
                    }
                    value={baseData.pLines}
                  />
                </Tab.Content>
                <Tab.Content>
                  <ResourceIdTab
                    onChange={(resourceId: any) => {
                      updateData({
                        ...updatedData,
                        resourceId,
                      });
                    }}
                    setValidStatus={(resourceId: boolean) => {
                      validStatuses.current.resourceId = resourceId;
                    }}
                    validated={validated}
                    value={baseData.resourceId}
                  />
                </Tab.Content>
                <Tab.Content>
                  <WorkIdTab
                    onChange={(workId: any) => {
                      updateData({
                        ...updatedData,
                        workId,
                      });
                    }}
                    setValidStatus={(workId: boolean) => {
                      validStatuses.current.workId = workId;
                    }}
                    validated={validated}
                    value={baseData.workId}
                  />
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Context.Provider>
          <div className="page__btn-container">
            <SpinnerBtn
              disabled={getIsEqual() || dataSaving}
              isLoading={dataSaving}
              onClick={submit}
              text={
                id && props.match?.params?.type !== undefined
                  ? "Update"
                  : "Save"
              }
            />
          </div>
        </div>
      </DndProvider>
    );
  }
);
