const getData = (id: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${id}/deal_lists`,
});

const deleteData = (newReleaseMessageId: string, id: string, key: string) => ({
  method: "delete",
  url: `/ern/new_release_messages/${newReleaseMessageId}/deal_list/${key}/${id}`,
});

export default {
  getData,
  deleteData,
};
