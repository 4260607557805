import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { SignInBtn } from "./SignInBtn";

import "./layout.scss";

export const Header = () => (
  <Navbar className="ddex-navbar" sticky="top">
    <Link to="/">
      <Navbar.Brand>
        Metadata GUI <span style={{ fontSize: "0.675em" }}>beta</span>
      </Navbar.Brand>
    </Link>
    <Navbar.Collapse className="justify-content-end">
      <SignInBtn />
    </Navbar.Collapse>
  </Navbar>
);
