import React from "react";
import { SelectData } from "./SelectData";

const CHANNEL_OPTIONS = [
  "BinauralAudio",
  "LCR",
  "Mono",
  "MultichannelAudio",
  "MultiTrack",
  "Quad",
  "Stems",
  "Stereo",
  "SurroundSound",
  "Unknown",
];

export const RecordingMode = (props: {
  inputWidth?: number;
  labelWidth?: number;
  onChange: (value: string) => void;
  value: any;
}) => (
  <>
    <SelectData
      id="recording-mode-audio-chan-conf"
      data={CHANNEL_OPTIONS}
      inputWidth={props.inputWidth}
      label="Audio Channel Configuration"
      labelWidth={props.labelWidth}
      onChoose={(choice) => props.onChange(choice)}
      value={props.value}
    />
  </>
);
