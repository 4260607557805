import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { FulfillmentDateWithFulfillment } from "../FulfillmentDateWithFulfillment";
import { EventDateWithoutFlags } from "../EventDateWithoutFlags";
import { TableComponent } from "../TableComponent";
import { Spacer } from "../Spacer";

interface Props {
  onChange: (value: any) => void;
  setValidStatus: (isValid: boolean) => void;
  validated: boolean;
  value: any[];
}

export const DatesTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [dates, setDates] = useState<any>({});

  const onUpdate = (data: any) => {
    setDates(data);
    props.onChange(data);
  };

  const addElement = (t: any) => {
    const copy = [...(dates.firstPublicationDates || [])];
    copy.push(t);
    onUpdate({
      ...dates,
      firstPublicationDates: copy,
    });
  };

  const updateElement = (t: any, index: number) => {
    const copy = [...dates.firstPublicationDates];
    copy[index] = t;
    onUpdate({
      ...dates,
      firstPublicationDates: copy,
    });
  };

  const removeElement = (index: number) => {
    const copy = [...dates.firstPublicationDates];
    copy.splice(index, 1);
    onUpdate({
      ...dates,
      firstPublicationDates: copy,
    });
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated]);

  useEffect(() => {
    if (props.value) setDates(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="dates" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <EventDateWithoutFlags
          id="creation-date-input"
          eventDate={dates.creationDate}
          keyName="approximate"
          label="Creation Date"
          updateEventDate={(creationDate: any) =>
            onUpdate({
              ...dates,
              creationDate,
            })
          }
        />
        <Spacer />
        <h5>First Publication Date</h5>
        <TableComponent
          add={addElement}
          addTitle="Add First Publication Date"
          data={dates.firstPublicationDates || []}
          emptyElement={{}}
          fields={["First Publication Date", "Territory Code", "Default"]}
          getCells={(el: any) => (
            <>
              <td>{el.fulfillmentDate}</td>
              <td>{el.applicableTerritoryCode || ""}</td>
              <td>{`${el.default || false}`}</td>
            </>
          )}
          getModalContent={(value: any, onChange: (data: any) => void) => (
            <FulfillmentDateWithFulfillment
              id="first-publication-date-input"
              keyName="default"
              eventDate={value || {}}
              label="Fulfillment Date"
              updateEventDate={onChange}
            />
          )}
          modalTitle="First Publication Date"
          remove={removeElement}
          update={updateElement}
        />
      </Form>
    </Tab.Pane>
  );
};
