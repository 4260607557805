const getData = (id: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${id}/chapter_lists`,
});

const deleteData = (newReleaseMessageId: string, id: string) => ({
  method: "delete",
  url: `/ern/new_release_messages/${newReleaseMessageId}/chapter_lists/chapters/${id}`,
});

export default {
  getData,
  deleteData,
};
