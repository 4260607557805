import React, { useRef, useState, useEffect } from "react";
import { Tab, Form } from "react-bootstrap";

const RelatedResourceId = React.lazy(() =>
  import("../RelatedResourceId").then((module) => ({
    default: module.RelatedResourceId,
  }))
);

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const ResourceIdTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="resourceid" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <React.Suspense fallback={null}>
          <RelatedResourceId onChange={onUpdate} value={generalData} />
        </React.Suspense>
      </Form>
    </Tab.Pane>
  );
};
