import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const MusicalWorkId = React.lazy(() =>
  import("../MusicalWorkId").then((module) => ({
    default: module.MusicalWorkId,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
  withReplaced?: boolean;
}

const fields = [
  "ISWC",
  "Opus Number",
  "Composer Catalog Numbers",
  "Replaced",
  "Proprietary IDs",
];

export const WorkIdsTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  const getId = (el: any) => {
    if (el?.proprietaryIds?.length) {
      const pIds: string[] = [];

      el.proprietaryIds.forEach((id: any) => {
        const value = id.value || "";
        const ns = id.namespace ? `(${id.namespace})` : "";

        pIds.push(`${value} ${ns}`);
      });

      return pIds.join(", ");
    }

    return "";
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="workids" className="page__tab-content">
      <TableComponent
        add={add}
        data={data || []}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.iswc}</td>
            <td>{el.opusNumber}</td>
            <td>{(el.composerCatalogNumbers || []).join(", ")}</td>
            <td>{`${!!el.opusNumber}`}</td>
            <td>{getId(el)}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <MusicalWorkId
              value={value}
              onChange={onChange}
              withReplaced={props.withReplaced !== false}
            />
          </React.Suspense>
        )}
        modalTitle="Work ID"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
