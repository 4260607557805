import React from "react";
import { UserDefinedSelect } from "./UserDefinedSelect";
import data from "../data/algorithms.json";

export const Algorithm = (props: {
  id: string;
  onChange: (val: any) => void;
  value: any;
}) => (
  <UserDefinedSelect
    id={`${props.id}-algorithm`}
    label="Algorithm"
    onChange={(value) =>
      props.onChange({
        ...props.value,
        ...value,
      })
    }
    options={data}
    value={props.value}
    valueKey="value"
    withNamespace
  />
);
