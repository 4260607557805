import React from "react";
import { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { Spinner } from "../components/Spinner";
import axios from "axios";

import "./with-api.scss";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
    // Auth token
  },
});

export default (Component: any) => (props: any) => {
  const timeout: any = useRef(null);

  const [alertMsg, setAlertMsg] = useState({ type: "", message: "" });
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [loader, setLoader] = useState(false);
  const [getRequestLoading, setGetRequestLoading] = useState(false);

  const setAlertData = (errors?: any, delay?: number) => {
    if (errors?.length) {
      setAlertMsg({
        type: "danger",
        message: errors.join(", "),
      });
    } else if (errors && errors.toJSON) {
      setAlertMsg({
        type: "danger",
        message: errors.toJSON().message,
      });
    } else {
      setAlertMsg({
        type: "danger",
        message: errors.message,
      });
    }

    setAlertVisibility(true);

    timeout.current = setTimeout(() => {
      clearTimeout(timeout.current);
      timeout.current = null;
      setAlertVisibility(false);
      setAlertMsg({ type: "", message: "" });
    }, delay || 3000);
  };

  const setSuccessAlertData = (method: "get" | "post" | "patch" | "delete") => {
    let action = "";

    switch (method) {
      case "get":
        action = "got";
        break;
      case "delete":
        action = "deleted";
        break;
      default:
        action = "saved";
        break;
    }

    setAlertMsg({
      type: "success",
      message: `Data ${action} successfully`,
    });
    setAlertVisibility(true);

    timeout.current = setTimeout(() => {
      clearTimeout(timeout.current);
      timeout.current = null;
      setAlertVisibility(false);
      setAlertMsg({ type: "", message: "" });
    }, 3000);
  };

  const request = (params: {
    method: "get" | "post" | "patch" | "delete";
    url: string;
    successCb?: (data: any) => void;
    errorCb?: (errors: string[]) => void;
    data?: any;
    baseUrl?: string;
    skipAlert?: boolean;
    skipErrorAlert?: boolean;
    skipLoading?: boolean;
  }) => {
    setLoader(true);

    if (params.method === "get") setGetRequestLoading(true);

    api
      .request({
        url: params.url,
        method: params.method,
        ...(params.baseUrl ? { baseUrl: params.baseUrl } : {}),
        ...(params.data ? { data: params.data } : {}),
        ...(localStorage.getItem("token")
          ? {
              headers: {
                Authorization: localStorage.getItem("token") || "",
              },
            }
          : {}),
      })
      .then((data: any) => {
        if (!params.skipAlert) setSuccessAlertData(params.method);
        if (params.successCb) params.successCb(data);
      })
      .catch((errors: any) => {
        if (!params.skipErrorAlert) {
          if (props.setIs404) {
            props.setIs404(errors?.response?.status === 404);
          }
          setAlertData(errors?.response?.data?.errors || errors);
        }
        if (params.errorCb) {
          params.errorCb(errors?.response?.data?.errors || errors);
        }
        if (errors?.response?.status === 401) {
          localStorage.removeItem("token");
          document.dispatchEvent(new Event("token-changed"));
        }
      })
      .finally(() => {
        setGetRequestLoading(false);
        setLoader(false);
      });
  };

  return (
    <>
      {props.skipLoading ? null : <Spinner isShow={getRequestLoading} />}
      <div className="alert-container">
        <Alert variant={alertMsg.type} show={alertVisibility}>
          {alertMsg.message}
        </Alert>
      </div>
      <Component
        {...props}
        api={request}
        apiLoader={loader}
        setAlertData={setAlertData}
        setSuccessAlertData={setSuccessAlertData}
      />
    </>
  );
};
