import { transformToCamelCase, transformToSnakeCase } from "./base-converters";

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    message_header: {
      message_id: converted.general_data.message_id,
      message_thread_id: converted.general_data.message_thread_id,
      message_file_name: converted.general_data.message_file_name,
      message_control_type: converted.general_data.message_control_type,
      message_sender: converted.message_sender,
      sent_on_behalf_of: converted.sent_on_behalf_of,
      message_recipients: converted.message_recipients,
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: {
      messageId: converted.messageHeader?.messageId || "",
      messageThreadId: converted.messageHeader?.messageThreadId || "",
      messageFileName: converted.messageHeader?.messageFileName || "",
      messageControlType: converted.messageHeader?.messageControlType || "",
    },
    messageSender: converted.messageHeader?.messageSender || {},
    sentOnBehalfOf: converted.messageHeader?.sentOnBehalfOf || {},
    messageRecipients: converted.messageHeader?.messageRecipients || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
