import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

interface Props {
  id?: string;
  newReleaseMessageId: string;
  onChange: (data: any) => void;
  releaseKey?: string;
  value: any;
}

const fields = ["Deal Release References"];

export const DealsTab = (props: Props) => {
  const history = useHistory();
  const [data, , remove, , , setDefault] = useArray<any>();

  const add = () => {
    history.push(`/ern/${props.newReleaseMessageId}/deal/${props.id}`);
  };

  const update = (data: any, index: number) => {
    if (props.id) {
      history.push(
        `/ern/${props.newReleaseMessageId}/deal/${props.id}/${index}`
      );
    }
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="deals" className="page__tab-content">
      {!props.id ? (
        <h6>Please, save changes before adding Deal</h6>
      ) : (
        <TableComponent
          add={add}
          data={data || []}
          emptyElement={{}}
          fields={fields}
          getCells={(el: any) => (
            <>
              <td>{(el.dealReferences || []).join(", ") || "-"}</td>
            </>
          )}
          remove={remove}
          update={update}
        />
      )}
    </Tab.Pane>
  );
};
