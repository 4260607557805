import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { Input } from "../Input";
import { MultiInput } from "../MultiInput";

interface Props {
  onChange: (data: any) => void;
  value: any;
}

export const DealsGeneral = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [data, setData] = useState<any>({});

  const onUpdate = (data: any) => {
    setData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form ref={formRef}>
        <MultiInput
          data={data}
          dataKey="dealReferences"
          id="dealReferences"
          label="Deal References"
          update={(changes) =>
            onUpdate({
              ...data,
              ...changes,
            })
          }
        />
        <MultiInput
          data={data?.dealTechnicalResourceDetailsReferenceList || {}}
          dataKey="dealTechnicalResourceDetailsReferences"
          id="dealTechnicalResourceDetailsReferences"
          label="Deal Technical Resource Details References"
          update={(changes) =>
            onUpdate({
              ...data,
              dealTechnicalResourceDetailsReferenceList: changes,
            })
          }
        />
        <MultiInput
          data={data?.dealTerms?.instantGratificationResourceList || {}}
          dataKey="dealResourceReferences"
          id="dealResourceReferences"
          label="Deal Resource References"
          update={(changes) =>
            onUpdate({
              ...data,
              dealTerms: {
                ...(data.dealTerms || {}),
                instantGratificationResourceList: changes,
              },
            })
          }
        />
        <Input
          id="numberOfProductsPerCarton"
          label="Number Of Products Per Carton"
          min={0}
          onChange={(changes: string) =>
            onUpdate({
              ...data,
              dealTerms: {
                ...(data.dealTerms || {}),
                numberOfProductsPerCarton: changes,
              },
            })
          }
          step="0.01"
          type="number"
          value={data.dealTerms?.numberOfProductsPerCarton}
        />
        <Input
          id="numberOfUsages"
          label="Number Of Usages"
          min={0}
          onChange={(changes: string) =>
            onUpdate({
              ...data,
              dealTerms: {
                ...(data.dealTerms || {}),
                numberOfUsages: changes,
              },
            })
          }
          step="0.01"
          type="number"
          value={data.dealTerms?.numberOfUsages}
        />
        <Form.Group className="contributor-checkboxes">
          <Form.Check
            inline
            checked={data.communicatedOutOfBand || false}
            id="communicatedOutOfBand"
            type="checkbox"
            label="Is Communicated Out Of Band"
            onChange={() =>
              onUpdate({
                ...data,
                communicatedOutOfBand: !data.communicatedOutOfBand,
              })
            }
          />
          <Form.Check
            inline
            checked={data.dealTerms?.preOrderDeal || false}
            id="preOrderDeal"
            type="checkbox"
            label="Is Pre Order Deal"
            onChange={() =>
              onUpdate({
                ...data,
                dealTerms: {
                  ...(data.dealTerms || {}),
                  preOrderDeal: !(data.dealTerms?.preOrderDeal || false),
                },
              })
            }
          />
          <Form.Check
            inline
            checked={data.dealTerms?.promotional || false}
            id="promotional"
            type="checkbox"
            label="Is Promotional"
            onChange={() =>
              onUpdate({
                ...data,
                dealTerms: {
                  ...(data.dealTerms || {}),
                  promotional: !(data.dealTerms?.promotional || false),
                },
              })
            }
          />
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
