import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { PartyNameMessageSender } from "../PartyNameMessageSender";

interface Props {
  id: string;
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  tabName: string;
  validated: boolean;
  value: any;
}

export const MessageSenderTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [senderData, setSenderData] = useState<any>({});

  const onUpdate = (data: any) => {
    setSenderData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, senderData]);

  useEffect(() => {
    if (props.value) setSenderData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey={props.tabName} className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <PartyNameMessageSender
          id={props.id}
          value={senderData || {}}
          onChange={(value: any) =>
            onUpdate({
              ...senderData,
              ...value,
            })
          }
        />
      </Form>
    </Tab.Pane>
  );
};
