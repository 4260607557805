import React from "react";
import { Input } from "./Input";
import { Spacer } from "./Spacer";

interface Props {
  id: string;
  onChange: (data: any) => void;
  value: any;
}

export const PartyNameMessageSender = (props: Props) => (
  <>
    <Input
      id={`${props.id}_party_id`}
      label="Party Id"
      prependText="PADPIDA"
      value={props.value.partyId || ""}
      onChange={(partyId) => props.onChange({ partyId })}
    />
    <Input
      id={`${props.id}_trading_name`}
      label="Trading Name"
      value={props.value.tradingName || ""}
      onChange={(tradingName) => props.onChange({ tradingName })}
    />
    <Spacer />
    <h5>Party Name</h5>
    <br />
    <Input
      id={`${props.id}_fullName`}
      label="Full Name"
      value={props.value?.partyName?.fullName || ""}
      onChange={(fullName) =>
        props.onChange({
          partyName: {
            ...(props.value?.partyName || {}),
            fullName,
          },
        })
      }
    />
    <Input
      id={`${props.id}_fullNameAsciiTranscribed`}
      label="Full Name Ascii Transcribed"
      value={props.value?.partyName?.fullNameAsciiTranscribed || ""}
      onChange={(fullNameAsciiTranscribed) =>
        props.onChange({
          partyName: {
            ...(props.value?.partyName || {}),
            fullNameAsciiTranscribed,
          },
        })
      }
    />
    <Input
      id={`${props.id}_fullNameIndexed`}
      label="Full Name Indexed"
      value={props.value?.partyName?.fullNameIndexed || ""}
      onChange={(fullNameIndexed) =>
        props.onChange({
          partyName: {
            ...(props.value?.partyName || {}),
            fullNameIndexed,
          },
        })
      }
    />
    <Input
      id={`${props.id}_namesBeforeKeyName`}
      label="Names Before Key Name"
      value={props.value?.partyName?.namesBeforeKeyName || ""}
      onChange={(namesBeforeKeyName) =>
        props.onChange({
          partyName: {
            ...(props.value?.partyName || {}),
            namesBeforeKeyName,
          },
        })
      }
    />
    <Input
      id={`${props.id}_keyName`}
      label="Key Name"
      value={props.value?.partyName?.keyName || ""}
      onChange={(keyName) =>
        props.onChange({
          partyName: {
            ...(props.value?.partyName || {}),
            keyName,
          },
        })
      }
    />
    <Input
      id={`${props.id}_namesAfterKeyName`}
      label="Names After Key Name"
      value={props.value?.partyName?.namesAfterKeyName || ""}
      onChange={(namesAfterKeyName) =>
        props.onChange({
          partyName: {
            ...(props.value?.partyName || {}),
            namesAfterKeyName,
          },
        })
      }
    />
    <Input
      id={`${props.id}_abbreviatedName`}
      label="Abbreviated Name"
      value={props.value?.partyName?.abbreviatedName || ""}
      onChange={(abbreviatedName) =>
        props.onChange({
          partyName: {
            ...(props.value?.partyName || {}),
            abbreviatedName,
          },
        })
      }
    />
  </>
);
