import React from "react";
import { Alert } from "react-bootstrap";
import "./App.scss";

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="alert-main">
          <Alert variant="danger" show>
            Server error. Please, reload page or try later.
          </Alert>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
