import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";
import { getOption } from "../../utils/parse-options";

const Contributor = React.lazy(() =>
  import("../Contributor").then((module) => ({
    default: module.Contributor,
  }))
);

interface Props {
  isSortable?: boolean;
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Contributor Party Reference",
  "Instrument Type",
  "Contribution",
  "Display Credits",
];

export const ContributorTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  const getContributionField = (el: any) => {
    const data = [];

    if (el.hasMadeFeaturedContribution) data.push("Featured");
    if (el.hasMadeContractedContribution) data.push("Contracted");

    return data.join(", ");
  };

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="contributors" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any) => (
          <>
            <td>{el.contributorPartyReference}</td>
            <td>
              {(el.instrumentTypes || [])
                .map((el: any) => getOption(el.value))
                .join(", ")}
            </td>
            <td>{getContributionField(el)}</td>
            <td>{(el.displayCredits || []).length || "None"}</td>
          </>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <React.Suspense fallback={null}>
            <Contributor id="contr-modal" onChange={onChange} value={value} />
          </React.Suspense>
        )}
        modalTitle="Contributor"
        onSortTable={setDefault}
        remove={remove}
        update={update}
        isSortable={props.isSortable}
      />
    </Tab.Pane>
  );
};
