import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { SpinnerBtn } from "../../components/SpinnerBtn";
import { ReleaseVisibilityTab } from "../../components/tabs-deal/ReleaseVisibilityTab";
import withApi from "../../hocs/withApi";
import pageApi from "../../api/release-visibilities";
import pageConverter from "../../api-converters/release-visibilities";

import "../page.scss";

export const ReleaseVisibility = withApi(
  (props: {
    api: any;
    apiLoader: boolean;
    newReleaseMessageId: string;
    id?: string;
    history: any;
    match: {
      params: {
        id?: string;
        newReleaseMessageId: string;
      };
    };
    setAlertData: (errors: string[], delay?: number) => void;
    setSuccessAlertData: (method: string) => void;
  }) => {
    const id = props.match?.params?.id;
    const history = useHistory();
    const [baseData, setBaseData] = useState<any>({});
    const [updatedData, setUpdatedData] = useState<any>({});
    const [dataSaving, setDataSaving] = useState(false);

    const updateData = (data: any) => {
      setUpdatedData(data);
    };

    const getIsEqual = () => isEqual(baseData, updatedData);

    const setDefaultData = (data: any) => {
      const parsedData = pageConverter.getDataForSet(data);

      setBaseData(parsedData);
      setUpdatedData(cloneDeep(parsedData));
    };

    const saveData = (data: any) => {
      if (id) {
        props.api({
          ...pageApi.patchData(
            id,
            props.match.params.newReleaseMessageId,
            data
          ),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      } else {
        props.api({
          ...pageApi.postData(props.match.params.newReleaseMessageId, data),
          successCb: ({ data }: any) => {
            setDefaultData(data);
            setDataSaving(false);

            history.push(
              `/ern/${props.match.params.newReleaseMessageId}/release-visibility/${data.id}`,
              { showSuccessAlert: true }
            );
          },
          errorCb: () => {
            setDataSaving(false);
          },
        });
      }
    };

    const submit = () => {
      setDataSaving(true);
      saveData(updatedData);
    };

    useEffect(() => {
      if (id) {
        props.api({
          ...pageApi.getData(id, props.match?.params?.newReleaseMessageId),
          skipAlert: true,
          successCb: ({ data }: any) => setDefaultData(data),
        });

        if (props.history?.location?.state?.showSuccessAlert) {
          props.setSuccessAlertData("post");
        }
      }

      if (props.history?.location?.state?.showSuccessAlert) {
        props.setSuccessAlertData("post");
      }
    }, []);

    return (
      <div className="page">
        <h2 className="page__title">Release Visibility</h2>
        <Col sm={12}>
          <ReleaseVisibilityTab
            onChange={(data: any) => {
              updateData({
                ...updatedData,
                ...data,
              });
            }}
            value={baseData}
          />
        </Col>
        <div className="page__btn-container">
          <SpinnerBtn
            disabled={getIsEqual() || dataSaving}
            isLoading={dataSaving}
            onClick={submit}
            text={id ? "Update" : "Save"}
          />
        </div>
      </div>
    );
  }
);
