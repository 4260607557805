import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import withApi from "../hocs/withApi";
import { AuthModal } from "../components/AuthModal";

import "./page.scss";

export const Login = withApi(
  (props: {
    api: any;
    history: {
      location: {
        state: {
          from: {
            pathname: string;
          };
        };
      };
    };
  }) => {
    const [isNeedAuth, setIsNeedAuth] = useState(false);
    const history = useHistory();
    const pathname = props.history?.location?.state?.from?.pathname || "/ern";

    const toggle = () => {
      const token = localStorage.getItem("token");

      setIsNeedAuth(false);
      if (token) history.push(pathname);
    };

    useEffect(() => {
      const token = localStorage.getItem("token");

      if (!token) {
        setIsNeedAuth(true);
      } else {
        history.push(pathname);
      }
    }, []);

    return <AuthModal isOpen={isNeedAuth} api={props.api} toggle={toggle} />;
  }
);
