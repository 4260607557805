import React, { useState, useEffect, useRef } from "react";
import { Form, Tab } from "react-bootstrap";
import { Input } from "../Input";
import { LanguageScriptField } from "../LanguageField";

interface Props {
  onChange: (data: any) => void;
  setValidStatus: (status: boolean) => void;
  validated: boolean;
  value: any;
}

export const GeneralTab = (props: Props) => {
  const formRef: any = useRef(null);
  const [generalData, setGeneralData] = useState<any>({});

  const onUpdate = (data: any) => {
    setGeneralData(data);
    props.onChange(data);
  };

  useEffect(() => {
    if (props.validated) {
      props.setValidStatus(formRef.current.checkValidity());
    }
  }, [props.validated, generalData]);

  useEffect(() => {
    if (props.value) setGeneralData(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="general" className="page__tab-content">
      <Form validated={props.validated} ref={formRef}>
        <Input
          id="resourceReference"
          inputWidth={8}
          label="Resource Reference"
          labelWidth={4}
          invalidFeedback="Please provide a valid value."
          prependText="A"
          pattern="^[\d\-_a-zA-Z]+$"
          value={generalData.resourceReference || ""}
          onChange={(resourceReference) =>
            onUpdate({ ...generalData, resourceReference })
          }
        />
        <Input
          id="general-type"
          inputWidth={8}
          label="Type"
          labelWidth={4}
          value={generalData.type?.value || ""}
          onChange={(value) =>
            onUpdate({
              ...generalData,
              type: {
                ...(generalData.type || {}),
                value,
              },
            })
          }
        />
        <Input
          id="general-namespace"
          inputWidth={8}
          label="Namespace"
          labelWidth={4}
          value={generalData.type?.namespace || ""}
          onChange={(namespace) =>
            onUpdate({
              ...generalData,
              type: {
                ...(generalData.type || {}),
                namespace,
              },
            })
          }
        />
        <Input
          id="general-user-defined"
          inputWidth={8}
          label="User Defined Value"
          labelWidth={4}
          value={generalData.type?.userDefinedValue || ""}
          onChange={(userDefinedValue) =>
            onUpdate({
              ...generalData,
              type: {
                ...(generalData.type || {}),
                userDefinedValue,
              },
            })
          }
        />
        <LanguageScriptField
          idDiff="general-lang"
          inputWidth={8}
          labelWidth={4}
          onChange={(languageAndScriptCode) =>
            onUpdate({
              ...generalData,
              languageAndScriptCode,
            })
          }
          value={generalData?.languageAndScriptCode || ""}
        />
        <Form.Group>
          <Form.Check
            inline
            checked={generalData.containsHiddenContent || false}
            id="containsHiddenContent"
            type="checkbox"
            label="Contains Hidden Content"
            onChange={() =>
              onUpdate({
                ...generalData,
                containsHiddenContent: !generalData.containsHiddenContent,
              })
            }
          />
          <Form.Check
            inline
            checked={generalData.supplemental || false}
            id="supplemental"
            type="checkbox"
            label="Supplemental"
            onChange={() =>
              onUpdate({
                ...generalData,
                supplemental: !generalData.supplemental,
              })
            }
          />
        </Form.Group>
      </Form>
    </Tab.Pane>
  );
};
