import {
  transformToCamelCase,
  transformToSnakeCase,
  convertDisplayArtistsForTable,
  convertDisplayArtistsForApi,
  convertAddTitlesForApi,
  convertAddTitlesForTable,
} from "./base-converters";

const getDatesForUpdate = (data: any) => ({
  original_release_dates: data.original_release_dates?.value
    ? data.original_release_dates
    : undefined,
  release_dates: data.release_dates?.value ? data.release_dates : undefined,
});

const getDatesForSet = (data: any) => ({
  originalReleaseDates: data.originalReleaseDates || [],
  releaseDates: data.releaseDates || [],
});

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    release: {
      ...converted.general_data,
      ...getDatesForUpdate(converted.dates || {}),
      additional_titles: convertAddTitlesForApi(converted.add_titles || []),
      administrating_record_companies:
        converted.administrating_record_companies || [],
      av_ratings: converted.av_ratings || [],
      c_lines: converted.c_lines || [],
      courtesy_lines: converted.courtesy_lines || [],
      deities: converted.deities || [],
      display_artists: convertDisplayArtistsForApi(
        converted.display_artists || []
      ),
      display_artist_names: converted.display_artist_names || [],
      display_titles: convertAddTitlesForApi(converted.display_titles || []),
      display_title_texts: converted.display_title_texts || [],
      external_resource_links: converted.external_resource_links,
      genres: converted.genres,
      keywords: converted.keywords,
      marketing_comments: converted.marketing_comments,
      original_release_dates: converted.original_release_dates || [],
      p_lines: converted.p_lines,
      parental_warning_types: converted.parental_warning_types,
      ragas: converted.ragas || [],
      related_releases: converted.related_releases || [],
      related_resources: converted.related_resources || [],
      release_dates: converted.release_dates || [],
      release_id: converted.release_id || {},
      release_label_references: converted.release_label_references || [],
      release_types: converted.release_types || [],
      resource_group: converted.resource_group || [],
      synopsises: converted.synopsises || [],
      talas: converted.talas || [],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: converted.release,
    addTitles: convertAddTitlesForTable(
      converted.release.additionalTitles || []
    ),
    administratingRecordCompanies:
      converted.release.administratingRecordCompanies || [],
    avRatings: converted.release.avRatings || [],
    cLines: converted.release.cLines || [],
    courtesyLines: converted.release.courtesyLines || [],
    dates: getDatesForSet(converted.release),
    deities: converted.release.deities || [],
    displayArtists: convertDisplayArtistsForTable(
      converted.release.displayArtists || []
    ),
    displayArtistNames: converted.release.displayArtistNames,
    displayTitles: convertAddTitlesForTable(
      converted.release.displayTitles || []
    ),
    displayTitleTexts: converted.release.displayTitleTexts,
    externalResourceLinks: converted.release.externalResourceLinks || [],
    genres: converted.release.genres || [],
    keywords: converted.release.keywords || [],
    marketingComments: converted.release.marketingComments || [],
    originalReleaseDates: converted.release.originalReleaseDates || [],
    pLines: converted.release.pLines,
    parentalWarningTypes: converted.release.parentalWarningTypes,
    ragas: converted.release.ragas || [],
    relatedReleases: converted.release.relatedReleases,
    relatedResources: converted.release.relatedResources || [],
    releaseDates: converted.release.releaseDates || [],
    releaseId: converted.release.releaseId || {},
    releaseLabelReferences: converted.release.releaseLabelReferences || [],
    releaseTypes: converted.release.releaseTypes || [],
    resourceGroup: converted.release.resourceGroup || [],
    synopsises: converted.release.synopsises || [],
    talas: converted.release.talas || [],
  };
};

export default {
  getDataForUpdate,
  getDataForSet,
};
