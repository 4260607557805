import converter from "../api-converters/image";

const patchData = (id: string, newReleaseMessageId: string, data: any) => ({
  method: "patch",
  url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/images/${id}`,
  data: converter.getDataForUpdate(data),
});

const postData = (newReleaseMessageId: string, data: any) => ({
  method: "post",
  url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/images`,
  data: converter.getDataForUpdate(data),
});

const getData = (id: string, newReleaseMessageId: string) => ({
  method: "get",
  url: `/ern/new_release_messages/${newReleaseMessageId}/resource_list/images/${id}`,
});

export default {
  patchData,
  postData,
  getData,
};
