import React from "react";
import { getOption } from "../utils/parse-options";
import { Select } from "./Select";

const MUSICAL_WORK_TYPE_OPTIONS = ["Medley", "Potpurri", "Neither"].map(
  (el: string) => ({
    label: el,
    value: el,
  })
);

export const CompositeMusicalWorkType = (props: {
  onChange: (value: any) => void;
  value: any;
}) => (
  <Select
    id="general-composite-musical-work-type"
    onChoose={(change: any) => props.onChange(change.value)}
    label="Composite Musical Work Type"
    value={{
      label: getOption(props.value || ""),
      value: props.value || "",
    }}
    options={MUSICAL_WORK_TYPE_OPTIONS}
  />
);
