import React, { useEffect } from "react";
import { Tab, Row, Col } from "react-bootstrap";
import { useArray } from "../../hooks/useArray";
import { TableComponent } from "../TableComponent";

const BaseValueComponent = React.lazy(() =>
  import("../BaseValueComponent").then((module) => ({
    default: module.BaseValueComponent,
  }))
);

const InputIsDefault = React.lazy(() =>
  import("../InputIsDefault").then((module) => ({
    default: module.InputIsDefault,
  }))
);

interface Props {
  onChange: (value: any) => void;
  value: any[];
}

const fields = [
  "Synopsis",
  "Language And Script Code",
  "Applicable Territory Code",
  "Is Default",
  "Is Short Synopsis",
];

export const SynopsisTab = (props: Props) => {
  const [data, add, remove, update, , setDefault] = useArray<any>();

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  useEffect(() => {
    if (props.value) setDefault(props.value);
  }, [props.value]);

  return (
    <Tab.Pane eventKey="synopsis" className="page__tab-content">
      <TableComponent
        add={add}
        data={data}
        emptyElement={{}}
        fields={fields}
        getCells={(el: any, index: number) => (
          <React.Fragment key={index}>
            <td>{el.value || ""}</td>
            <td>{el.languageAndScriptCode || ""}</td>
            <td>{el.applicableTerritoryCode || ""}</td>
            <td>{`${el.default}`}</td>
            <td>{`${el.shortSynopsis || false}`}</td>
          </React.Fragment>
        )}
        getModalContent={(value: any, onChange: (data: any) => void) => (
          <>
            <React.Suspense fallback={null}>
              <BaseValueComponent
                hideDefault
                label="Synopsis"
                onChange={onChange}
                value={value}
              />
            </React.Suspense>
            <Row>
              <Col sm={2}>
                <React.Suspense fallback={null}>
                  <InputIsDefault
                    id="synopsis-is-default"
                    value={value?.default || false}
                    onChange={onChange}
                  />
                </React.Suspense>
              </Col>
              <Col sm={3}>
                <React.Suspense fallback={null}>
                  <InputIsDefault
                    id="synopsis-is-short"
                    label="Is Short Synopsis?"
                    value={value?.shortSynopsis || false}
                    onChange={(data) =>
                      onChange({ shortSynopsis: data.default })
                    }
                  />
                </React.Suspense>
              </Col>
            </Row>
          </>
        )}
        modalTitle="Synopsis"
        remove={remove}
        update={update}
      />
    </Tab.Pane>
  );
};
