import { transformToCamelCase, transformToSnakeCase } from "./base-converters";

const getDataForUpdate = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    release_deal: {
      ...(converted.general_data || { deal_release_references: [] }),
      deals: converted.deals || [],
    },
  };
};

const getDealsDataForUpdate = (
  resourceData: any,
  data: any,
  dealsKey?: string
) => {
  const converted = transformToSnakeCase(data);

  if (dealsKey) {
    const intDealKey = parseInt(dealsKey, 10);
    resourceData.release_deal.deals[intDealKey] = converted;
  } else {
    resourceData.release_deal.deals.push(converted);
  }

  return resourceData;
};

const getDataForPost = (data: any) => {
  const converted = transformToSnakeCase(data);

  return {
    release_deal: {
      ...converted.general_data,
      deals: converted.dates || [],
    },
  };
};

const getDataForSet = (data: any) => {
  const converted = transformToCamelCase(data);

  return {
    generalData: {
      dealReleaseReferences: converted.releaseDeal.dealReleaseReferences || [],
    },
    deals: converted.releaseDeal.deals || [],
  };
};

const getDealsDataForSet = (data: any, dealsKey?: string) => {
  const converted = transformToCamelCase(data);

  if (dealsKey) {
    return converted.releaseDeal.deals[parseInt(dealsKey, 10)];
  }

  return {};
};

export default {
  getDataForUpdate,
  getDataForPost,
  getDataForSet,
  getDealsDataForSet,
  getDealsDataForUpdate,
};
